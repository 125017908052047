import React, { useCallback, useMemo, useState } from 'react';
import { Form, notification, Tooltip } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { format, parse } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../hooks/auth';
import { PropsUserAccount, PropsAccount } from '../../Account/index';
import DataPersonalPf from './DataPersonalPf';
import { getDocumentCategory } from '../../../../../services/utils';
import api from '../../../../../services/api';
import api_bank from '../../../../../services/bank_api';
import DataPersonalPj from './DataPersonalPj';
import { useSettings } from '../../../../../hooks/settings';

interface IPersonFormData {
  phone: string;
  email: string;
}
interface PropsParams {
  account_id: string;
}

interface IAddressFormData {
  address: string;
  city: string;
  complement: string;
  neighborhood: string;
  state: string;
}

interface IAdditionalFormData {
  birth_date: string;
  mother_name: string;
  education: string;
  isPEP?: boolean;
  monthly_income: string;
  nationality: string;
  occupation: string;
  patrimony: string;
  pronoun: string;
  relationship: string;
}

interface IPartners {
  document: string;
  email: string;
  name: string;
  qualification: string;
  index?: number;
}
interface IAdditionalPjFormData {
  legal_nature: string;
  last_year_change: boolean;
  revenues: number;
  cnae: string;
  number_of_employees: number;
  number_of_partners: number;
  number_of_affiliates: number;
  declaration_number: string;
  state_registration: string;
  partners: [IPartners];
  opening_date: string;
  non_profit: boolean;
  trading_name: string;
  share_capital: string;
  document: string;
}

const Personal: React.FC<{
  user: PropsUserAccount;
  account?: PropsAccount;
}> = ({ user: userParam, account }) => {
  const { account_id } = useParams<PropsParams>();

  const { signOut, admin } = useAuth();
  const { settings } = useSettings();

  const [user] = useState(userParam);

  const [userAccount, setUserAccount] = useState(user);
  const [loading, setLoading] = useState(false);

  const loadAccountInfo = useCallback(async () => {
    try {
      const { data: userResposne } = await api.get(`/admin/users/details`, {
        headers: {
          user: user.user.id,
        },
      });

      setUserAccount(userResposne);
    } catch (err: any) {
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });

        signOut();
      } else {
        notification.error({
          message: 'Não foi possível recuperar os dados da conta informada.',
        });
      }
    }
  }, [signOut, user?.user?.id]);

  const handleSavePersonalData = useCallback(
    async (values: IPersonFormData) => {
      try {
        await api.put(
          '/admin/users/contact',
          {
            ...values,
          },
          {
            headers: {
              user: user?.user?.id,
            },
          },
        );
        notification.success({
          message: 'Nome e e-mail atualizados com sucesso',
        });
      } catch (err) {
        notification.error({
          message: 'Não foi possível atualizar os dados de nome e e-mail.',
        });
      }
    },
    [user],
  );
  const handleUpdateAddress = useCallback(
    async (values: IAddressFormData) => {
      try {
        await api.put(
          `/admin/users/address/${user.address.id}`,
          {
            ...values,
          },
          {
            headers: {
              user: user.user.id,
            },
          },
        );

        notification.success({
          message: 'Endereço atualizado com sucesso',
        });
      } catch (err: any) {
        notification.error({
          message: 'Não foi possível atualizar os dados de endereço.',
        });
      }
    },
    [user],
  );

  const handleUpdateAdditional = useCallback(
    async (values: IAdditionalFormData) => {
      try {
        setLoading(true);
        const formatDate: Array<number> = values.birth_date
          .split('/')
          .map(item => {
            return +item;
          });
        await api.put(
          `/admin/users/additionaldata`,
          {
            ...values,
            birth_date: new Date(
              Date.UTC(formatDate[2], formatDate[1] - 1, formatDate[0]),
            ),
          },
          {
            headers: {
              user: user.user.id,
            },
          },
        );
        setLoading(false);
        notification.success({
          message: 'Endereço atualizado com sucesso',
        });
      } catch (err: any) {
        setLoading(false);
        notification.error({
          message: 'Não foi possível atualizar os dados de endereço.',
        });
      }
    },
    [user],
  );
  const handleUpdateAdditionalPj = useCallback(
    async (values: IAdditionalPjFormData) => {
      try {
        setLoading(true);

        const formatDate: Array<number> = values.opening_date
          .split('/')
          .map(item => {
            return +item;
          });

        await api_bank.put(
          `/admin/pj/update/${user.user.id}`,
          {
            ...values,
            opening_date: new Date(
              Date.UTC(formatDate[2], formatDate[1] - 1, formatDate[0]),
            ),
            document: values.document.replace(/[.]|[-]|[/]/g, ''),
            revenues: Number(values.revenues),
          },
          {
            headers: {
              user: user.user.id,
              admintoken: localStorage.getItem('@BaasQesh:token'),
            },
          },
        );
        setLoading(false);

        notification.success({
          message:
            'Dados adicionais da empresa / socios atualizado com sucesso',
        });
      } catch (err: any) {
        setLoading(false);

        notification.error({
          message: 'Não foi possivel atualizar os dados.',
        });
        console.log(err?.response?.data);
      }
    },
    [user],
  );

  const handleUpdatePartner = useCallback(
    async (values: IPartners) => {
      try {
        setLoading(true);

        const partner = user.additional.partners[values.index as never];
        // eslint-disable-next-line no-param-reassign
        delete values.index;

        await api.patch(
          '/admin/pj/partners/UPDATE',
          {
            partners: partner,
            newPartner: {
              ...values,
              document: values.document.replace(/(\.)|(-)/g, ''),
            },
          },
          {
            headers: {
              user: user.user.id,
              admintoken: `${localStorage.getItem('@BaasQesh:token')}`,
            },
          },
        );
        setLoading(false);

        notification.success({
          message:
            'Dados adicionais da empresa / socios atualizado com sucesso',
        });
      } catch (err: any) {
        setLoading(false);

        notification.error({
          message: 'Não foi possivel atualizar os dados.',
        });
        console.log('erro partner: ', err?.response?.data);
      }
    },
    [user],
  );

  const setRisk = useCallback(() => {
    let resp;
    switch (account?.classify) {
      case 'low': {
        resp = <>Baixo</>;
        break;
      }
      case 'medium': {
        resp = <span style={{ color: 'orange' }}>Médio</span>;
        break;
      }
      case 'high': {
        resp = <span style={{ color: 'tomato' }}>Alto</span>;
        break;
      }
      case 'very_high': {
        resp = (
          <span style={{ color: 'red', fontWeight: 'bold' }}>Muito Alto</span>
        );
        break;
      }
      default: {
        resp = <>Em análise</>;
        break;
      }
    }
    return resp;
  }, []);

  const Risks = (conditions: any): JSX.Element => {
    const risks = ['low', 'medium', 'high', 'very_high'];

    const dict = {
      capital: 'RENDA',
      isPEP: 'PEP',
      cnaeHighRisk: 'CNAE',
      hasProcessAdmin: 'PROC. ADMINISTRATIVO',
      hasProcessTax: 'PROC. TRIBUTARIO',
      low_condition: 'GRAU BAIXO',
    };

    const tooltip = (
      <>
        {conditions &&
          Object.keys(conditions)?.map((condition: string): any => (
            <tr>
              <td>{dict[condition as keyof typeof dict]}: </td>
              <td>
                {conditions[condition as keyof typeof conditions] ? (
                  <b>SIM</b>
                ) : (
                  'NÃO'
                )}
              </td>
            </tr>
          ))}
      </>
    );
    return (
      <>
        {account?.classify &&
        risks.includes(account.classify) &&
        admin?.bank.name === 'Qesh Payments' ? (
          <Tooltip title={tooltip} placement="bottomRight" trigger="hover">
            <span>
              Risco: <span style={{ color: 'grey' }}>{setRisk()}</span>
            </span>
          </Tooltip>
        ) : (
          <span>
            Risco: <span style={{ color: 'grey' }}>{setRisk()}</span>
          </span>
        )}
      </>
    );
  };

  const formattedDocuments = useMemo(
    () =>
      userAccount.documents?.map(document => {
        return {
          category: getDocumentCategory(document.category),
          date: format(new Date(document.created_at), 'dd/MM/yyyy'),
          link_to_pic: (
            <a
              href={String(document?.urlDoc)}
              // href={
              //   document?.urlDoc ||
              //   `https://qesh-storage.s3.amazonaws.com/documents/${document.id}/${document.name}`
              // }
              target="blank"
              key={document.name}
            >
              Visualizar imagem
            </a>
          ),
        };
      }),
    [userAccount],
  );

  const renderPf = (): any => {
    return (
      <DataPersonalPf
        user={user}
        getRisk={Risks}
        handleSavePersonalData={handleSavePersonalData}
        handleUpdateAddress={handleUpdateAddress}
        handleUpdateAdditional={handleUpdateAdditional}
        formattedDocuments={formattedDocuments}
      />
    );
  };

  const renderPj = (): any => {
    return (
      <DataPersonalPj
        user={user}
        getRisk={Risks}
        handleSavePersonalData={handleSavePersonalData}
        handleUpdateAddress={handleUpdateAddress}
        handleUpdateAdditionalPj={handleUpdateAdditionalPj}
        handleUpdatePartner={handleUpdatePartner}
        formattedDocuments={formattedDocuments}
        loading={loading}
      />
    );
  };

  // return Risks(userAccount?.generalConditions || {});
  return userAccount?.user?.document.length < 14 ? renderPf() : renderPj();
};

export default Personal;

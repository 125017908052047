import React, { useState, useEffect, useCallback } from 'react';

import { Table, Button, notification } from 'antd';
import ReactJson from 'react-json-view';

import { format, parseISO } from 'date-fns';

import {
  SearchOutlined,
  RightCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Container, Pagination } from './styles';

import { RowGutter, Col, RawInput } from '../../styles/components';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

const { Column, ColumnGroup } = Table;

interface User {
  id: string;
  hash: string;
}

const Dashboard: React.FC = () => {
  const [accounts, setAccounts] = useState([] as Array<User>);
  const [activeAccounts, setActiveAccounts] = useState({} as User | undefined);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [inputData, setInputData] = useState('');
  const { signOut } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingActiveAccounts, setLoadingActiveAccounts] = useState(false);

  const [rowActive, setRowActive] = useState('');

  const loadData = useCallback(
    async (page = 0, filters = '') => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/admin/logs/requests?page=${page}${filters}`,
        );
        setAccounts(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar a lista de logs',
          });
        }
      }
    },
    [signOut],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const prepareLoadData = useCallback(
    (page = 0) => {
      if (inputData === '' || inputData === undefined || inputData === null) {
        loadData(page || 0);
      } else {
        loadData(page || 0, `&filter=${filter}&value=${inputData}`);
      }
    },
    [filter, inputData, loadData],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      setCurrentPage(0); // temp [test]
      prepareLoadData();
    },
    [prepareLoadData],
  );

  const loadResponseLog = useCallback(async (hash: string) => {
    setRowActive(hash);
    setActiveAccounts(undefined);
    setLoadingActiveAccounts(true);
    try {
      const { data } = await api.get(`/admin/logs/response/${hash}`);
      setActiveAccounts(data);
      setLoadingActiveAccounts(false);
    } catch (err) {
      setLoadingActiveAccounts(false);
      setRowActive('');
    }
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <RowGutter>
          <Col>
            <h1>Logs</h1>
            <h2>Utilize o filtro para pesquisar</h2>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px' }}
          >
            <RawInput
              placeholder="Key"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px' }}
          >
            <RawInput
              placeholder="Value"
              value={inputData}
              onChange={e => setInputData(e.target.value)}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
        </RowGutter>
      </form>

      <RowGutter style={{ marginTop: '30px' }} />

      <Col>
        <Table
          dataSource={accounts}
          pagination={false}
          rowKey="hash"
          loading={loading}
          locale={{
            emptyText: 'Nenhum log para exibir.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={5}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: record => (
              <div>
                <h1>Request</h1>
                <ReactJson src={record} collapsed />

                <h1>Response</h1>
                {activeAccounts?.id && (
                  <ReactJson src={activeAccounts} collapsed />
                )}
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    onExpand(record, e);
                  }}
                />
              ) : (
                <RightCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadResponseLog(record.hash);
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        >
          <ColumnGroup title="Dados">
            <Column
              title="ID"
              dataIndex="id"
              key="id"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
            <Column
              title="HASH"
              dataIndex="hash"
              key="hash"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
            <Column
              title="URL"
              dataIndex="path"
              key="path"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
          </ColumnGroup>
          <Column
            title="Data"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy HH:mm:ss')}</>;
            }}
          />
        </Table>
      </Col>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              prepareLoadData(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {accounts && accounts.length >= 50 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage + 1);
              prepareLoadData(currentPage + 1);
            }}
          >
            Próximo <RightOutlined />
          </span>
        )}
      </Pagination>
    </Container>
  );
};

export default Dashboard;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import BankApi from './bank_api';

function findGetParameter(parameterName: string) {
  let result = null;
  let tmp = [];
  // eslint-disable-next-line no-restricted-globals
  const items = location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index += 1) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

const urlClient = () => {
  const productos = [{ name: 'royalpagodash', value: 'royalpago' }];

  for (let i = 0; i < productos.length; i += 1) {
    if (window.location.host.includes(productos[i].name)) {
      return productos[i].value;
    }
  }
  return false;
};
const paramClient = findGetParameter('client');

export const CLIENT = paramClient || urlClient() || 'qesh';

export const getSettings = async () => {
  try {
    const { data: settings } = await BankApi.get('/client-settings', {
      headers: {
        name: CLIENT,
      },
    });
    return settings;
  } catch (error) {
    return null;
  }
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';

class LoanService {
  /**
   * @description Get all loan clients
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */
  async getAllLoanClients(token: any, startDate: any, endDate: any) {
    try {
      const response = await api.get(
        `/admin/credit/intentions/?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            authorization: `teste ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  /**
   * @description Get all loan clients filtered by document
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */
  async searchLoanClientsByDocument(
    token: any,
    document: any,
    startDate: any,
    endDate: any,
  ) {
    try {
      const response = await api.get(
        `/admin/credit/intentions/?document=${document}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            authorization: `teste ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  /**
   * @description Get all loan clients filtered by status
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */
  async searchLoanClientsByStatus(
    token: any,
    status: any,
    startDate: any,
    endDate: any,
  ) {
    try {
      const response = await api.get(
        `/admin/credit/intentions/?current_status=${status}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            authorization: `teste ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  /**
   * @description Update a loan status
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */
  async updateStatus(token: any, id: any, status: any) {
    try {
      const response = await api.patch(
        `/admin/credit/intentions/${id}`,
        {
          current_status: status,
        },
        {
          headers: {
            authorization: `teste ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
}

export default new LoanService();

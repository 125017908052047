/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, notification, Table } from 'antd';
import { format } from 'date-fns';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  ActionContainer,
  Container,
  ICol,
  Pagination,
  Tab,
  TabsContainer,
} from './styles';

import { RawInput, RowGutter } from '../../styles/components';

import api from '../../services/api';

import { documentMask } from '../../services/utils';

const { Column } = Table;

interface IBilletsWhitelist {
  id: string;
  document: string;
  justification: string;
  admin_name: string;
  admin_id: string;
  created_at: string;
  updated_at: string;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

interface IBilletsWhitelistRequest extends PropsMetaData {
  items: IBilletsWhitelist[];
}

const Permissions: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [data, setData] = useState<IBilletsWhitelist[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [documentInputData, setDocumentInputData] = useState('');
  const [justificationInputData, setJustificationInputData] = useState('');

  const [currentTab, setCurrentTab] = useState<'BILLETS_WHITELIST'>(
    'BILLETS_WHITELIST',
  );

  const getData = useCallback(() => {
    setLoading(true);
    api
      .get<IBilletsWhitelistRequest>(`/admin/billets-whitelist`, {
        params: {
          page: currentPage,
        },
      })
      .then(response => {
        setData(response.data.items);
        setMetaData({
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
          total_items: response.data.total_items,
          total_items_page: response.data.total_items_page,
        });
        setLoading(false);
      })
      .catch(() => {
        notification.error({ message: 'Erro ao carregar os dados' });
        setLoading(false);
      });
  }, [currentPage]);

  const handleRemove = useCallback(
    (id: string) => {
      setLoading(true);
      api
        .delete(`/admin/billets-whitelist/${id}`)
        .then(() => {
          notification.success({ message: 'Removido com sucesso' });
          setLoading(false);
          getData();
        })
        .catch(() => {
          notification.error({ message: 'Erro ao carregar os dados' });
          setLoading(false);
        });
    },
    [getData],
  );

  const handleAdd = useCallback(() => {
    if (!documentInputData || !justificationInputData) {
      notification.error({ message: 'Preencha todos os campos' });
      return;
    }
    setLoading(true);

    api
      .post(`/admin/billets-whitelist`, {
        document: documentInputData,
        justification: justificationInputData,
      })
      .then(() => {
        notification.success({ message: 'Adicionado com sucesso' });
        setDocumentInputData('');
        setJustificationInputData('');
        setLoading(false);
        getData();
      })
      .catch(err => {
        notification.error({
          message: err.response?.data?.message || 'Erro ao carregar os dados',
        });
        setLoading(false);
      });
  }, [getData, documentInputData, justificationInputData]);

  useEffect(getData, [currentTab, getData]);

  const formattedWhitelist = useMemo(
    () =>
      data.map(request => {
        return {
          document: request.document,
          justification: request.justification,
          admin_name: request.admin_name,
          created_at: format(
            new Date(String(request.created_at)),
            'dd/MM/yyyy',
          ),
          remove: (
            <a
              rel="noreferrer"
              target="_blank"
              onClick={() => handleRemove(request.id)}
            >
              Remover
            </a>
          ),
        };
      }),
    [handleRemove, data],
  );

  return (
    <Container>
      <RowGutter
        style={{
          marginTop: '15px',
          marginBottom: '15px',
          marginLeft: '0px',
          marginRight: '0px',
        }}
      >
        <TabsContainer>
          <Tab
            onClick={() => {
              setCurrentTab('BILLETS_WHITELIST');
            }}
            selected={currentTab === 'BILLETS_WHITELIST'}
          >
            <span>Whitelist de geração de boletos</span>
          </Tab>
        </TabsContainer>
      </RowGutter>

      {currentTab === 'BILLETS_WHITELIST' && (
        <>
          <ActionContainer>
            <div style={{ width: '100%', textAlign: 'center', margin: 15 }}>
              Adicionar novo registro à whitelist de boletos
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '60%',
                marginBottom: '15px',
              }}
            >
              <div>
                <RawInput
                  placeholder="CPF"
                  value={documentInputData}
                  onChange={e => setDocumentInputData(e.target.value)}
                />
              </div>
              <div>
                <textarea
                  id="justification"
                  value={justificationInputData}
                  style={{
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: 5,
                    padding: 5,
                    height: 44,
                  }}
                  placeholder="JUSTIFICATIVA"
                  onChange={e => setJustificationInputData(e.target.value)}
                />
              </div>
              <div>
                <Button style={{ height: '44px' }} onClick={handleAdd}>
                  Salvar
                </Button>
              </div>
            </div>
          </ActionContainer>

          <ICol>
            <Table
              dataSource={formattedWhitelist}
              pagination={false}
              rowKey="id"
              loading={loading}
              locale={{
                emptyText: 'Nada para mostrar.',
              }}
              scroll={{ x: true }}
            >
              <Column
                title="Documento"
                dataIndex="document"
                key="document"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Justificativa"
                dataIndex="justification"
                key="justification"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={text => documentMask(text)}
              />
              <Column
                title="Quem permitiu"
                dataIndex="admin_name"
                key="admin_name"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Data"
                dataIndex="created_at"
                key="created_at"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title=""
                dataIndex="remove"
                key="remove"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
            </Table>
          </ICol>

          <Pagination>
            {currentPage > 0 && (
              <span
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  getData();
                }}
              >
                <LeftOutlined /> Anterior
              </span>
            )}

            {metaData.total_items_page >= 20 &&
              metaData.current_page !== metaData.total_pages && (
                <span
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    getData();
                  }}
                >
                  Próximo <RightOutlined />
                </span>
              )}
          </Pagination>
        </>
      )}
    </Container>
  );
};

export default Permissions;

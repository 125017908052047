import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

interface IBank {
  id: string;
  name: string;
}
interface User {
  id: string;
  name: string;
  email: string;
  profile: string;
  bank: {
    id: string;
    name: string;
    transaction_id?: string;
  };
  banks: Array<IBank> | boolean;
}

interface AuthState {
  token: string;
  admin: User;
  banks: Array<IBank> | boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  admin: User;
  banks: Array<IBank> | boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  dataChecked: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [dataChecked, setDataChecked] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const token = localStorage.getItem('@BaasQesh:token');
    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      api
        .get('admin/users')
        .then(resp => {
          const { banks } = resp.data;

          setData({
            token,
            admin: resp.data,
            banks,
          });
          setDataChecked(true);
        })
        .catch(err => {
          console.log(err?.response?.data);
          setData({
            token,
          });
          setDataChecked(true);
        });
    } else {
      setDataChecked(true);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@BaasQesh:token');
    localStorage.removeItem('@BaasQesh:original:token');
    localStorage.removeItem('@BaasQesh:user');
    localStorage.removeItem('@BaasQesh:banks');
    localStorage.setItem('displaySocialName', 'false');
    setData({} as AuthState);
    window.location.href = '/';
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/admin/sessions', {
      email,
      password,
    });

    const { token } = response.data;

    localStorage.setItem('@BaasQesh:token', token);
    localStorage.setItem('@BaasQesh:original:token', token);

    api.defaults.headers.authorization = `Bearer ${token}`;

    const { data: userData } = await api.get('admin/users');

    setData({ token, admin: userData, banks: userData.banks });
  }, []);

  const updateUser = useCallback(
    (admin: User) => {
      localStorage.setItem('@BaasQesh:user', JSON.stringify(admin));
      localStorage.setItem('@BaasQesh:banks', JSON.stringify(data.banks));

      setData({
        token: data.token,
        admin,
        banks: data.banks,
      });
    },
    [setData, data.token, data.banks],
  );

  return (
    <AuthContext.Provider
      value={{
        admin: data?.admin,
        banks: data?.banks,
        signIn,
        signOut,
        updateUser,
        dataChecked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import { getSettings } from '../services/client';

const changeFavicon = (link: string) => {
  let favicon = document.querySelector<any>('link[rel="icon"]');
  if (favicon !== null) {
    favicon.href = link;
  } else {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = link;
    document.head.appendChild(favicon);
  }
};

function setZendesk(zendesk: any) {
  const zd = document.createElement('script');
  zd.id = 'ze-snippet';
  zd.src = zendesk;
  document.head.appendChild(zd);
}

function setDefaultWidget(sett: any) {
  const existingAtend = document.getElementById('atendimento');
  if (!existingAtend) {
    const atend = document.createElement('div');
    atend.id = 'atendimento';
    document.body.appendChild(atend);

    const teste = document.getElementById('atendimento');
    if (teste) {
      teste.innerHTML = `
          <div style="
            position: fixed;
            right: 20px;
            bottom: 20px;
            width: 90px;
            height: 40px;
            background-color: ${sett?.colors.primary};
            border-radius: 25px;
            display: flex;
            justify-content: space-around;
            padding: 15px;
            align-items: center;
            font-size: 10pt;
            color: ${sett?.colors.text};
            opacity: 0.9;
            cursor: pointer;"
          >
            <span style="color: white; font-size: 8pt">💬  </span>  Ajuda
          </div>`;
    }

    document.getElementById('atendimento')?.addEventListener('click', () => {
      window.open(`${sett.ib_url}/support`, '_blank', 'noopener,noreferrer');
    });
  }
}

const SettingsContext = createContext({});

const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState({});
  const updateSettings = useCallback(async () => {
    const sett = await getSettings();

    const faviconPath = `/favicon_${sett?.name || 'default'}.png`;
    changeFavicon(faviconPath);

    // if (sett?.support_zendesk) {
    //   setZendesk(sett.support_zendesk);
    // } else {
    //   setDefaultWidget(sett);
    // }
    setDefaultWidget(sett);

    setSettings(sett);
    return sett;
  }, []);

  useEffect(() => {
    updateSettings();
  }, [updateSettings]);
  return (
    <SettingsContext.Provider
      value={{
        settings,
        updateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

function useSettings(): any {
  const context = useContext(SettingsContext);
  return context;
}

export { SettingsProvider, useSettings };

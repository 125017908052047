/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  Link,
} from 'react-router-dom';

import { Layout, Menu, Breadcrumb } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';

import {
  GlobalOutlined,
  UserOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  ConsoleSqlOutlined,
  AlertOutlined,
  BankOutlined,
  LockOutlined,
  LikeOutlined,
  ReadOutlined,
  SolutionOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

import { useSettings } from '../hooks/settings';

import { useAuth } from '../hooks/auth';

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

export const BV_EMAILS = ['____'];

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { admin, signOut, banks, dataChecked } = useAuth();
  const { settings } = useSettings();

  const collapsedSave = localStorage.getItem('@Qesh:collapsed');

  const badgeValue = 3000;
  const [showBadge, setShowBadge] = useState(true);

  const [collapsed, setCollapsed] = useState(
    (collapsedSave && collapsedSave === 'true') || false,
  );

  const selectKeys = useMemo(() => {
    if (rest.path?.includes('accounts')) {
      return '2';
    }

    if (rest.path?.includes('big-data')) {
      return '3';
    }

    if (rest.path?.includes('logs')) {
      return '4';
    }

    if (rest.path?.includes('reports')) {
      return '5';
    }

    if (rest.path?.includes('limit-update-requests')) {
      return '6';
    }

    if (rest.path?.includes('banks-configurations')) {
      return '7';
    }

    if (rest.path?.includes('alerts')) {
      return '8';
    }

    if (rest.path?.includes('users-permissions')) {
      return '9';
    }

    if (rest.path?.includes('recommendation')) {
      return '10';
    }
    if (rest.path?.includes('blacklist')) {
      return '11';
    }
    if (rest.path?.includes('businessaccount')) {
      return '12';
    }
    if (rest.path?.includes('loan')) {
      return '15';
    }
    if (rest.path?.includes('approvepfaccount')) {
      return '16';
    }
    return '1';
  }, [rest.path]);

  const toggle = useCallback(() => {
    setCollapsed(!collapsed);
    localStorage.setItem('@Qesh:collapsed', String(!collapsed));
  }, [collapsed]);

  const changeVisualization = useCallback(async (key: string) => {
    try {
      const originalToken = localStorage.getItem('@BaasQesh:original:token');
      const { data } = await axios.post(
        'https://baas.qesh.ai/v1/admin/sessions',
        {
          authorization: originalToken,
          client_id: key,
        },
      );

      localStorage.setItem('@BaasQesh:token', data.token);
      window.location.href = '/';
    } catch (err) {
      console.log(err);
    }
  }, []);

  const goBackToQesh = useMemo(() => {
    const originalToken = localStorage.getItem('@BaasQesh:original:token');
    const userToken = localStorage.getItem('@BaasQesh:token');
    if (originalToken && userToken && userToken !== originalToken) {
      return (
        <div
          style={{ float: 'right', fontSize: 12, cursor: 'pointer' }}
          onClick={() => {
            localStorage.setItem('@BaasQesh:token', originalToken);
            window.location.href = '/';
          }}
        >
          Voltar à Qesh
        </div>
      );
    }
    return <></>;
  }, []);

  return dataChecked ? (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!admin ? (
          <Layout style={{ minHeight: '100vh' }}>
            {isPrivate ? (
              <>
                {BV_EMAILS.includes(admin.email) ? (
                  <>
                    <Sider
                      collapsible
                      collapsed={collapsed}
                      onCollapse={toggle}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '15px',
                          paddingTop: '15px',
                        }}
                      >
                        <img
                          style={{ maxHeight: '100%', maxWidth: '80%' }}
                          src={`${settings?.default_logo} `}
                          alt="Logo"
                        />
                      </div>
                      <Menu
                        theme="light"
                        defaultSelectedKeys={[selectKeys]}
                        mode="inline"
                      >
                        <Menu.Item key="8" icon={<AlertOutlined />}>
                          <Link to="/alerts">Alertas</Link>
                        </Menu.Item>
                      </Menu>
                    </Sider>
                    <Layout className="site-layout">
                      <Header
                        className="site-layout-background"
                        style={{ padding: 0, justifyContent: 'flex-end' }}
                      >
                        <Menu
                          theme="light"
                          mode="horizontal"
                          style={{ float: 'right' }}
                        >
                          <SubMenu
                            key="sub1"
                            icon={<UserOutlined />}
                            title={admin.name}
                          >
                            <Menu.Item key="1" onClick={signOut}>
                              Sair
                            </Menu.Item>
                          </SubMenu>
                        </Menu>
                      </Header>
                      <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                          {/* <Breadcrumb.Item>Baas</Breadcrumb.Item> */}
                          {/* <Breadcrumb.Item>Dashboard</Breadcrumb.Item> */}
                        </Breadcrumb>
                        <div
                          className="site-layout-background"
                          style={{ padding: 24, minHeight: 360 }}
                        >
                          <Component />
                        </div>
                      </Content>
                      <Footer style={{ textAlign: 'center' }}>
                        {`${`${settings.display_name} ©${format(
                          new Date(),
                          'yyyy',
                        )}`}`}
                      </Footer>
                    </Layout>
                  </>
                ) : (
                  <>
                    <Sider
                      collapsible
                      collapsed={collapsed}
                      onCollapse={toggle}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '15px',
                          paddingTop: '15px',
                        }}
                      >
                        <img
                          style={{ maxHeight: '100%', maxWidth: '80%' }}
                          src={`${settings?.default_logo} `}
                          alt="Logo"
                        />
                      </div>
                      <Menu
                        theme="light"
                        defaultSelectedKeys={[selectKeys]}
                        mode="inline"
                      >
                        <Menu.Item key="1" icon={<DashboardOutlined />}>
                          <Link to="/dashboard">Dashboard</Link>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<UserSwitchOutlined />}>
                          <Link to="/accounts">Contas</Link>
                        </Menu.Item>
                        {['Kexion'].includes(admin.bank.name) && (
                          <>
                            <Menu.Item key="10" icon={<LikeOutlined />}>
                              <Link to="/recommendation">Recomendações</Link>
                            </Menu.Item>
                          </>
                        )}

                        {admin?.profile === 'ADMIN' &&
                          admin?.bank.name === 'Qesh Payments' && (
                            <>
                              <Menu.Item key="3" icon={<GlobalOutlined />}>
                                <Link to="/big-data">KYC</Link>
                              </Menu.Item>
                              <Menu.Item key="4" icon={<DatabaseOutlined />}>
                                <Link to="/logs">Logs</Link>
                              </Menu.Item>
                              <Menu.Item key="5" icon={<ConsoleSqlOutlined />}>
                                <Link to="/reports">Consultas avançadas</Link>
                              </Menu.Item>
                              <Menu.Item
                                key="6"
                                icon={<DollarCircleOutlined />}
                              >
                                <Link to="/limit-update-requests">
                                  Aprovação de limite
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="7" icon={<BankOutlined />}>
                                <Link to="/banks-configurations">Bancos</Link>
                              </Menu.Item>
                              <Menu.Item key="9" icon={<LockOutlined />}>
                                <Link to="/users-permissions">Permissões</Link>
                              </Menu.Item>
                              <Menu.Item key="8" icon={<AlertOutlined />}>
                                <Link to="/alerts">Alertas</Link>
                              </Menu.Item>
                              <Menu.Item key="11" icon={<ReadOutlined />}>
                                <Link to="/blacklist">Blacklist</Link>
                              </Menu.Item>
                              <Menu.Item key="12" icon={<SolutionOutlined />}>
                                <Link to="/businessaccount">
                                  Análise conta PJ
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="16" icon={<SolutionOutlined />}>
                                <Link to="/approvepfaccount">
                                  Análise conta PF
                                </Link>
                              </Menu.Item>
                              {/* <SubMenu
                                onTitleClick={() => setShowBadge(!showBadge)}
                                key="13"
                                icon={
                                  // eslint-disable-next-line react/jsx-wrap-multilines
                                  <>
                                    {showBadge ? (
                                      <Badge
                                        size="small"
                                        count={badgeValue}
                                        style={{ background: '#521AA3' }}
                                        offset={[-25, 20]}
                                      >
                                        <FileSearchOutlined />
                                      </Badge>
                                    ) : (
                                      <FileSearchOutlined />
                                    )}
                                  </>
                                }
                                title="Compliance"
                              >
                                <Menu.Item key="14" icon={<GlobalOutlined />}>
                                  KYC
                                </Menu.Item>
                                <Menu.Item key="15" icon={<ReadFilled />}>
                                  Blacklist
                                </Menu.Item>
                                <Menu.Item
                                  key="16"
                                  icon={
                                    // eslint-disable-next-line react/jsx-wrap-multilines
                                    <Badge
                                      size="small"
                                      count={badgeValue}
                                      style={{ background: '#521AA3' }}
                                      offset={[-25, 20]}
                                    >
                                      <AlertOutlined />
                                    </Badge>
                                  }
                                >
                                  <Link to="/complianceAlerts">Alertas</Link>
                                </Menu.Item>
                              </SubMenu> */}
                            </>
                          )}
                        {admin?.bank.name === 'Faemg' && (
                          <>
                            <SubMenu
                              title="Empréstimo"
                              key="13"
                              icon={<FileTextOutlined />}
                            >
                              <Menu.Item key="14" icon={<FolderOpenOutlined />}>
                                <Link to="/loan">Acompanhamento</Link>
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}
                      </Menu>
                    </Sider>
                    <Layout className="site-layout">
                      <Header
                        className="site-layout-background"
                        style={{ padding: 0, justifyContent: 'flex-end' }}
                      >
                        <Menu
                          theme="light"
                          mode="horizontal"
                          style={{ float: 'right' }}
                        >
                          <SubMenu
                            key="sub1"
                            icon={<UserOutlined />}
                            title={admin.name}
                          >
                            <Menu.Item key="1" onClick={signOut}>
                              Sair
                            </Menu.Item>
                          </SubMenu>
                        </Menu>

                        {Array.isArray(banks) ? (
                          <Menu
                            theme="light"
                            mode="horizontal"
                            style={{ float: 'right' }}
                          >
                            <SubMenu
                              key="sub2"
                              // icon={<UserOutlined />}
                              title={`Visualizar como: ${admin.bank.name}`}
                            >
                              {Array.isArray(banks) &&
                                banks
                                  .filter(bank => bank.name !== admin.bank.name)
                                  .map(b => (
                                    <Menu.Item
                                      key={b.id}
                                      onClick={() => changeVisualization(b.id)}
                                    >
                                      {b.name}
                                    </Menu.Item>
                                  ))}
                            </SubMenu>
                          </Menu>
                        ) : (
                          goBackToQesh
                        )}
                      </Header>
                      <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                          {/* <Breadcrumb.Item>Baas</Breadcrumb.Item> */}
                          {/* <Breadcrumb.Item>Dashboard</Breadcrumb.Item> */}
                        </Breadcrumb>
                        <div
                          className="site-layout-background"
                          style={{ padding: 24, minHeight: 360 }}
                        >
                          <Component />
                        </div>
                      </Content>
                      <Footer style={{ textAlign: 'center' }}>
                        {`${`${settings?.display_name} ©${format(
                          new Date(),
                          'yyyy',
                        )}`}`}
                      </Footer>
                    </Layout>
                  </>
                )}
              </>
            ) : (
              <>
                <Component />
              </>
            )}
          </Layout>
        ) : dataChecked ? (
          <>
            <Redirect
              to={{
                pathname: isPrivate
                  ? '/'
                  : BV_EMAILS.includes(admin.email)
                  ? 'alerts'
                  : '/accounts',
                state: { from: location },
              }}
            />
          </>
        ) : (
          <></>
        );
      }}
    />
  ) : (
    <></>
  );
};

export default Route;

import api from './api';

export const putMainPartner = async (
  account_id: string,
  user_id: string,
  doc: string,
): Promise<boolean> => {
  try {
    await api.put(
      '/admin/pj/additional/accept',
      {},
      {
        headers: {
          account: account_id,
          user: user_id,
          document: doc,
        },
      },
    );
    return true;
  } catch (error) {
    console.log('erro:', error);
    return false;
  }
};

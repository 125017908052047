export function HandleFormatMoney(amount: number): string {
  try {
    return amount.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } catch (e) {
    return amount?.toString();
  }
}

export function documentMask(val: string): string {
  try {
    if (val && val.length) {
      const docVal = val.replace(/[/,.,-]/g, '');
      // MASK CPF / CNPJ
      if (docVal.length <= 14) {
        if (docVal.length > 12) {
          // cnpj  `##.###.###/####-##`
          const cnpj = /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})\.*([\d]{2})/;
          return docVal.replace(cnpj, '$1.$2.$3/$4-$5');
        }
        // cpf `###.###.###-##`
        const cpf = /([\d]{3})\.*([\d]{3})\.*([\d]{3})-*([\d]{2})/;
        return docVal.replace(cpf, '$1.$2.$3-$4');
      }
      return val;
    }

    return val;
  } catch {
    return val;
  }
}

export function phoneMask(val: string): string {
  try {
    if (val && val.length) {
      const phone = /^(?:\+55)?\s?\(?0?[1-9][1-9]\)?\s?(?:9)?\s?\d{4}-?\d{4}$/;

      return val.replace(phone, '($2)$5-$4');
    }
    return val;
  } catch {
    return val;
  }
}

export function getDocumentCategory(category: string): string {
  switch (category) {
    case 'SELFIE':
      return 'Selfie frontal';
    case 'SELFIEL':
      return 'Selfie esquerda';
    case 'SELFIER':
      return 'Selfie direita';
    case 'SELFIEU':
      return 'Selfie para cima';
    case 'SELFIED':
      return 'Selfie para baixo';
    case 'INVALID_SELFIER':
      return 'Selfie direita';
    case 'INVALID_SELFIEL':
      return 'Selfie esquerda';
    case 'INVALID_SELFIEU':
      return 'Selfie para cima';
    case 'INVALID_SELFIED':
      return 'Selfie para baixo';
    case 'INVALID_SELFIE':
      return 'Selfie frontal';
    case 'DRIVER_LICENSE_FRONT':
      return 'Frente da CNH';
    case 'DRIVER_LICENSE_VERSE':
      return 'Verso da CNH';
    case 'IDENTITY_CARD_FRONT':
      return 'Frente da Identidade';
    case 'IDENTITY_CARD_VERSE':
      return 'Verso da Identidade';
    case 'IDENTITY_CARD_VERIFY':
      return 'Selfie com documento';
    case 'NEWIDENTITY_CARD_FRONT':
      return 'Frente da nova identidade';
    case 'NEWIDENTITY_CARD_VERSE':
      return 'Verso da nova identidade';
    case 'COMPANY':
      return 'Documento PJ';
    case 'DOCUMENT_VERSE':
      return 'Verso do documento';
    case 'DOCUMENT_FRONT':
      return 'Frente do documento';
    default:
      return '';
  }
}

export function getTransactionStatusConfig(
  status: string,
): { color: string; status: string } {
  switch (status) {
    case 'APPROVED':
      return { color: 'rgb(135, 208, 104)', status: 'Aprovada' };
    case 'EXECUTED':
      return { color: 'rgb(135, 208, 104)', status: 'Aprovada' };
    case 'REJECTED':
      return { color: '#ff4252', status: 'Estornada' };
    case 'PRCESSING':
      return { color: '#427eff', status: 'Processando' };
    default:
      return { color: '', status: '' };
  }
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Button, Table, DatePicker, notification } from 'antd';
import 'moment/locale/pt-br';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  SearchOutlined,
  DownCircleFilled,
  RightCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import {
  Container,
  ICol,
  DateContainer,
  TableInfoArea,
  SearchSelectArea,
  SuccessButton,
  DangerButton,
  AlertButton,
  LargeAlertButton,
  SelectArea,
  TableButtonArea,
  TableButton,
} from './styles';
import { RowGutter, Col, RawInput, SelectInput } from '../../styles/components';
import LoanService from '../../services/loanService';

const Loan: React.FC = () => {
  const [start, setStart] = React.useState<Moment | null>(
    moment().startOf('month'),
  );
  const [end, setEnd] = React.useState<Moment | null>(moment().endOf('month'));
  const [allLoanClients, setAllLoanClients] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rowActive, setRowActive] = React.useState<string>('');
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [searchSelect, setSearchSelect] = React.useState<string>('Documento');
  const [searchStatus, setSearchStatus] = React.useState<string>('Status');
  const [selectNewStatus, setSelectNewStatus] = React.useState<string>(
    'Status',
  );

  React.useEffect(() => {
    setSelectNewStatus('Status');
  }, [rowActive]);

  React.useEffect(() => {
    getLoanClients();
  }, []);

  const getLoanClients = async () => {
    setLoading(true);
    const token = localStorage.getItem('@BaasQesh:token');
    const startDate = `${start}01:12:20.107`;
    const endDate = `${end}01:12:20.107`;
    await LoanService.getAllLoanClients(token, startDate, endDate).then(
      response => {
        setAllLoanClients(response.credit_intention_list.items);
        setLoading(false);
      },
    );
  };

  const getLoanClientsBySearch = async (text: string) => {
    if (searchInput !== '' && searchInput.length >= 11) {
      setLoading(true);
      const token = localStorage.getItem('@BaasQesh:token');
      const startDate = `${start}01:12:20.107`;
      const endDate = `${end}01:12:20.107`;
      await LoanService.searchLoanClientsByDocument(
        token,
        text,
        startDate,
        endDate,
      ).then(response => {
        setAllLoanClients(response.credit_intention_list.items);
        setLoading(false);
      });
    } else {
      notification.open({
        message: 'Erro ao buscar os clientes',
        description: 'Por favor, digite um documento válido',
        icon: (
          <CloseCircleFilled style={{ color: '#e91010', marginTop: '10px' }} />
        ),
      });
    }
  };

  const getLoanClientsByStatus = async (status: string) => {
    if (status !== 'Status') {
      setLoading(true);
      const token = localStorage.getItem('@BaasQesh:token');
      const startDate = `${start}01:12:20.107`;
      const endDate = `${end}01:12:20.107`;
      await LoanService.searchLoanClientsByStatus(
        token,
        status,
        startDate,
        endDate,
      ).then(response => {
        setAllLoanClients(response.credit_intention_list.items);
        setLoading(false);
      });
    }
  };

  const updateLoanStatus = async (id: string, status: string) => {
    if (status !== 'Status') {
      setLoading(true);
      const token = localStorage.getItem('@BaasQesh:token');
      await LoanService.updateStatus(token, id, status).then(response => {
        getLoanClients();
      });
      setLoading(false);
    }
  };

  const handleStatus = (status: string): JSX.Element => {
    if (status === 'IN_RECEPTION_PARTNER') {
      return <LargeAlertButton>Em acolhimento - Parceiro</LargeAlertButton>;
    }
    if (status === 'IN_RECEPTION_BANK') {
      return <LargeAlertButton>Em acolhimento - Banco</LargeAlertButton>;
    }
    if (status === 'PENDING') {
      return <DangerButton>Com pendência</DangerButton>;
    }
    if (status === 'UNDER_ANALYSIS') {
      return <AlertButton>Em análise</AlertButton>;
    }
    if (status === 'ON_APPROVAL') {
      return <AlertButton>Em aprovação</AlertButton>;
    }
    if (status === 'AWAITING_FORMALIZATION') {
      return <LargeAlertButton>Aguardando Formalização</LargeAlertButton>;
    }
    if (status === 'FORMALIZATION') {
      return <AlertButton>Formalização</AlertButton>;
    }
    if (status === 'RELEASED') {
      return <SuccessButton>Liberada</SuccessButton>;
    }
    return <></>;
  };

  const handleDate = (date: string): string => {
    const splitDate = date.split('T')[0].split('-');
    const formatedDateString = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
    return formatedDateString;
  };

  const handleCpf = (cpf: string): string => {
    const newCpf = cpf.replace(/[^\d]/g, '');
    return newCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const columns = [
    { title: 'Tipo de empréstimo', dataIndex: 'credit_type', key: 'loanType' },
    { title: 'Usuário', dataIndex: 'name', key: 'user' },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
      render: (cpf: string) => handleCpf(cpf),
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'date',
      render: (date: string) => handleDate(date),
    },
    {
      title: 'Número da Proposta',
      dataIndex: 'proposal_number',
      key: 'proposal_number',
    },
    {
      title: 'Status Atual',
      dataIndex: 'current_status',
      key: 'status',
      render: (status: string) => handleStatus(status),
    },
    { title: '', key: 'button_area' },
  ];

  const clearFilter = (): void => {
    setStart(moment().startOf('month'));
    setEnd(moment().endOf('month'));
    setSearchInput('');
    setSearchSelect('Documento');
    setSearchSelect('Status');
    getLoanClients();
  };

  const handleSearchSelect = (value: any): void => {
    setSearchSelect(value);
  };

  const handleSearchStatus = (value: any): void => {
    setSearchStatus(value);
  };

  const handleSelectNewStatus = (value: any): void => {
    setSelectNewStatus(value);
  };

  return (
    <Container>
      <RowGutter>
        <Col>
          <h1>Atualização de status - empréstimos</h1>
          <h2>Utilize o filtro para pesquisar</h2>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 6 }}
          md={{ span: 3 }}
          lg={{ span: 3 }}
          style={{ marginBottom: '5px' }}
        >
          <SelectInput value={searchSelect} onChange={handleSearchSelect}>
            <SelectInput.Option value="document">Documento</SelectInput.Option>
          </SelectInput>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          style={{ marginBottom: '5px' }}
        >
          <RawInput
            placeholder="Buscar"
            value={searchInput}
            maxLength={14}
            onChange={e => setSearchInput(e.target.value.replace(/[^\d]/g, ''))}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Button
            htmlType="submit"
            style={{ height: '44px', width: '44px' }}
            type="dashed"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => getLoanClientsBySearch(searchInput)}
          />
          <Button
            htmlType="submit"
            style={{
              marginLeft: '14px',
            }}
            onClick={() => {
              clearFilter();
            }}
          >
            Limpar filtro
          </Button>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 9 }}
          lg={{ span: 9 }}
          style={{
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <DateContainer>
            <span style={{ fontSize: 12 }}>De</span>
            <DatePicker
              value={start}
              defaultValue={moment().startOf('month')}
              locale={locale}
              format="DD/MM/YYYY"
              onChange={m => setStart(m)}
            />
          </DateContainer>
          <DateContainer>
            <span style={{ fontSize: 12 }}>Até</span>
            <DatePicker
              value={end}
              defaultValue={moment().endOf('month')}
              format="DD/MM/YYYY"
              locale={locale}
              onChange={m => setEnd(m)}
            />
          </DateContainer>
        </Col>
      </RowGutter>
      <ICol>
        <Col
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: '100vw',
            marginTop: '40px',
          }}
        >
          <SearchSelectArea>
            <SelectInput
              value={searchStatus}
              onChange={handleSearchStatus}
              style={{ maxWidth: '190px', marginRight: '15px' }}
            >
              <SelectInput.Option value="IN_RECEPTION_PARTNER">
                Em Acolhimento - Parceiro
              </SelectInput.Option>
              <SelectInput.Option value="IN_RECEPTION_BANK	">
                Em Acolhimento - Banco
              </SelectInput.Option>
              <SelectInput.Option value="PENDING" style={{ color: '#DA1E28' }}>
                Com Pendência
              </SelectInput.Option>
              <SelectInput.Option value="UNDER_ANALYSIS">
                Em Análise
              </SelectInput.Option>
              <SelectInput.Option value="ON_APPROVAL">
                Em Aprovação
              </SelectInput.Option>
              <SelectInput.Option value="AWAITING_FORMALIZATION">
                Aguardando Formalização
              </SelectInput.Option>
              <SelectInput.Option value="FORMALIZATION">
                Formalizada
              </SelectInput.Option>
              <SelectInput.Option value="RELEASED" style={{ color: '#24A148' }}>
                Liberada
              </SelectInput.Option>
            </SelectInput>

            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
              onClick={() => getLoanClientsByStatus(searchStatus)}
            />
          </SearchSelectArea>
        </Col>
        <Table
          columns={columns}
          dataSource={allLoanClients}
          pagination={false}
          rowKey="user_id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={6}
          expandRowByClick={false}
          onExpand={(expanded, record) => {
            setRowActive(record.id);
          }}
          expandable={{
            expandedRowRender: record => (
              <TableInfoArea>
                <SelectArea>
                  <SelectInput
                    value={selectNewStatus}
                    onChange={handleSelectNewStatus}
                  >
                    <SelectInput.Option value="IN_RECEPTION_PARTNER">
                      Em Acolhimento - Parceiro
                    </SelectInput.Option>
                    <SelectInput.Option value="IN_RECEPTION_BANK">
                      Em Acolhimento - Banco
                    </SelectInput.Option>
                    <SelectInput.Option
                      value="PENDING"
                      style={{ color: '#DA1E28' }}
                    >
                      Com Pendência
                    </SelectInput.Option>
                    <SelectInput.Option value="UNDER_ANALYSIS">
                      Em Análise
                    </SelectInput.Option>
                    <SelectInput.Option value="ON_APPROVAL">
                      Em Aprovação
                    </SelectInput.Option>
                    <SelectInput.Option value="AWAITING_FORMALIZATION">
                      Aguardando Formalização
                    </SelectInput.Option>
                    <SelectInput.Option value="FORMALIZATION">
                      Formalizada
                    </SelectInput.Option>
                    <SelectInput.Option
                      value="RELEASED"
                      style={{ color: '#24A148' }}
                    >
                      Liberada
                    </SelectInput.Option>
                  </SelectInput>
                </SelectArea>
                <TableButtonArea>
                  <TableButton
                    onClick={() => updateLoanStatus(record.id, selectNewStatus)}
                  >
                    Atualizar status
                  </TableButton>
                </TableButtonArea>
              </TableInfoArea>
            ),
            rowExpandable: record => record.id === rowActive,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleFilled
                  style={{
                    fontSize: 21,
                    color: '#6F6F6F',
                  }}
                  onClick={e => {
                    setRowActive('');
                    onExpand(record, e);
                  }}
                />
              ) : (
                <RightCircleFilled
                  style={{
                    fontSize: 21,
                    color: '#6F6F6F',
                  }}
                  onClick={e => {
                    setRowActive(record.id);
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        />
      </ICol>
    </Container>
  );
};

export default Loan;

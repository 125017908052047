import React, { useCallback, useState } from 'react';
import {
  Form,
  Divider,
  Table,
  notification,
  Tooltip,
  Button,
  Checkbox,
} from 'antd';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import Column from 'antd/lib/table/Column';
import { FiUpload } from 'react-icons/fi';
import { IMaskedInput } from '../styles';
import { ContainerContent } from '../../../Account/styles';
import { PropsUserAccount, PropsAccount } from '../../../Account/index';
import { ButtonContainer } from '../styles';
import { CreatedAtContainer } from '../styles';
import {
  RowGutter,
  Col,
  RawInput,
  RowButton,
} from '../../../../../../styles/components';

import { useAuth } from '../../../../../../hooks/auth';
import DocumentUploadModal from '../../../../../../components/Modals/DocumentUploadModal';

interface ICepResponseData {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

const DataPersonalPf: React.FC<{
  user: PropsUserAccount;
  account?: PropsAccount;
  getRisk: (conditions: any) => JSX.Element;
  handleSavePersonalData: any;
  handleUpdateAddress: any;
  handleUpdateAdditional: any;
  formattedDocuments: any;
}> = ({
  user: userParam,
  account,
  getRisk,
  handleSavePersonalData,
  handleUpdateAddress,
  handleUpdateAdditional,
  formattedDocuments,
}) => {
  const [user] = useState(userParam);
  const { admin } = useAuth();
  const [personalDataForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [additionalForm] = Form.useForm();
  const [userAccount, setUserAccount] = useState(user);
  const [
    isDocumentUploadModalOpened,
    setIsDocumentUploadModalOpened,
  ] = useState(false);
  const [birthDate] = useState(
    user.additional
      ? format(parseISO(user.additional.birth_date), 'dd/MM/yyyy')
      : '',
  );

  const clientQesh =
    admin.profile === 'ADMIN' && admin.bank.name === 'Qesh Payments';

  const handleCepValueChange = useCallback(
    async (value: string) => {
      if (value.split('').length !== 8) {
        return;
      }

      try {
        const { data } = await axios.get<ICepResponseData>(
          `https://viacep.com.br/ws/${value}/json/`,
        );

        addressForm.setFieldsValue({
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [addressForm],
  );

  return (
    <ContainerContent>
      <span style={{ float: 'right' }}>{user.user.bank?.name}</span>
      <br />
      <CreatedAtContainer>
        <span>
          Data de criação do usuário:{' '}
          {format(parseISO(String(user.user.created_at)), 'dd/MM/yyyy')}
        </span>
      </CreatedAtContainer>
      <CreatedAtContainer>
        {getRisk(userAccount?.generalConditions)}
        {/* <span>
          Risco: <span style={{ color: 'grey' }}>{setRisk()}</span>
        </span> */}
      </CreatedAtContainer>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Dados Pessoais
      </Divider>

      <Form
        scrollToFirstError
        form={personalDataForm}
        layout="vertical"
        onFinish={handleSavePersonalData}
      >
        <RowGutter>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              label="Nome Completo"
              name="name"
              initialValue={user.user.name}
            >
              <RawInput name="name" disabled={!clientQesh} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item label="Documento">
              <IMaskedInput
                mask="999.999.999-99"
                name="document"
                value={user.user.document}
                type="cpf"
                disabled
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item label="Celular">
              <IMaskedInput
                mask="(99) 99999-9999"
                name="phone"
                value={user.user.phone}
                type="phone"
                disabled
              />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira o e-mail',
                },
                {
                  type: 'email',
                  message: 'Esse e-mail não é válido!',
                },
              ]}
              initialValue={user.user.email}
            >
              <RawInput name="email" disabled={!clientQesh} />
            </Form.Item>
            {admin?.bank?.name === 'Diverse Bank' &&
              user?.user?.social_name !== null && (
                <Form.Item
                  name="social_name"
                  label="Nome Social"
                  initialValue={user.user.social_name}
                >
                  <RawInput name="social_name" disabled={!clientQesh} />
                </Form.Item>
              )}
          </Col>
        </RowGutter>
        <ButtonContainer>
          <RowButton
            disabled={
              (admin.profile !== 'ADMIN' &&
                admin.bank.name !== 'Qesh Payments') ||
              !clientQesh
            }
            style={{ width: '100px' }}
            type="primary"
            htmlType="submit"
          >
            Salvar
          </RowButton>
        </ButtonContainer>
      </Form>

      {user?.address && (
        <>
          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
            }}
          >
            Endereço
          </Divider>
          <Form
            form={addressForm}
            layout="vertical"
            onFinish={handleUpdateAddress}
          >
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  initialValue={user.address.zip_code}
                  label="Cep"
                  name="zip_code"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o cep.',
                    },
                    { len: 8, message: 'Digite 8 dígitos.' },
                  ]}
                >
                  <RawInput
                    onChange={e => handleCepValueChange(e.target.value)}
                    name="zip_code"
                    disabled={!clientQesh}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user.address.street}
                  label="Rua"
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a rua',
                    },
                  ]}
                >
                  <RawInput name="street" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
              >
                <Form.Item
                  initialValue={user.address.number}
                  label="N°"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o número.',
                    },
                    {
                      // eslint-disable-next-line consistent-return
                      validator: async (_, number) => {
                        if (Number.isNaN(Number(number))) {
                          return Promise.reject(
                            new Error('Por favor insira apenas números.'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <RawInput name="number" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="complement"
                  label="Complemento"
                  initialValue={user.address?.complement}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o complemento',
                    },
                  ]}
                >
                  <RawInput name="complement" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
              >
                <Form.Item
                  initialValue={user.address.neighborhood}
                  name="neighborhood"
                  label="Bairro"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o bairro',
                    },
                  ]}
                >
                  <RawInput name="neighborhood" disabled={!clientQesh} />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  initialValue={user.address.city}
                  label="Cidade"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a cidade',
                    },
                  ]}
                >
                  <RawInput name="city" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
              >
                <Form.Item
                  initialValue={user.address.state}
                  name="state"
                  label="Estado"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o estado',
                    },
                  ]}
                >
                  <RawInput name="state" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <ButtonContainer>
              <RowButton
                type="primary"
                disabled={!clientQesh}
                style={{ width: '100px' }}
                htmlType="submit"
              >
                Salvar
              </RowButton>
            </ButtonContainer>
          </Form>
        </>
      )}

      {user?.additional && (
        <>
          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
            }}
          >
            Dados Complementares
          </Divider>
          <Form
            form={additionalForm}
            layout="vertical"
            onFinish={handleUpdateAdditional}
          >
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label="Nacionalidade"
                  name="nationality"
                  initialValue={user.additional.nationality}
                >
                  <RawInput name="nationality" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label="Data de Nascimento"
                  initialValue={birthDate}
                  name="birth_date"
                >
                  <RawInput name="birth_date" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user?.additional?.education}
                  label="Escolaridade"
                  name="education"
                  rules={[
                    {
                      required: true,
                      message: 'Por Favor! Informe a escolaridade',
                    },
                  ]}
                >
                  <RawInput name="education" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user?.additional?.occupation}
                  label="Profissão"
                  name="occupation"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a profissão',
                    },
                  ]}
                >
                  <RawInput name="occupation" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user?.additional?.monthly_income}
                  label="Renda Mensal"
                  name="monthly_income"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira nova renda mensal',
                    },
                  ]}
                >
                  <RawInput name="monthly_income" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  name="patrimony"
                  label="Patrimônio"
                  initialValue={user?.additional?.patrimony}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o valor do patrimônio',
                    },
                  ]}
                >
                  <RawInput name="patrimony" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user?.additional?.relationship}
                  name="relationship"
                  label="Estado Cívil"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o estado cívil',
                    },
                  ]}
                >
                  <RawInput name="relationship" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label="Nome da Mãe"
                  name="mother_name"
                  initialValue={user?.additional.mother_name}
                >
                  <RawInput name="mother_name" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user?.additional?.isPEP}
                  name="ispep"
                  valuePropName="checked"
                >
                  <Checkbox name="ispep" disabled={!clientQesh}>
                    Pessoa Politicamente Exposta (PEP)
                  </Checkbox>
                </Form.Item>
              </Col>
            </RowGutter>
            <ButtonContainer>
              <RowButton
                type="primary"
                disabled={!clientQesh}
                style={{ width: '100px' }}
                htmlType="submit"
              >
                Salvar
              </RowButton>
            </ButtonContainer>
          </Form>
        </>
      )}

      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Documentos
      </Divider>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          marginBottom: '8px',
        }}
      >
        <Tooltip title="Fazer upload de um documento">
          <RowButton
            onClick={() => setIsDocumentUploadModalOpened(true)}
            disabled={!clientQesh}
            type="primary"
            style={{ width: '50px' }}
          >
            <FiUpload />
          </RowButton>
        </Tooltip>
      </div>
      {userAccount?.documents && userAccount.documents.length > 0 && (
        <>
          <Table
            showHeader
            dataSource={formattedDocuments}
            rowKey="id"
            pagination={false}
            // loading={loading}
            locale={{
              emptyText: 'Nenhum documento para mostrar.',
            }}
            scroll={{ x: '500px' }}
          >
            <Column title="Categoria" dataIndex="category" key="category" />
            <Column title="Data" dataIndex="date" key="date" />
            <Column title="" dataIndex="link_to_pic" key="link_to_pic" />
          </Table>
        </>
      )}
      <DocumentUploadModal
        userId={user.user.id}
        visible={isDocumentUploadModalOpened}
        hide={() => {
          setIsDocumentUploadModalOpened(false);
        }}
      />
    </ContainerContent>
  );
};

export default DataPersonalPf;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { notification, Button, Spin } from 'antd';
import api from '../../services/api';
import { generateSheet } from './template';
// import { useAuth } from '../../hooks/auth';

const AccountsSheet: React.FC<{ client: { id: string; name: string } }> = ({
  client,
}) => {
  const [loading, setLoading] = useState(false);
  // const { admin } = useAuth();

  // const allAccountsInfoQuery = useCallback(
  //   () =>
  //     `SELECT * FROM users u INNER JOIN accounts ac ON ac.user_id = u.id ORDER BY u.created_at DESC`,
  //   [],
  // );

  // const allUsersWithoutAccountQuery = useCallback(
  //   () =>
  //     `SELECT * FROM users u WHERE u.id NOT IN (SELECT user_id from accounts) ORDER BY u.created_at DESC`,
  //   [],
  // );

  // const runQuery = useCallback(async code => {
  //   try {
  //     const { data } = await api.post('/admin/logs/raw', {
  //       raw: code,
  //     });
  //     return data;
  //   } catch (err) {
  //     notification.error({
  //       message: 'Invalid.',
  //     });
  //     return null;
  //   }
  // }, []);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      // if (admin?.bank?.name === 'Qesh Payments') {
      //   const accounts = await runQuery(allAccountsInfoQuery());
      //   const usersWithoutAccount = await runQuery(
      //     allUsersWithoutAccountQuery(),
      //   );
      //   await generateSheet(client, [...accounts, ...usersWithoutAccount]);
      // }
      const { data: accounts } = await api.get('/admin/accounts/report');

      await generateSheet(client, accounts);
      // }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          'Ocorreu um erro ao gerar o relatório de contas. Tente novamente mais tarde.',
      });
    }
    setLoading(false);
  }, [client]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {loading && <Spin size="small" style={{ marginRight: 15 }} />}
      <Button onClick={handleClick} disabled={loading}>
        Gerar relatório de contas
      </Button>
    </div>
  );
};

export default AccountsSheet;

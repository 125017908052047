import React, { useState, useEffect } from 'react';

import { CarouselProvider, Slider } from 'pure-react-carousel';

import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Modal, ButtonStyle } from './styled';

interface Dimg {
  url: string;
}

interface IModalProps {
  visible: boolean;
  hide: () => void;
  img: [];
  index: number;
}

const ModalImage: React.FC<IModalProps> = ({ visible, hide, img, index }) => {
  const [ind, setInd] = useState(index);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    setInd(index);
  }, [index]);
  return (
    <Modal
      wrapClassName="ModalImage"
      visible={visible}
      onOk={hide}
      closable={false}
      onCancel={hide}
      footer={false}
      destroyOnClose
      bodyStyle={{
        padding: 0,
      }}
      key="modal"
    >
      <div style={{ backgroundColor: 'transparent' }}>
        <CloseOutlined
          onClick={hide}
          style={{
            display: 'flex',
            justifyContent: 'right',
            color: 'red',
            backgroundColor: 'transparent',
            marginRight: 75,
            marginBottom: 5,
            fontSize: 20,
          }}
        />
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={10}
          totalSlides={3}
          dragEnabled={false}
          infinite
        >
          <div>
            <Slider>
              {img?.map((url, i) => {
                return (
                  ind === i && (
                    <>
                      <img
                        style={{
                          position: 'absolute',
                          alignContent: 'center',
                        }}
                        className="nameimg"
                        src={String(url)}
                        alt="not found"
                        width={500}
                        onLoad={(e: any) => {
                          setImgHeight(e?.target?.naturalHeight);
                        }}
                      />
                    </>
                  )
                );
              })}
            </Slider>

            <ButtonStyle className="button" ih={imgHeight}>
              <LeftOutlined
                style={{ fontSize: 50, color: 'white' }}
                onClick={() => {
                  if (
                    (img[ind - 1 <= 0 ? 0 : ind - 1] as any)?.includes('pdf')
                  ) {
                    ind - 2 <= 0 ? setInd(ind) : setInd(ind - 2);
                  } else {
                    ind <= 0 ? setInd(0) : setInd(ind - 1);
                  }
                }}
              />
              <RightOutlined
                style={{ fontSize: 50, color: 'white' }}
                onClick={() => {
                  if ((img[ind + 1] as any)?.includes('pdf')) {
                    ind + 2 >= img.length - 1 ? setInd(ind) : setInd(ind + 2);
                  } else {
                    ind >= img.length - 1
                      ? setInd(img.length - 1)
                      : setInd(ind + 1);
                  }
                }}
              />
            </ButtonStyle>
          </div>
        </CarouselProvider>
      </div>
    </Modal>
  );
};

export default ModalImage;

import React, { useState, useCallback } from 'react';
import { Button, notification, Row, Col, Input, Upload, Switch } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FaFilePdf } from 'react-icons/fa';

import Axios from 'axios';
import api from '../../../services/api';
import bank from '../../../services/bank_api';

import { Modal } from './styled';

import { documentMask } from '../../../services/utils';

const { TextArea } = Input;

interface IModalProps {
  visible: boolean;
  hide: () => void;
  id: string;
  doc: string;
  clientId: string;
}

const AllowBlockAccontModal: React.FC<IModalProps> = ({
  visible,
  hide,
  id,
  doc,
  clientId,
}) => {
  const [inputData, setInputData] = useState('');

  const [currentFile, setCurrentFile] = useState<null | string>(null);
  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const [enablePreview, setEnablePreview] = useState(true);

  const [FileList, setFileList] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const beforeUpload = useCallback(
    file => {
      setEnablePreview(true);
      if (file.type === 'application/pdf') {
        setEnablePreview(false);

        if (file.size > 33096064) {
          notification.error({ message: 'No máximo 3MB por arquivo.' });
          return true;
        }
      }
      if (file.size > 5000000) {
        setCurrentFile('');
        FileList.pop();
        notification.warning({
          message: `O arquivo passa do tamanha de 5MB e não podera ser enviado`,
        });
      }
      setFileList([...FileList, file]);
      return false;
    },
    [FileList],
  );

  const handleUpload: () => void = useCallback(async () => {
    const formData = new FormData();
    setLoading(true);
    if (inputData === '' || inputData === undefined || inputData === null) {
      notification.error({
        message: `Favor inserir um motivo para liberar o documento ${documentMask(
          doc,
        )}`,
      });
      setLoading(false);
      return;
    }
    FileList.forEach((file: any, index: number) => {
      formData.append('files', file.originFileObj);
    });
    formData.append('comment', inputData);

    try {
      await bank.patch('/admin/skip-checkpoint', formData, {
        headers: {
          admintoken: localStorage.getItem('@BaasQesh:token'),
          client: clientId,
          document: doc,
          checked,
          'Content-Type': `multipart/form-data;`,
        },
      });

      notification.success({
        message: `A pessoa foi aprovada com Sucesso.`,
      });
      setLoading(false);
      setFileList([]);
      setInputData('');
      hide();
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.data?.message.includes('without permition')) {
        notification.error({
          message: `Houve um erro na Aprovação de Etapa.`,
        });
      } else if (
        err?.response?.data?.message.match(/Documents missing - \[.+\]/i)
      ) {
        const message = err?.response?.data?.message;
        const missing = message.match(/\[(.+)\]/g)[0];
        notification.error({
          message: `Não foi possivel executar. Falta a atualização ${
            missing.includes('SELFIE') && 'da Selfie'
          }${
            missing.includes('SELFIE') && missing.includes('DOCUMENTS') && ' e '
          }${missing.includes('DOCUMENTS') && 'dos Documentos'}.`,
        });
      } else {
        notification.error({
          message: `Houve um erro na aprovação da pessoa.`,
        });
      }
    }
  }, [inputData, FileList, checked]);

  const handleChange: any = () => {
    setChecked(!checked);
  };

  return (
    <Modal
      wrapClassName="AddDocumentBlackListModal"
      centered
      visible={visible}
      onOk={() => {
        setFileList([]);
        setInputData('');
        setLoading(false);
        hide();
      }}
      closable={false}
      onCancel={() => {
        setFileList([]);
        setInputData('');
        setLoading(false);
        hide();
      }}
      footer={false}
      destroyOnClose
      bodyStyle={{
        padding: 20,
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <div>
        <CloseOutlined
          onClick={() => {
            setFileList([]);
            setInputData('');
            setLoading(false);
            hide();
          }}
          style={{
            display: 'flex',
            justifyContent: 'right',
            color: 'red',
          }}
          size={30}
        />
        <h2
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: '#42C1C7',
          }}
        >
          Detalhamento do motivo
        </h2>
        <TextArea
          rows={6}
          style={{ background: '#F4F4F4', resize: 'none' }}
          placeholder="Escreva aqui o motivo que justifique a liberação do usuário"
          value={inputData}
          onChange={(e: any) => setInputData(e?.target?.value)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '5px',
          }}
        >
          Aprovar Etapa:&nbsp;
          <Switch checked={checked} onChange={handleChange} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList
              multiple
              beforeUpload={beforeUpload}
              fileList={FileList}
              accept=".pdf, image/png, image/jpeg"
              onChange={async (info: any) => {
                setFileList(info.fileList);
                if (info.fileList[info.fileList.length - 1]?.size > 5000000) {
                  await info.fileList.pop();
                }
              }}
            >
              {currentFile ? (
                <>
                  {enablePreview ? (
                    <img
                      src={currentFile || ''}
                      alt="Selfie"
                      style={{
                        width: '100%',
                        marginTop: '60px',
                        marginBottom: '60px',
                      }}
                    />
                  ) : (
                    <FaFilePdf size={125} />
                  )}
                </>
              ) : (
                <div>
                  <div style={{ marginBottom: '5px' }}>
                    Clique para fazer upload
                  </div>
                  {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                </div>
              )}
            </Upload>
          </div>
          <div>
            <Row style={{ display: 'flex', flexWrap: 'nowrap', marginTop: 40 }}>
              <Col>
                <LoadingOutlined
                  width={50}
                  height={50}
                  style={{
                    opacity: `${loading ? 1 : 0}`,
                    marginRight: 10,
                    marginTop: 9,
                  }}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    handleUpload();
                  }}
                  type="default"
                  disabled={loading}
                  style={{
                    borderRadius: 6,
                    color: 'white',
                    background: 'green',
                    borderWidth: 0,
                  }}
                >
                  <span>Liberar</span>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllowBlockAccontModal;

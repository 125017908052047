import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  background: #fff;

  padding: 16px;
  border-radius: 6px;

  box-shadow: rgba(9, 72, 121, 0.08) 0px 5px 30px 0px;
  display: flex;
  flex-direction: column;

  color: rgb(51, 51, 51);
  font-weight: 500;
  margin-bottom: 30px;

  h1 {
    font-size: 24px;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: rgb(102, 102, 102);
  }
`;

export const TopSidebar = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 1px solid rgb(221, 221, 221);

  margin-bottom: 30px;
`;

export const Avatar = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  /* background-color: rgb(236, 240, 241); */
  background-color: #42c1c7;
  border-radius: 50%;
  font-size: 32px;
  letter-spacing: 0.6px;
  color: rgb(255, 255, 255);
  font-weight: 500;
  margin-bottom: 30px;
`;

export const Menu = styled.div``;

export const MenuItem = styled.div`
  align-items: center;
  font-size: 14px;
  color: rgb(102, 102, 102);
  letter-spacing: 0.2px;
  font-weight: 500;
`;

export const ContainerContent = styled.div`
  /* display: flex; */
  /* flex: 1; */
  background: '#f0f2f5';

  padding: 16px;
  border-radius: 16px;
`;

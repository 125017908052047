import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100vw;
  height: 100vh; */
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 500px;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 80px;

  img {
    max-width: 100%;
    width: 100px;
    align-self: center;
    margin-bottom: 30px;
  }
`;

export const Box = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(9, 72, 121, 0.08) 0px 5px 30px 0px;

  border-radius: 5px;
  padding: 50px;

  form {
    div {
      margin-bottom: 20px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }
  }
`;

import React from 'react';
import { Switch } from 'react-router-dom';

import Route, { BV_EMAILS } from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Contas from '../pages/Accounts';
import Recommendation from '../pages/recommendation';
import BigData from '../pages/BigData';
import ContasDetail from '../pages/Accounts/Detail/Account';
import UsersDetail from '../pages/Accounts/Detail/User';

import Logs from '../pages/Logs';
import Sql from '../pages/Sql';

import Blank from '../pages/Blank';

import { useAuth } from '../hooks/auth';
import LimitApproval from '../pages/LimitApproval';
import BanksConfigurations from '../pages/BanksConfigurations';
import Alerts from '../pages/Alerts';

import BlacklList from '../pages/BlackList';
import BusinessAccount from '../pages/BusinessAccount';
import Permissions from '../pages/Permissions';
import Loan from '../pages/Loan';
import AprovePfAccount from '../pages/AprovePfAccount';

const Routes: React.FC = () => {
  const { admin } = useAuth();
  return (
    <Switch>
      {BV_EMAILS.includes(admin?.email) ? (
        <>
          <Route path="/" exact component={SignIn} />
          <Route path="/alerts" exact component={Alerts} isPrivate />
        </>
      ) : (
        <>
          <Route path="/" exact component={SignIn} />

          <Route path="/dashboard" exact component={Dashboard} isPrivate />
          <Route path="/accounts" exact component={Contas} isPrivate />
          <Route
            path="/recommendation"
            exact
            component={Recommendation}
            isPrivate
          />
          <Route
            path="/accounts/:account_id"
            exact
            component={ContasDetail}
            isPrivate
          />

          <Route
            path="/users/:user_id"
            exact
            component={UsersDetail}
            isPrivate
          />

          {admin?.profile === 'ADMIN' && admin?.bank.name === 'Qesh Payments' && (
            <>
              <Route path="/big-data" exact component={BigData} isPrivate />
              <Route path="/logs" exact component={Logs} isPrivate />
              <Route path="/reports" exact component={Sql} isPrivate />
              <Route
                path="/limit-update-requests"
                exact
                component={LimitApproval}
                isPrivate
              />
              <Route
                path="/banks-configurations"
                exact
                component={BanksConfigurations}
                isPrivate
              />
              <Route
                path="/users-permissions"
                exact
                component={Permissions}
                isPrivate
              />
              <Route path="/blacklist" exact component={BlacklList} isPrivate />
              <Route
                path="/businessaccount"
                exact
                component={BusinessAccount}
                isPrivate
              />
              <Route path="/alerts" exact component={Alerts} isPrivate />
              {/* <Route
                path="/complianceAlerts"
                exact
                component={ComplianceAlerts}
                isPrivate
              /> */}
            </>
          )}
          {admin?.bank.name === 'Faemg' && (
            <>
              <Route path="/loan" exact component={Loan} isPrivate />
            </>
          )}
          <Route
            path="/approvepfaccount"
            exact
            component={AprovePfAccount}
            isPrivate
          />

          <Route path="/blank" exact component={Blank} isPrivate />
        </>
      )}
    </Switch>
  );
};

export default Routes;

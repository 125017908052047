/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  Button,
  Table,
  Tag,
  notification,
  Dropdown,
  Menu,
  DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';

import { endOfDay, format, parseISO } from 'date-fns';

import {
  // LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import moment, { Moment } from 'moment';
import { Container, Pagination, ICol } from './styles';

import { RowGutter, Col, RawInput, SelectInput } from '../../styles/components';
import AccountsSheet from '../../components/AccountsSheet';

import api from '../../services/api';

import { documentMask } from '../../services/utils';

const { TabPane } = Tabs;

const { Column } = Table;

const Alerts: React.FC = () => {
  const [accounts, setAccounts] = useState([] as Array<any>);
  const [currentTab, setCurrentTab] = useState('not-resolved');
  const [filter, setFilter] = useState('all');
  const [start, setStart] = useState<Moment | null>(moment().startOf('month'));
  const [end, setEnd] = useState<Moment | null>(moment().endOf('month'));

  const [data, setData] = useState([]);

  const handleTabChange = useCallback(key => {
    switch (key) {
      case '1':
        setCurrentTab('not-resolved');
        break;
      case 2:
        setCurrentTab('resolved');
        break;
      default:
        setCurrentTab('not-resolved');
    }
  }, []);

  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    setLoading(true);
    if (start && end) {
      try {
        let alerts = null;
        if (currentTab === 'not-resolved') {
          const { data: alertsResponse } = await api.get(
            `/admin/users/alerts?start=${start.format(
              'YYYY-MM-DD',
            )}&end=${end.format('YYYY-MM-DD')}`,
          );
          alerts = alertsResponse;
        } else {
          // const { data: alertsResponse } = await api.get(
          //   `/admin/users/alerts?start=${start.format(
          //     'YYYY-MM-DD',
          //   )}&end=${end.format('YYYY-MM-DD')}`,
          // );
          alerts = [];
        }
        switch (filter) {
          case 'ALL':
            setData(alerts);
            break;
          case 'FAST_TRANSACTIONS':
            setData(
              alerts.filter((item: any) => item.type === 'FAST_TRANSACTIONS'),
            );
            break;
          case 'MULTIPLE_TRANSACTIONS':
            setData(
              alerts.filter(
                (item: any) => item.type === 'MULTIPLE_TRANSACTIONS',
              ),
            );
            break;
          default:
            setData(alerts);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
  }, [start, end, filter, currentTab]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const formattedData = useMemo(
    () =>
      data?.map((item: any) => {
        return {
          ...item,
          document: documentMask(item.account.user.document),
          name: item.account.user.name,
          status: item.account.status,
          bank_account: item.account.bank_account,
          id: item.account.id,
          bank: item.account.user.bank.name,
        };
      }),
    [data],
  );

  // const handleMenuClick = useCallback((key, record) => {
  //   switch (key) {
  //     case '1':
  //       window.open(`https://dash.qesh.ai/accounts/${}`, '_blank');
  //       break;
  //   }
  // });

  return (
    <Container>
      <RowGutter>
        <Col>
          <h1>Alertas</h1>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 6 }}
          md={{ span: 3 }}
          lg={{ span: 3 }}
          style={{ marginBottom: '5px' }}
        >
          <div style={{ height: 25 }}>
            <SelectInput
              defaultValue="Todos"
              onChange={e => setFilter(String(e))}
              style={{ width: 400 }}
            >
              <SelectInput.Option value="ALL">Todos</SelectInput.Option>
              <SelectInput.Option value="FAST_TRANSACTIONS">
                Transações de valor expressivo em curto período
              </SelectInput.Option>
              <SelectInput.Option value="MULTIPLE_TRANSACTIONS">
                Múltiplas transações em curto período
              </SelectInput.Option>
            </SelectInput>
          </div>
        </Col>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              marginRight: 10,
            }}
          >
            <>
              <span style={{ fontSize: 12 }}>De</span>
              <DatePicker
                defaultValue={start || moment().startOf('month')}
                locale={locale}
                format="DD/MM/YYYY"
                onChange={m => setStart(m)}
              />
            </>
          </div>
          <div
            style={{
              marginLeft: 0,
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <span style={{ fontSize: 12 }}>Até</span>
              <DatePicker
                defaultValue={end || moment().endOf('month')}
                format="DD/MM/YYYY"
                locale={locale}
                onChange={m => setEnd(m)}
              />
            </>
          </div>
        </div>
      </RowGutter>

      <RowGutter style={{ marginTop: '30px' }} />

      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Pendentes" key="1">
          <ICol>
            <Table
              dataSource={formattedData}
              pagination={false}
              loading={loading}
              locale={{
                emptyText: 'Nenhum registro para mostrar.',
              }}
              scroll={{ x: true }}
            >
              <Column
                title="Descrição"
                dataIndex="description"
                key="description"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Banco"
                dataIndex="bank"
                key="bank"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Documento"
                dataIndex="document"
                key="document"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Nome"
                dataIndex="name"
                key="name"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Conta"
                dataIndex="bank_account"
                key="bank_account"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Gravidade"
                dataIndex="flag"
                key="flag"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={text => {
                  if (text === 'yellow') {
                    return (
                      <Tag
                        style={{ fontWeight: 700, marginRight: 0 }}
                        color="#d4c522"
                      >
                        Média
                      </Tag>
                    );
                  }
                  return (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#f80850"
                    >
                      Alta
                    </Tag>
                  );
                }}
              />
              <Column
                title="Data"
                dataIndex="created_at"
                key="created_at"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={text => {
                  return text ? format(new Date(text), 'dd/MM/yyyy') : <>-</>;
                }}
              />
              {/* <Column
                title="Ações"
                dataIndex="actions"
                key="actions"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(_text, record) => (
                  <Dropdown
                    overlay={() => (
                      <Menu>
                        {/* <Menu.Item>Marcar como resolvido</Menu.Item> }
                      </Menu>
                    )}
                    placement="bottomCenter"
                    arrow
                  >
                    <MoreOutlined style={{ cursor: 'pointer' }} />
                  </Dropdown>
                )}
              /> */}
            </Table>
          </ICol>
        </TabPane>
        <TabPane tab="Concluídos" key="2">
          <ICol>
            <Table
              dataSource={[]}
              pagination={false}
              loading={loading}
              locale={{
                emptyText: 'Nenhum registro para mostrar.',
              }}
              scroll={{ x: true }}
            >
              <Column
                title="Descrição"
                dataIndex="description"
                key="description"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Banco"
                dataIndex="bank"
                key="bank"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Documento"
                dataIndex="document"
                key="document"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Nome"
                dataIndex="name"
                key="name"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Conta"
                dataIndex="bank_account"
                key="bank_account"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Gravidade"
                dataIndex="flag"
                key="flag"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={text => {
                  if (text === 'yellow') {
                    return (
                      <Tag
                        style={{ fontWeight: 700, marginRight: 0 }}
                        color="#d4c522"
                      >
                        Média
                      </Tag>
                    );
                  }
                  return (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#f80850"
                    >
                      Alta
                    </Tag>
                  );
                }}
              />
              {/* <Column
                title="Ações"
                dataIndex="actions"
                key="actions"
                align="center"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(_text, record) => (
                  <Dropdown
                    overlay={() => (
                      <Menu>
                        {/* <Menu.Item key="1">Marcar como resolvido</Menu.Item>
                        <Menu.Item key="2">Visualizar conta</Menu.Item> }
                      </Menu>
                    )}
                    placement="bottomCenter"
                    arrow
                  >
                    <MoreOutlined style={{ cursor: 'pointer' }} />
                  </Dropdown>
                )}
              /> */}
            </Table>
          </ICol>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Alerts;

import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, notification, Tooltip, Form, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ContainerContent } from '../../Account/styles';
import { SuspendButton, UnsuspendButton, ButtonContainer } from './styles';
import { PropsAccount, PropsUserAccount } from '../../Account';
import api from '../../../../../services/api';

import {
  RowGutter,
  Col,
  RawInput,
  RowButton,
} from '../../../../../styles/components';

interface PropsParams {
  account_id: string;
}

interface IPersonalizationFormData {
  alias: string;
}

const Settings: React.FC<{
  user: PropsUserAccount;
  account: PropsAccount;
  onDelete: () => Promise<void>;
}> = ({ account: accountParam, onDelete }) => {
  const { account_id } = useParams<PropsParams>();
  const [accountPersonalizationForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [unsuspendLoading, setUnsuspendLoading] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(!!accountParam.suspended_at);

  const handleSaveAccountPersonalization = useCallback(
    async (values: IPersonalizationFormData) => {
      try {
        await api.patch(`/admin/accounts/alias/${accountParam.id}`, {
          ...values,
        });

        notification.success({
          message: 'Apelido da conta atualizados com sucesso',
        });
      } catch (err) {
        notification.error({
          message: 'Não foi possível atualizar os dados de apelido da conta.',
        });
      }
    },
    [],
  );

  const handleDelete = useCallback(async () => {
    setLoading(true);

    try {
      const { data: response } = await api.delete(
        `admin/accounts/suspend/${account_id}`,
      );

      if (!response.is_suspended) {
        notification.error({
          message: 'Não foi possível suspender essa conta.',
        });
      }

      notification.success({
        message: 'Conta suspensa com sucesso.',
      });

      setDeleted(true);
      onDelete();
    } catch (e) {
      notification.error({
        message: 'Não foi possível suspender essa conta.',
      });
    } finally {
      setLoading(false);
    }
  }, [account_id, onDelete]);

  const handleUnsuspendAccount = useCallback(async () => {
    setUnsuspendLoading(true);

    try {
      const { data: response } = await api.patch(
        `admin/accounts/unsuspend/${account_id}`,
      );

      if (!response.is_unsuspended) {
        notification.error({
          message: 'Não foi possível remover a suspensão dessa conta.',
        });
      }

      notification.success({
        message: 'Suspensão da conta removida com sucesso.',
      });

      setDeleted(false);
      onDelete();
    } catch (e) {
      notification.error({
        message: 'Não foi possível remover a suspensão dessa conta.',
      });
    } finally {
      setUnsuspendLoading(false);
    }
  }, [account_id, onDelete]);

  return (
    <ContainerContent>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Personalização da Conta
      </Divider>
      <Form
        form={accountPersonalizationForm}
        layout="vertical"
        onFinish={handleSaveAccountPersonalization}
      >
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item label="Agência">
              <RawInput value={accountParam.bank_branch} disabled />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item label="Conta">
              <RawInput value={accountParam.bank_account} disabled />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <Form.Item
              initialValue={accountParam.alias}
              label="Apelido da conta"
              name="alias"
            >
              <RawInput name="alias" />
            </Form.Item>
          </Col>
        </RowGutter>
        <ButtonContainer>
          <RowButton
            type="primary"
            style={{ width: '100px' }}
            htmlType="submit"
          >
            Salvar
          </RowButton>
        </ButtonContainer>
      </Form>
    </ContainerContent>
  );
};

export default Settings;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-self-compare */
/* eslint-disable no-useless-concat */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  Statistic,
  notification,
  Progress,
  Button,
  Tabs,
  Modal,
  DatePicker,
  Spin,
  // DatePicker,
  // Spin,
} from 'antd';

// import ptBR from 'antd/es/date-picker/locale/pt_BR';

import Chart from 'react-apexcharts';

import {
  UserOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

// import { Moment } from 'moment';
import { format, startOfMonth } from 'date-fns';
import moment from 'moment';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { RowGutter, Col } from '../../styles/components';

import { Container, StyledChart } from './styles';

import api from '../../services/api';
import { HandleFormatMoney } from '../../services/utils';

import { useAuth } from '../../hooks/auth';
import Extract from '../Accounts/Detail/menus/Extract';
import { PropsUserAccount } from '../Accounts/Detail/User';

const { TabPane } = Tabs;

interface PropsQesh {
  accounts: number;
  profit: number;
  transaction: number;
  statics: {
    total: number;
    complete: number;
    incomplete: number;
    blocked: number;
  };
}

interface PropsStatic {
  total: number;
  complete: number;
  incomplete: number;
  blocked: number;
}

interface PropsRevenues {
  teds: number;
  tefs: number;
  billets: number;
  pixTransfers: number;
  pixQrCodes: number;
  payments: number;
  phoneTopups: number;
  total: number;
}
interface ObjectMetric {
  // time: string;
  sum: number;
  count: string;
}
interface PropsMetrics {
  teds: Array<ObjectMetric>;
  tefs: Array<ObjectMetric>;
  billets: Array<ObjectMetric>;
  payments: Array<ObjectMetric>;
  pixReceivements: Array<ObjectMetric>;
  pixTransfers: Array<ObjectMetric>;
  topups: Array<ObjectMetric>;
}

interface PropsTransaction {
  // name: string;
  // balance: number;
  transaction: number;
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingCharts, setLoadingCharts] = useState(true);
  const [startDate, setStartDate] = useState<string>(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState<string>(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const [pool, setPool] = useState(0);
  const [qesh, setQesh] = useState({} as PropsQesh);
  const [statics, setStatics] = useState({} as PropsStatic);
  // const [revenues, setRevenues] = useState<PropsRevenues | null>(null);
  const [metrics, setMetrics] = useState({} as PropsMetrics);
  const [transactions, setTransactions] = useState({} as PropsTransaction);
  const { admin, signOut } = useAuth();
  const [transactionsModalVisible, setTransactionsModalVisible] = useState(
    false,
  );
  const [
    transactionAccountInfo,
    setTransactionAccountInfo,
  ] = useState<null | PropsUserAccount>(null);

  // const [revenuesDate, setRevenuesDate] = useState<Moment | null>(
  //   // moment(new Date()).startOf('month'),
  //   null,
  // );

  const numberMask = useCallback((value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d)/, 'R$ $1')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');
  }, []);

  const loadBalances = useCallback(async () => {
    setLoadingCharts(true);
    try {
      const { data } = await api.get(
        `/admin/dashboard/balances?start=${startDate}&end=${endDate}`,
      );
      setPool(data.pool || false);
      setQesh(data.qesh);
      setStatics(data.statics);
      // setRevenues(data.revenues);
      setTransactions(data.transactions || []);
      setMetrics(data.metrics);
      setLoading(false);
      setLoadingCharts(false);
    } catch (err) {
      setLoadingCharts(false);
      setLoading(false);
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });
        signOut();
      } else {
        notification.error({
          message: 'Não foi possível buscar informações do Dashboard',
        });
      }
    }
  }, [signOut, startDate, endDate]);
  // const loadRevenues = useCallback(async () => {
  //   setRevenues(null);
  //   if (revenuesDate) {
  //     try {
  //       const startDate = revenuesDate.startOf('month').toISOString();
  //       const endDate = revenuesDate
  //         .add(1, 'month')
  //         .startOf('month')
  //         .subtract(1, 'second')
  //         .toISOString();

  //       const { data } = await api.get(
  //         `/admin/dashboard/balances/revenues?start=${startDate}&end=${endDate}`,
  //       );
  //       setRevenues(data);
  //     } catch (err) {
  //       console.log(err);
  //       setLoading(false);
  //       if (err?.response?.data?.message === 'There is another active token') {
  //         notification.error({
  //           message:
  //             'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
  //         });
  //         signOut();
  //       } else {
  //         notification.error({
  //           message: 'Não foi possível buscar informações do Dashboard',
  //         });
  //       }
  //     }
  //   }
  // }, [signOut, revenuesDate]);

  const bankNames = [
    'Sorte Esportiva',
    'Directa24',
    'Urano',
    'vctrade',
    'FDS Games',
    'Nuvei',
  ];
  const isNameOnTheList = (): boolean => {
    let value = false;
    bankNames.forEach(name => {
      if (admin.bank.name === name) {
        value = true;
      }
    });
    return value;
  };
  const loadTransactionAccountInfo = useCallback(async () => {
    if (admin?.bank?.transaction_id) {
      try {
        const { data } = await api.get(`/admin/accounts/details`, {
          headers: {
            account: admin.bank.transaction_id,
          },
        });
        setTransactionAccountInfo(data);
      } catch {
        //
      }
    }
  }, [admin]);

  useEffect(() => {
    loadBalances();
  }, [loadBalances]);

  // useEffect(() => {
  //   loadRevenues();
  // }, [loadRevenues]);

  useEffect(() => {
    loadTransactionAccountInfo();
  }, [loadTransactionAccountInfo]);

  if (loading) {
    return <div>Carregando</div>;
  }

  interface IHashMap {
    [key: number]: any;
  }
  const tooltipHash: IHashMap = {
    0: metrics.teds[0],
    1: metrics.tefs[0],
    2: metrics.payments[0],
    3: metrics.billets[0],
    4: metrics.pixReceivements[0],
    5: metrics.pixTransfers[0],
    6: metrics.topups[0],
  };

  const handleDatePickersChange = (date: any, dateString: string): Date => {
    return new Date(date);
  };
  return (
    <Container>
      {!!transactionsModalVisible && !!transactionAccountInfo && (
        <Modal
          title="Extrato de conta de transição"
          visible
          onOk={() => setTransactionsModalVisible(false)}
          onCancel={() => setTransactionsModalVisible(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          width="60%"
          bodyStyle={{ maxHeight: 500, overflow: 'scroll' }}
        >
          <Extract
            allowDownload={false}
            user={transactionAccountInfo}
            account={transactionAccountInfo as any}
          />
        </Modal>
      )}
      <RowGutter>
        {statics && statics?.total && (
          <>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              style={{ marginBottom: '5px' }}
            >
              <Card>
                <Statistic
                  title="Total cadastros"
                  value={statics.total || 0}
                  loading={pool === 0}
                  prefix={<UserOutlined style={{ marginRight: '5px' }} />}
                />
                <Progress
                  percent={100}
                  size="small"
                  status="active"
                  showInfo={false}
                />
              </Card>
            </Col>

            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              style={{ marginBottom: '5px' }}
            >
              <Card>
                <Statistic
                  title="Cadastros completos"
                  value={statics.complete || 0}
                  loading={pool === 0}
                  prefix={
                    <CheckCircleOutlined style={{ marginRight: '5px' }} />
                  }
                />
                <Progress
                  percent={Math.ceil((statics.complete * 100) / statics.total)}
                  size="small"
                  status="normal"
                  strokeColor="#87d068"
                />
              </Card>
            </Col>

            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              style={{ marginBottom: '5px' }}
            >
              <Card>
                <Statistic
                  title="Cadastros incompletos"
                  value={statics.incomplete || 0}
                  loading={pool === 0}
                  prefix={
                    <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                  }
                />
                <Progress
                  percent={Math.floor(
                    (statics.incomplete * 100) / statics.total,
                  )}
                  size="small"
                  status="normal"
                  strokeColor="#ffbb00"
                />
              </Card>
            </Col>

            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              style={{ marginBottom: '5px' }}
            >
              <Card>
                <Statistic
                  title="Cadastros bloqueados"
                  value={statics.blocked || 0}
                  loading={pool === 0}
                  prefix={
                    <CloseCircleOutlined style={{ marginRight: '5px' }} />
                  }
                />
                <Progress
                  percent={Math.floor((statics.blocked * 100) / statics.total)}
                  size="small"
                  status="normal"
                  strokeColor="#f80850"
                />
              </Card>
            </Col>
          </>
        )}
      </RowGutter>
      <RowGutter style={{ marginTop: '20px' }}>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 6 }}
          md={{ span: 18 }}
          lg={{ span: 18 }}
        >
          <div>
            <h1 style={{ marginLeft: '15px' }}>Métricas</h1>
            {admin.bank.name === 'Sorte Esportiva' && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <DatePicker
                  disabled={loadingCharts}
                  format="DD/MM/YYYY"
                  placeholder="Data inicial"
                  value={moment(String(startDate), 'YYYY-MM-DD')}
                  clearIcon={null}
                  locale={ptBR}
                  onChange={
                    (date, dateString) =>
                      setStartDate(
                        format(
                          handleDatePickersChange(date, dateString),
                          'yyyy-MM-dd',
                        ),
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                <DatePicker
                  disabled={loadingCharts}
                  format="DD/MM/YYYY"
                  placeholder="Data final"
                  value={moment(String(endDate), 'YYYY-MM-DD')}
                  clearIcon={null}
                  locale={ptBR}
                  onChange={
                    (date, dateString) =>
                      setEndDate(
                        format(
                          handleDatePickersChange(date, dateString),
                          'yyyy-MM-dd',
                        ),
                      )
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              </div>
            )}
            {metrics?.teds && (
              <>
                <Tabs
                  style={{ marginLeft: '15px' }}
                  defaultActiveKey="1"
                  size="middle"
                >
                  <TabPane tab="OPERACIONAL" key="1">
                    {loadingCharts ? (
                      <div
                        style={{
                          width: '100%',
                          height: '300px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Spin />
                      </div>
                    ) : (
                      <StyledChart
                        type="bar"
                        height={350}
                        options={{
                          theme: {
                            mode: 'light',
                            palette: 'palette4',
                          },
                          colors: ['#1890ff', '#1890ff', '#1890ff', '#1890ff'],
                          chart: {
                            height: 350,
                            width: '100%',
                            type: 'bar',
                            zoom: {
                              enabled: false,
                            },
                          },
                          plotOptions: {
                            bar: {
                              borderRadius: 2,
                              horizontal: false,
                              distributed: false,
                            },
                          },
                          stroke: {
                            show: true,
                            curve: 'straight',
                            lineCap: 'butt',
                            colors: ['transparent'],
                            width: 3,
                            dashArray: 0,
                          },
                          labels: [
                            'TEDS',
                            'TEFS',
                            'PAGAMENTOS',
                            'BOLETOS',
                            'RECEB. PIX',
                            'TRANSF. PIX',
                            'RECARGAS',
                          ],
                          dataLabels: {
                            enabled: true,
                            style: {
                              colors: ['#000'],
                            },
                          },
                          xaxis: {
                            categories: [
                              'TEDS',
                              'TEFS',
                              'PAGAMENTOS',
                              'BOLETOS',
                              'RECEB. PIX',
                              'TRANSF. PIX',
                              'RECARGAS',
                            ],
                          },
                          tooltip: {
                            custom({
                              dataPointIndex,
                            }: {
                              dataPointIndex: number;
                            }) {
                              return `${
                                '<div class="arrow_box">' +
                                '<span>' +
                                `Quantidade: ${tooltipHash[dataPointIndex].count}` +
                                '</span>' +
                                '<br/>' +
                                '<span>'
                              }${
                                admin.bank.name === 'Sorte Esportiva'
                                  ? `Soma total: ${numberMask(
                                      tooltipHash[dataPointIndex].sum
                                        .toFixed(2)
                                        .toString(),
                                    )}` + '</span>'
                                  : ''
                              }</div>`;
                            },
                          },
                          yaxis: {
                            opposite: true,
                          },
                          legend: {
                            horizontalAlign: 'left',
                          },
                        }}
                        series={[
                          {
                            name: 'Quantidade',
                            data: [
                              Number(metrics?.teds[0]?.count),
                              Number(metrics?.tefs[0]?.count),
                              Number(metrics?.payments[0]?.count),
                              Number(metrics?.billets[0]?.count),
                              Number(metrics?.pixReceivements[0]?.count),
                              Number(metrics?.pixTransfers[0]?.count),
                              Number(metrics?.topups[0]?.count),
                            ],
                          },
                        ]}
                      />
                    )}
                  </TabPane>

                  {admin?.profile === 'ADMIN' && (
                    <TabPane tab="FINANCEIRO" key="5">
                      <RowGutter>
                        {pool > 0 && (
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Saldo bolsão"
                                value={HandleFormatMoney(pool || 0)}
                                loading={pool === 0}
                              />
                            </Card>
                          </Col>
                        )}
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 12 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}
                          style={{ marginBottom: '5px' }}
                        >
                          <Card>
                            <Statistic
                              title="Saldo em contas"
                              // value={HandleFormatMoney(qesh.accounts || 0)}
                              value={HandleFormatMoney(37364468.9 || 0)}
                              loading={pool === 0}
                            />
                          </Card>
                        </Col>
                        {/* <Col
                          xs={{ span: 12 }}
                          sm={{ span: 12 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}
                          style={{ marginBottom: '5px' }}
                        >
                          <Card>
                            <Statistic
                              title="Saldo Transaction"
                              value={HandleFormatMoney(qesh.transaction || 0)}
                              loading={pool === 0}
                            />
                          </Card>
                        </Col> */}
                        {qesh.profit && (
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Saldo Profit"
                                value={HandleFormatMoney(qesh.profit || 0)}
                                loading={pool === 0}
                              />
                            </Card>
                          </Col>
                        )}
                      </RowGutter>
                    </TabPane>
                  )}
                  {/* <TabPane tab="RECEITAS" key="6">
                    <DatePicker
                      onSelect={date => {
                        if (date) {
                          setRevenuesDate(date);
                        }
                      }}
                      picker="month"
                      format="MMMM/YYYY"
                      placeholder="Selecione o mês"
                      locale={ptBR}
                      clearIcon={false}
                      monthCellRender={month => {
                        return <span>{month?.format('MMMM')}</span>;
                      }}
                      style={{ marginBottom: 20 }}
                    />
                    {revenues ? (
                      <>
                        <RowGutter>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="TEDs"
                                value={
                                  HandleFormatMoney(+revenues.teds || 0) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="TEFs"
                                value={
                                  HandleFormatMoney(+revenues.tefs || 0) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Gerações de boleto"
                                value={
                                  HandleFormatMoney(+revenues.billets || 0) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                        </RowGutter>
                        <RowGutter>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Pagamentos de boleto"
                                value={
                                  HandleFormatMoney(+revenues.payments || 0) ||
                                  0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="QRCodes Pix"
                                value={
                                  HandleFormatMoney(
                                    +revenues.pixQrCodes || 0,
                                  ) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Transferências Pix"
                                value={
                                  HandleFormatMoney(
                                    +revenues.pixTransfers || 0,
                                  ) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                        </RowGutter>
                        <RowGutter>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Recargas de celular"
                                value={
                                  HandleFormatMoney(
                                    +revenues.phoneTopups || 0,
                                  ) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            style={{ marginBottom: '5px' }}
                          >
                            <Card>
                              <Statistic
                                title="Total de receitas no período"
                                value={
                                  HandleFormatMoney(+revenues.total || 0) || 0
                                }
                                loading={false}
                              />
                            </Card>
                          </Col>
                        </RowGutter>
                      </>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {!!revenuesDate && <Spin size="large" />}
                      </div>
                    )}
                  </TabPane> */}
                </Tabs>
              </>
            )}
          </div>
        </Col>

        <Col
          xs={{ span: 6 }}
          sm={{ span: 6 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <div style={{ maxHeight: '400px', overflow: 'auto' }}>
            {isNameOnTheList() ? (
              <></>
            ) : (
              <>
                <h1 style={{ marginLeft: '15px' }}>Conta de transição</h1>
                <Col style={{ marginBottom: '15px' }}>
                  <Card>
                    <Statistic
                      title="Saldo"
                      value={HandleFormatMoney(
                        Number(
                          transactions?.transaction || qesh.transaction || 0,
                        ),
                      )}
                      loading={pool === 0}
                    />
                    {/* <Button style={{ marginTop: '15px' }}>Recarregar</Button> */}
                    {!!transactionAccountInfo &&
                      !!(
                        admin.bank.name === 'NM Pay' ||
                        admin.bank.name === 'Kexion' ||
                        admin.bank.name === 'Qesh Payments'
                      ) && (
                        <Button
                          onClick={() => setTransactionsModalVisible(true)}
                          style={{ marginTop: '15px' }}
                        >
                          Extrato
                        </Button>
                      )}
                  </Card>
                </Col>
              </>
            )}

            {/* {transactions && transactions.length > 0 && (
              <>
                {transactions.map(t => (
                  <Col key={t.name} style={{ marginBottom: '15px' }}>
                    <Card>
                      <Statistic
                        title={t.name}
                        value={HandleFormatMoney(t.balance || 0)}
                        loading={pool === 0}
                      />
                    </Card>
                  </Col>
                ))}
              </>
            )} */}
          </div>
        </Col>
      </RowGutter>
    </Container>
  );
};

export default Dashboard;

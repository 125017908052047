import { Input, Modal, notification } from 'antd';
import React from 'react';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import * as Style from '../styles';
import bank from '../../../services/bank_api';

const { TextArea } = Input;

interface IModalProps {
  user: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setReload: (visible: number) => void;
  reload: number;
  liberation?: boolean;
}
const JustificationModal: React.FC<IModalProps> = ({
  user,
  visible,
  setVisible,
  setReload,
  reload,
  liberation,
}) => {
  const handleOk = (): any => {
    setValue('');
    setVisible(false);
    return true;
  };

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [value, setValue] = React.useState('');
  const handleCancel = React.useCallback(() => {
    setVisible(false);
    setValue('');
    return true;
  }, []);

  const handleUnderstood = (): any => {
    setVisible(false);
    setValue('');
    setReload(reload + 1);
  };

  const onChange = React.useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (value) {
      setLoading(true);
      try {
        await bank.put(
          `/admin/user-accountpf/active/${user}?status=${liberation}&&info=${value}`,
          {},
          {
            headers: {
              admintoken: localStorage.getItem('@BaasQesh:token'),
            },
          },
        );
        setSuccess(true);
        setLoading(false);
        notification.success({
          message: 'Sucesso!',
        });
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        notification.error({
          message: 'Houve um erro!',
        });
      }
      return;
    }
    notification.error({
      message: 'Para proseguir preencha o campo de justificativa',
    });
  }, [user, value]);
  return (
    <Modal
      title="Detalhamento do Motivo"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      {!success ? (
        <>
          <TextArea
            onChange={onChange}
            rows={6}
            value={value}
            placeholder={
              liberation
                ? 'Escreva aqui o motivo que justifique a liberação do usuário'
                : 'Escreva aqui o motivo que justifique a não liberação do usuário'
            }
            maxLength={400}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Style.UnlockButton
              style={{ background: liberation ? '#24a148' : '#da1e28' }}
              onClick={() => handleSubmit()}
            >
              {loading ? <LoadingOutlined spin /> : 'confirmar'}
            </Style.UnlockButton>
          </div>
        </>
      ) : (
        <>
          <Style.SuccessModal>
            <CheckOutlined
              style={{
                color: '#24a148',
                fontSize: '10rem',
              }}
            />
            <span>
              {liberation
                ? 'Conta aprovada com sucesso!'
                : 'Conta rejeitada com sucesso!'}
            </span>
          </Style.SuccessModal>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Style.UnlockButton onClick={() => handleUnderstood()}>
              Entendido
            </Style.UnlockButton>
          </div>
        </>
      )}
    </Modal>
  );
};

export default JustificationModal;

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Button, Table } from 'antd';

import { format } from 'date-fns';

import {
  SearchOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { FiCheck, FiClock, FiX } from 'react-icons/fi';
import {
  ApprovalButton,
  Container,
  ICol,
  Pagination,
  Tab,
  TabsContainer,
} from './styles';

import { RowGutter, Col, RawInput, SelectInput } from '../../styles/components';

import api from '../../services/api';

import { documentMask, HandleFormatMoney } from '../../services/utils';
import AcceptRequestModal from './AcceptRequestModal';

const { Column } = Table;

interface ILimitChangeRequest {
  id: string;
  requested_limit: number;
  last_limit: number;
  status: 'IN_ANALYSIS' | 'ACCEPTED' | 'REJECTED';
  justification: string;
  expiration_date: string;
  filename: string;
  limit_authorization_terms_filename: string;
  created_at: string;
  user_name: string;
  document: string;
  bank_name: string;
}

interface ILimitRequest {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
  items: ILimitChangeRequest[];
}

interface IFormattedLimitRequest {
  id: string;
  name: string;
  cpf: string;
  currentLimit: string;
  requestedLimit: string;
  bank: string;
  request_date: string;
  approval?: JSX.Element;
  request_status?: JSX.Element;
  justification: string;
  balance: string;
  document: JSX.Element;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

const LimitApproval: React.FC = () => {
  const [inputData, setInputData] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [filter, setFilter] = useState('cpf');
  const [limitRequests, setLimitRequests] = useState<ILimitChangeRequest[]>(
    [] as ILimitChangeRequest[],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [rowActive, setRowActive] = useState('');
  const [activeRequest, setActiveRequest] = useState(
    [] as IFormattedLimitRequest[],
  );
  const [loadingActiveRequest, setLoadingActiveRequest] = useState(false);

  const [currentTab, setCurrentTab] = useState<
    'IN_ANALYSIS' | 'ACCEPTED' | 'REJECTED'
  >('IN_ANALYSIS');

  const [
    isAccepetRequestModalOpened,
    setIsAccepetRequestModalOpened,
  ] = useState(false);

  const [requestToApprove, setRequestToApprove] = useState<ILimitChangeRequest>(
    {} as ILimitChangeRequest,
  );

  const getData = useCallback(() => {
    const user = localStorage.getItem('@BaasQesh:user');

    const showExpired = currentTab !== 'IN_ANALYSIS';

    api
      .get<ILimitRequest>(
        `/limit-requests/?status=${currentTab}&showExpired=${showExpired}&page=${currentPage}`,
        {
          headers: { user },
        },
      )
      .then(response => {
        setLimitRequests(response.data.items);
        setLoading(false);
      });
  }, [currentPage, currentTab]);

  useEffect(() => {
    setLoading(true);
    const user = localStorage.getItem('@BaasQesh:user');

    const showExpired = currentTab !== 'IN_ANALYSIS';

    api
      .get<ILimitRequest>(
        `/limit-requests/?status=${currentTab}&showExpired=${showExpired}&page=${currentPage}`,
        {
          headers: { user },
        },
      )
      .then(response => {
        setLimitRequests(response.data.items);
        setLoading(false);

        setMetaData({
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
          total_items: response.data.total_items,
          total_items_page: response.data.total_items_page,
        });
      });
  }, [currentPage, currentTab]);

  const handleApprove = useCallback((request: ILimitChangeRequest) => {
    setIsAccepetRequestModalOpened(true);
    setRequestToApprove(request);
  }, []);

  const handleReprove = useCallback(
    (request: ILimitChangeRequest) => {
      api
        .patch(`limit-requests/${request.id}`, {
          status: 'REJECTED',
          value: request.last_limit,
        })
        .then(() => {
          getData();
        });
    },
    [getData],
  );

  const formattedLimitRequests = useMemo(
    () =>
      limitRequests.map(request => {
        const balance = request.requested_limit - request.last_limit;

        if (currentTab === 'IN_ANALYSIS') {
          return {
            id: request.id,
            name: request.user_name,
            cpf: request.document,
            currentLimit: HandleFormatMoney(Number(request.last_limit)),
            requestedLimit: HandleFormatMoney(Number(request.requested_limit)),
            bank: request.bank_name,
            request_date: format(
              new Date(String(request.created_at)),
              'dd/MM/yyyy',
            ),
            approval: (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ApprovalButton
                  onClick={() => handleApprove(request)}
                  color="#009a05"
                >
                  <FiCheck size={20} />
                </ApprovalButton>
                <ApprovalButton
                  onClick={() => handleReprove(request)}
                  color="#ce2828"
                >
                  <FiX size={20} />
                </ApprovalButton>
              </div>
            ),
            justification: request.justification,
            balance: HandleFormatMoney(balance),
            document: (
              <a
                rel="noreferrer"
                target="_blank"
                href={`http://qesh-storage.s3.amazonaws.com/documents/${request.id}/${request.filename}`}
              >
                Visualizar arquivo
              </a>
            ),
            terms_document: (
              <a
                rel="noreferrer"
                target="_blank"
                href={`http://qesh-storage.s3.amazonaws.com/documents/${request.id}/${request.limit_authorization_terms_filename}`}
              >
                Visualizar arquivo de termos
              </a>
            ),
          };
        }

        return {
          id: request.id,
          name: request.user_name,
          cpf: request.document,
          currentLimit: HandleFormatMoney(Number(request.last_limit)),
          requestedLimit: HandleFormatMoney(Number(request.requested_limit)),
          bank: request.bank_name,
          request_date: format(
            new Date(String(request.created_at)),
            'dd/MM/yyyy',
          ),
          request_status:
            currentTab === 'ACCEPTED' ? (
              <span style={{ color: '#75c877' }}>Aprovado</span>
            ) : (
              <span style={{ color: '#da5f5f' }}>Recusado</span>
            ),
          justification: request.justification,
          balance: HandleFormatMoney(balance),
          document: (
            <a
              rel="noreferrer"
              target="_blank"
              href={`http://qesh-storage.s3.amazonaws.com/documents/${request.id}/${request.filename}`}
            >
              Visualizar arquivo
            </a>
          ),
          terms_document: (
            <a
              rel="noreferrer"
              target="_blank"
              href={`http://qesh-storage.s3.amazonaws.com/documents/${request.id}/${request.limit_authorization_terms_filename}`}
            >
              Visualizar arquivo de termos
            </a>
          ),
        };
      }),
    [currentTab, handleApprove, handleReprove, limitRequests],
  );

  const loadRequestInfo = useCallback(
    async (id: string) => {
      setRowActive(id);
      setActiveRequest([] as IFormattedLimitRequest[]);
      setLoadingActiveRequest(true);

      const data = formattedLimitRequests.find(request => request.id === id);

      if (!data) {
        setLoadingActiveRequest(false);
        setRowActive('');

        return;
      }

      setActiveRequest([data]);
      setLoadingActiveRequest(false);
    },
    [formattedLimitRequests],
  );

  const handleFilter = useCallback(() => {
    const user = localStorage.getItem('@BaasQesh:user');
    const showExpired = currentTab !== 'IN_ANALYSIS';
    setLoading(true);

    api
      .get<ILimitRequest>(
        `/limit-requests/?status=${currentTab}&showExpired=${showExpired}&page=${currentPage}&${filter}=${inputData}`,
        {
          headers: { user },
        },
      )
      .then(response => {
        setLimitRequests(response.data.items);
        setLoading(false);

        setMetaData({
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
          total_items: response.data.total_items,
          total_items_page: response.data.total_items_page,
        });
      });
  }, [currentPage, currentTab, filter, inputData]);

  return (
    <Container>
      <AcceptRequestModal
        hide={() => {
          setIsAccepetRequestModalOpened(false);
          getData();
        }}
        visible={!!isAccepetRequestModalOpened}
        id={requestToApprove.id}
        current_limit={requestToApprove.last_limit}
        requested_limit={requestToApprove.requested_limit}
      />
      <RowGutter>
        <Col>
          <h1>Aprovação de limite</h1>
          <h2>Utilize o filtro para pesquisar</h2>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 6 }}
          md={{ span: 3 }}
          lg={{ span: 3 }}
          style={{ marginBottom: '5px' }}
        >
          <SelectInput
            defaultValue="CPF/CNPJ"
            onChange={e => setFilter(String(e))}
          >
            <SelectInput.Option value="cpf">CPF/CNPJ</SelectInput.Option>
            <SelectInput.Option value="name">NOME</SelectInput.Option>
            <SelectInput.Option value="account">CONTA</SelectInput.Option>
          </SelectInput>
        </Col>

        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          style={{ marginBottom: '5px' }}
        >
          <RawInput
            placeholder="Buscar"
            value={inputData}
            onChange={e => setInputData(e.target.value)}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Button
            onClick={handleFilter}
            style={{ height: '44px', width: '44px' }}
            type="dashed"
            shape="circle"
            icon={<SearchOutlined />}
          />
        </Col>
      </RowGutter>

      <RowGutter
        style={{
          marginTop: '15px',
          marginBottom: '15px',
          marginLeft: '0px',
          marginRight: '0px',
        }}
      >
        <TabsContainer>
          <Tab
            onClick={() => {
              setCurrentTab('IN_ANALYSIS');
              setInputData('');
              setActiveRequest([] as IFormattedLimitRequest[]);
              setRowActive('');
            }}
            selected={currentTab === 'IN_ANALYSIS'}
          >
            <FiClock />
            <span>Em aberto</span>
          </Tab>
          <Tab
            onClick={() => {
              setCurrentTab('ACCEPTED');
              setInputData('');
              setActiveRequest([] as IFormattedLimitRequest[]);
              setRowActive('');
            }}
            selected={currentTab === 'ACCEPTED'}
          >
            <span>Aprovadas</span>
          </Tab>
          <Tab
            onClick={() => {
              setCurrentTab('REJECTED');
              setInputData('');
              setActiveRequest([] as IFormattedLimitRequest[]);
              setRowActive('');
            }}
            selected={currentTab === 'REJECTED'}
          >
            <span>Recusadas</span>
          </Tab>
        </TabsContainer>
      </RowGutter>

      <ICol>
        <Table
          dataSource={formattedLimitRequests}
          pagination={false}
          rowKey="id"
          loading={loading}
          locale={{
            emptyText: 'Nenhuma solicitação para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={7}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: () => (
              <Table
                dataSource={activeRequest}
                pagination={false}
                rowKey="id"
                loading={loadingActiveRequest}
              >
                <Column
                  title="Justificativa"
                  dataIndex="justification"
                  key="justification"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Diferença"
                  dataIndex="balance"
                  key="balance"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Documento anexado"
                  dataIndex="document"
                  key="document"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Termo de autorização"
                  dataIndex="terms_document"
                  key="terms_document"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
              </Table>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleOutlined
                  style={{
                    fontSize: 26,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    onExpand(record, e);
                  }}
                />
              ) : (
                <DownCircleOutlined
                  style={{
                    fontSize: 26,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadRequestInfo(record.id);
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="CPF"
            dataIndex="cpf"
            key="cpf"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => documentMask(text)}
          />
          <Column
            title="Limite atual"
            dataIndex="currentLimit"
            key="currentLimit"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Limite solicitado"
            dataIndex="requestedLimit"
            key="requestedLimit"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Banco"
            dataIndex="bank"
            key="bank"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Data da solicitação"
            dataIndex="request_date"
            key="request_date"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          {currentTab === 'IN_ANALYSIS' ? (
            <Column
              title="Aprovação"
              dataIndex="approval"
              key="approval"
              align="center"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
          ) : (
            <Column
              title="Status"
              dataIndex="request_status"
              key="request_status"
              align="center"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
          )}
        </Table>
      </ICol>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              getData();
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                getData();
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </Container>
  );
};

export default LimitApproval;

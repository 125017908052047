import styled from 'styled-components';
import { Row as RolAntd, Col as ColAntd, Upload } from 'antd';

export const Row = styled(RolAntd)`
  @media (max-width: 1020px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Col = styled(ColAntd)`
  @media (max-width: 1020px) {
    margin-top: 30px;
  }

  &.bottom {
    margin-bottom: 35px;

    @media (max-width: 1020px) {
      margin-bottom: 0;
    }
  }

  &.bottomLg {
    margin-bottom: 60px;
    @media (max-width: 1020px) {
      margin-bottom: 0;
    }
  }
`;

export const IUpload = styled(Upload)`
  width: 100%;
  display: flex;
  justify-content: center;

  span > img {
    max-height: 150px;
  }
`;

export const Header = styled.div`
  background: #42c1c7;
  color: #f9f9f9;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const StepButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  padding-bottom: 18px;

  button {
    min-width: 60px;
    width: 12%;

    & + button {
      margin-left: 18px;
    }
  }
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  border-top: 1px solid #f0f0f0;

  justify-content: center;
  align-items: center;

  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

import * as Antd from 'antd';
import React from 'react';

interface IModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
}
const ImageModal: React.FC<IModalProps> = ({ visible, setVisible, url }) => {
  return (
    <Antd.Modal
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={false}
    >
      <div style={{ paddingTop: '10%' }}>
        <img height={500} width={350} src={url} alt="error" />
      </div>
    </Antd.Modal>
  );
};

export default ImageModal;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';

import { Button, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { RawInput, PasswordInput } from '../../styles/components';
import { Container, Content, Box } from './styles';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';

const SignIn: React.FC = () => {
  const { signIn } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);

      try {
        await signIn({
          email,
          password,
        });

        setLoading(false);
      } catch (err) {
        notification.error({
          message: 'Não foi possível fazer o Login.',
        });
        setLoading(false);
      }
    },
    [email, password, signIn],
  );

  return (
    <Container>
      <Content>
        <img
          src={`${settings?.default_logo}`}
          alt={`${settings?.display_name || 'logo'}`}
        />
        <Box>
          <h1>Entre na sua conta</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">E-mail</label>
              <RawInput
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="password">Senha</label>
              <PasswordInput
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            <div style={{ textAlign: 'center' }}>
              {loading ? (
                <>
                  <div
                    style={{
                      margin: '0 auto',
                      marginTop: '10px',
                    }}
                  >
                    <LoadingOutlined
                      style={{
                        fontSize: 21,
                      }}
                    />
                  </div>
                </>
              ) : (
                <Button htmlType="submit" block size="large" type="primary">
                  ENTRAR
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Content>
    </Container>
  );
};

export default SignIn;

/* eslint-disable dot-notation */
import React, { useState, useCallback } from 'react';
import { Button, notification, Form, Input, Upload, Row, Col } from 'antd';

import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { FaSearch, FaFilePdf } from 'react-icons/fa';

import { format, parseISO } from 'date-fns';
import Axios from 'axios';
import { Modal } from './styled';

import { RowGutter, RawInput, SelectInput } from '../../../styles/components';

import api from '../../../services/api';
import { documentMask } from '../../../services/utils';
import { useAuth } from '../../../hooks/auth';

const { TextArea } = Input;

interface IAccountData {
  data: {
    _id: string;
    nome?: string;
    dataNascimento?: Date;
    empresa: {
      abertura?: Date;
      razaoSocial?: string;
    };
  };
  id: string;
  type: string;
}
interface IModalProps {
  visible: boolean;
  hide: () => void;
}

const AddDocumentBlackListModal: React.FC<IModalProps> = ({
  visible,
  hide,
}) => {
  const [inputData, setInputData] = useState('');
  const [inputArea, setInputArea] = useState('');
  const [accountData, setAccountData] = useState({} as IAccountData);
  const [FileList, setFileList] = useState([] as any);

  const [enablePreview, setEnablePreview] = useState(true);
  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [currentFile, setCurrentFile] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const { admin } = useAuth();

  const loadData = useCallback(async () => {
    if (inputData === '' || inputData === null) {
      notification.error({ message: 'Por Favor, insira o documento' });
      return;
    }
    setLoadingDocument(true);
    try {
      const { data } = await api.get(
        `/id/admin/${inputData.replace(/[.]|[/]|[-]/gi, '')}`,
      );

      if (data?.data?.anoFalecimento) {
        notification.error({
          message: 'O usuario do CPF encontra-se falecido.',
          description:
            'Pela adversidade não iremos dar continuidade ao registrado',
        });
        setLoadingDocument(false);
        return;
      }

      if (!data) {
        const { data: data2 } = await api.get(
          `/id/big-data/admin/${inputData.replace(/[.]|[/]|[-]/gi, '')}`,
        );

        if (data2.data.isMinor) {
          notification.error({
            message: 'CPF com a idade mínima para registra-lo ',
            description:
              'Por questão de segurança apenas pessoas maiores de 18 anos poder ser registrados',
          });
          setLoadingDocument(false);
          return;
        }
      }
      if (!data || data === null || data === undefined || data === '') {
        notification.error({
          message: 'Nenhum Documento encontrado',
        });
        setLoadingDocument(false);
        return;
      }
      setAccountData(data);
      setLoadingDocument(false);
    } catch (err: any) {
      notification.error({ message: 'Documento invalido ou não existe' });
      console.log(err);
      setLoadingDocument(false);
    }
  }, [inputData]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      loadData();
    },
    [loadData],
  );

  const returnData = useCallback(() => {
    let resp = 'Data de Nascimento';
    if (accountData.id) {
      resp =
        accountData?.data['_id']?.length === 11
          ? format(
              parseISO(String(accountData?.data?.dataNascimento)),
              'dd/MM/yyyy',
            )
          : format(
              parseISO(String(accountData?.data?.empresa?.abertura)),
              'dd/MM/yyyy',
            );
    }
    return resp;
  }, [accountData]);

  const returnName = useCallback(() => {
    let resp = 'Nome';
    if (accountData.id) {
      resp =
        accountData?.data['_id']?.length === 11
          ? String(accountData?.data?.nome)
          : String(accountData?.data?.empresa?.razaoSocial);
    }
    return resp;
  }, [accountData]);

  const beforeUpload = useCallback(
    file => {
      setEnablePreview(true);
      if (file.size > 5000000) {
        setCurrentFile('');
        FileList.pop();
        notification.warning({
          message: `O arquivo passa do tamanha de 5MB e não podera ser enviado`,
        });
      }
      setFileList([...FileList, file]);
      return false;
    },
    [FileList],
  );

  const handleAddBlacklist = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();

    if (inputData === '' || inputData === null) {
      notification.error({ message: 'Favor Busque o documento.' });
      setLoading(false);
      return;
    }
    if (!accountData.data) {
      notification.error({ message: 'Favor encontre o documento.' });
      setLoading(false);
      return;
    }
    if (accountData.data['_id'] !== inputData.replace(/[.]|[-]/g, '')) {
      notification.error({
        message: 'O Documento Buscado difere do qual foi buscado.',
        description: 'Favor encontre o documento.',
      });
      setLoading(false);
      return;
    }
    if (inputArea === '' || inputArea === null) {
      notification.error({ message: 'Favor preencher o motivo.' });
      setLoading(false);
      return;
    }

    FileList.forEach((file: any, index: number) => {
      formData.append('files', file.originFileObj);
    });
    formData.append(
      'document_type',
      accountData.data['_id'].length <= 11 ? 'CPF' : 'CNPJ',
    );
    formData.append(
      'name',
      accountData?.data['_id']?.length === 11
        ? String(accountData?.data?.nome ? accountData?.data?.nome : '')
        : String(
            accountData?.data?.empresa?.razaoSocial
              ? accountData?.data?.empresa?.razaoSocial
              : '',
          ),
    );

    formData.append('comment', inputArea);

    try {
      const data = await api.put(
        `/admin/blacklist/?doc=${accountData.data['_id']}&&client_id=${admin.bank.id}`,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
        },
      );
      notification.success({
        message: `O documento ${documentMask(
          accountData.data['_id'],
        )} foi adicionado na Blaklist com sucesso`,
      });

      setLoading(false);
      setAccountData({} as IAccountData);
      setInputArea('');
      setInputData('');
      setFileList([]);
      hide();
    } catch (error: any) {
      console.log(error);
      setLoading(false);

      if (
        error?.response?.data?.message?.defaultMessage === 'FOUND' ||
        error?.response?.data?.message?.message?.blacklist
      ) {
        notification.error({
          message: `O documento ${documentMask(
            accountData.data['_id'],
          )} ja esta cadastrado na Blaklist`,
        });
        return;
      }
      notification.error({
        message: `Houve um erro na tentativa de adicionar o documento ${documentMask(
          accountData.data['_id'],
        )} na Blaklist`,
      });
    }
  }, [accountData, inputArea, inputData, FileList]);

  return (
    <Modal
      wrapClassName="AddDocumentBlackListModal"
      centered
      visible={visible}
      onOk={() => {
        setAccountData({} as IAccountData);
        setFileList([]);
        setInputArea('');
        setInputData('');
        hide();
      }}
      closable={false}
      onCancel={() => {
        setAccountData({} as IAccountData);
        setInputArea('');
        setFileList([]);
        setInputData('');
        hide();
      }}
      footer={false}
      destroyOnClose
      bodyStyle={{
        padding: 20,
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <div>
        <CloseOutlined
          onClick={() => {
            setAccountData({} as IAccountData);
            setInputArea('');
            setInputData('');
            setFileList([]);
            hide();
          }}
          style={{
            display: 'flex',
            justifyContent: 'right',
            color: 'red',
          }}
          size={30}
        />
        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <h2>Utilize o filtro para pesquisar</h2>
            </Row>
            <Row>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                md={{ span: 5 }}
                lg={{ span: 5 }}
                style={{ marginBottom: '5px' }}
              >
                <SelectInput defaultValue="CPF/CNPJ">
                  <SelectInput.Option value="u.document">
                    CPF/CNPJ
                  </SelectInput.Option>
                </SelectInput>
              </Col>

              <Col
                xs={{ span: 7 }}
                sm={{ span: 7 }}
                md={{ span: 7 }}
                lg={{ span: 7 }}
                style={{ marginBottom: '5px', marginLeft: '5px' }}
              >
                <RawInput
                  placeholder="Buscar"
                  value={documentMask(inputData).substring(0, 18)}
                  onChange={e => {
                    if (e.target.value.length >= 18) {
                      e.target.value.split('').pop();
                    } else {
                      setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                    }
                  }}
                />
              </Col>
              <Col
                xs={{ span: 4 }}
                sm={{ span: 4 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
                style={{ marginLeft: '5px' }}
              >
                <Button
                  htmlType="submit"
                  style={{ height: '44px', width: '44px' }}
                  type="dashed"
                  shape="circle"
                  disabled={loadingDocument}
                  icon={loadingDocument ? <LoadingOutlined /> : <FaSearch />}
                />
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                style={{ marginLeft: '5px', marginTop: '5px' }}
              >
                <Button
                  htmlType="button"
                  type="dashed"
                  onClick={() => {
                    setInputData('');
                    setAccountData({} as IAccountData);
                  }}
                >
                  Limpar Pesquisa
                </Button>
              </Col>
            </Row>
          </form>
          <div
            style={{
              alignContent: 'center',
              paddingLeft: 40,
              paddingTop: 15,
              paddingRight: 40,
              background: '#F4F4F4',
              borderRadius: 10,
              marginTop: 10,
              marginBottom: 10,
              height: 'auto',
            }}
          >
            <Form>
              <RowGutter style={{ justifyContent: 'space-between' }}>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h5>{returnName()}</h5>
                </Col>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h5>
                    {accountData?.data
                      ? documentMask(accountData?.data['_id'])
                      : 'Documento'}
                  </h5>
                </Col>
                <Col
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h5>{returnData()}</h5>
                </Col>
              </RowGutter>
            </Form>
          </div>
          <h2
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderColor: '#42C1C7',
            }}
          >
            Detalhamento do motivo
          </h2>
          <TextArea
            rows={6}
            style={{ background: '#F4F4F4', resize: 'none' }}
            placeholder="Escreva aqui o motivo que justifique a liberação do usuário"
            value={inputArea.substring(0, 102285)}
            onChange={(e: any) => {
              setInputArea(e.target.value);
              if (inputArea.length >= 102287) {
                notification.error({
                  message: 'Limite de caracteres atingido',
                });
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList
                multiple
                beforeUpload={beforeUpload}
                accept=".pdf, image/png, image/jpeg"
                fileList={FileList}
                onChange={async (info: any) => {
                  setFileList(info.fileList);
                  if (info.fileList[info.fileList.length - 1]?.size > 5000000) {
                    await info.fileList.pop();
                  }
                }}
              >
                {currentFile ? (
                  <>
                    {enablePreview ? (
                      <>
                        <img
                          src={currentFile || ''}
                          alt="file"
                          style={{
                            width: '100%',
                            marginTop: '60px',
                            marginBottom: '60px',
                          }}
                        />
                      </>
                    ) : (
                      <FaFilePdf size={125} />
                    )}
                  </>
                ) : (
                  <div>
                    <div style={{ marginBottom: '5px' }}>
                      Clique para fazer upload
                    </div>
                    {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                  </div>
                )}
              </Upload>
            </div>
            <Row style={{ display: 'flex', flexWrap: 'nowrap', marginTop: 40 }}>
              <Col>
                <LoadingOutlined
                  width={50}
                  height={50}
                  style={{
                    opacity: `${loading ? 1 : 0}`,
                    marginRight: 10,
                    marginTop: 9,
                  }}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    handleAddBlacklist();
                  }}
                  type="primary"
                  style={{
                    borderRadius: 6,
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    borderWidth: 0,
                  }}
                  disabled={loading}
                >
                  <span>Adicionar na blacklist</span>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddDocumentBlackListModal;

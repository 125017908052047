import styled from 'styled-components';
import { Col } from '../../styles/components';

interface IApprovalButtonProps {
  color: string;
}

export const Container = styled.div``;

export const Pagination = styled.div`
  margin: 0 auto;
  display: flex;
  width: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  span {
    cursor: pointer;
  }

  span:nth-child(2) {
    padding-left: 15px;

    &::before {
      content: '|';
      padding-right: 15px;
    }
  }
`;

export const ApprovalButton = styled.div<IApprovalButtonProps>`
  border: 2px solid;
  border-color: ${props => props.color};
  color: ${props => props.color};

  margin-left: 4px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background-color: ${props => props.color};
    color: #fff;
  }
`;

export const ICol = styled(Col)`
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > td
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0;
  }
`;

export const TabsContainer = styled.div`
  width: 22%;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  div {
    & + div {
      margin-left: 16px;
    }
  }
`;

interface ITabProps {
  selected: boolean;
}

export const Tab = styled.div<ITabProps>`
  cursor: pointer;
  padding-right: 6px;
  border-bottom: ${props => (props.selected ? '2px solid #5eb0fc' : '0px')};
  color: ${props => (props.selected ? '#5eb0fc' : '#767676')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;

  svg {
    margin-right: 4px;
  }

  :hover {
    border-bottom: ${props =>
      props.selected ? '2px solid #5eb0fc' : '2px solid  #dbdcde'};
  }
`;

import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, message as messageAntd } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { ContainerButton } from './styles';

const { TextArea } = Input;

interface IModalArguments {
  isVisible: boolean;
  color: string;
  textAreaPlaceholder: string;
  setIsvisible: React.Dispatch<React.SetStateAction<boolean>>;
  buttonText: string;
  cancelReason: string;
  setCancelReason: React.Dispatch<React.SetStateAction<string>>;
  handleCancelAccount: any;
}

const ModalCancelAccount: React.FC<IModalArguments> = ({
  isVisible,
  setIsvisible,
  color,
  textAreaPlaceholder,
  buttonText,
  cancelReason,
  setCancelReason,
  handleCancelAccount,
}) => {
  const [CloseConfirmation, setCloseConfirmation] = useState(false);

  const handleClose = (): void => {
    setCloseConfirmation(true);
    setIsvisible(false);
  };
  const handleClick = (): void => {
    handleCancelAccount();
    setIsvisible(false);
    setCloseConfirmation(false);
  };
  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={() => {
          setCancelReason('');
          setIsvisible(false);
        }}
        title=""
        footer={false}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>Cancelar conta</h2>
            <span>Você deseja cancelar essa conta?</span>
            <span style={{ marginTop: '5%' }}>A conta será cancelada</span>
          </div>
          <Button
            onClick={() => handleClose()}
            style={{
              margin: '10% 5% 0 5%',
              height: '2.5rem',
              color: 'white',
              background: color,
              border: 'none',
              borderRadius: '0.3rem',
              boxShadow: '5px 6px 13px -8px #000000',
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Modal>
      <Modal
        onCancel={() => {
          setCancelReason('');
          setCloseConfirmation(false);
        }}
        title="Detalhamento do motivo"
        visible={CloseConfirmation}
        footer={false}
      >
        <TextArea
          maxLength={600}
          autoSize={{ minRows: 8, maxRows: 8 }}
          placeholder={textAreaPlaceholder}
          bordered={false}
          value={cancelReason}
          onChange={e => setCancelReason(e.target.value)}
          style={{ background: '#F4F4F4', display: 'flex' }}
        />

        <ContainerButton>
          <Button
            onClick={
              cancelReason !== ''
                ? () => handleClick()
                : () =>
                    messageAntd.error(
                      'Por favor preencha o campo de justificativa para continuar sua ação.',
                      3,
                    )
            }
            style={{
              color: 'white',
              background: color,
              border: 'none',
              borderRadius: '0.3rem',
              boxShadow: '5px 6px 13px -8px #000000',
            }}
          >
            {buttonText}
          </Button>
        </ContainerButton>
      </Modal>
    </>
  );
};
export default ModalCancelAccount;

import React, { useState, useCallback, useEffect } from 'react';
import { Button, Table, Tag, Col, Row, notification } from 'antd';

import { format, parseISO } from 'date-fns';

import {
  SearchOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { FaUnlock } from 'react-icons/fa';
import ImageModal from './Modal/ImageModal';

import { Container, UnlockButton, DontAceptButton, Pagination } from './styles';

import { RawInput, SelectInput } from '../../styles/components';

import api from '../../services/api';

import { documentMask } from '../../services/utils';
import JustificationModal from './Modal/JustificationModal';

const { Column } = Table;

const AprovePfAccount: React.FC = () => {
  const [displayedDoc, setDisplayedDoc] = useState<any>({});
  const [reload, setReload] = useState(0);
  const [reloadPage, setReloadPage] = useState(false);

  const [filter, setFilter] = useState('u.document');

  const [rowActive, setRowActive] = useState('');
  const [filterData, setFilterData] = useState('');
  const [filterValue, setFiterValue] = useState(filterData);
  const [imageModal, setImageModal] = useState(false);
  const [justificationModal, setJustificationModal] = useState(false);
  const [justificationDenyModal, setJustificationDenyModal] = useState(false);
  const [selectedImage, setClickedImage] = useState('');

  const [metaData, setMetaData] = useState({} as any);
  const [accounts, setAccounts] = useState([] as Array<any>);
  const [currentPage, setCurrentPage] = useState(0);
  const [inputData, setInputData] = useState('');

  const [loading, setLoading] = useState(false);

  const [typeDoc, setTypeDoc] = useState('');

  interface IDefault {
    [key: string]: string;
  }

  const categoryEnum: IDefault = {
    IDENTITY_CARD_VERSE: 'Rg antigo - verso',
    IDENTITY_CARD_FRONT: 'Rg antigo - frente',
    IDENTITY_CARD_VERIFY: 'Selfie do documento',
    SELFIE: 'Selfie',
  };

  const loaddata = useCallback(async (page = 0, filters = '') => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/admin/accounts/client_approval_pending?page=${page}${filters}`,
        {},
      );

      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });

      setAccounts(
        data.items.map((item: any) => {
          return { ...item, key: item.user_id };
        }),
      );

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (reloadPage) {
      loaddata(currentPage);
      setReloadPage(false);
      setRowActive('');
    } else if (typeDoc !== '') {
      prepareLoadData();
    } else {
      loaddata();
    }
  }, [loaddata, reloadPage, typeDoc]);

  const prepareLoadData = useCallback(
    (page = 0) => {
      let finput = '';
      let ftype = '';
      if (
        inputData === '' ||
        inputData === undefined ||
        inputData === null ||
        !inputData
      ) {
        finput = '';
      } else if (filter === 'a.client_name') {
        finput = `&&filter=c.name&&value=${inputData}`;
      } else {
        finput = `${
          filter === 'u.document'
            ? `&&filter=u.document&&value=${inputData.replace(/[/,.,-]/g, '')}`
            : `&&filter=u.name&&value=${inputData}`
        }`;
      }
      if (
        typeDoc === '' ||
        typeDoc === undefined ||
        typeDoc === null ||
        !typeDoc
      ) {
        ftype = '';
      }
      const ff = finput + ftype;
      if (ff === '' || ff === undefined || ff === null || !ff) {
        loaddata(page || 0);
      } else {
        loaddata(page || 0, ff);
      }
    },
    [filter, inputData, loaddata, typeDoc],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      prepareLoadData();
    },
    [prepareLoadData],
  );

  const loadDocs = useCallback(
    async ({ user_id, document, client_name }) => {
      setLoading(true);

      try {
        const { data } = await api.get(`/admin/accounts/info/${document}`, {
          headers: {
            user: user_id,
            client: client_name,
          },
        });

        setRowActive(data.id);
        setDisplayedDoc(data);
        setLoading(false);
      } catch (err: any) {
        setRowActive('');
        setLoading(false);
        console.log(err);
        notification.error({
          message: 'Houve um erro na busca das fotos.',
        });
      }
    },
    [displayedDoc],
  );
  const openImageModal = (image: string): void => {
    setImageModal(true);
    setClickedImage(image);
  };

  return (
    <>
      {displayedDoc && (
        <>
          <JustificationModal
            user={displayedDoc.id}
            visible={justificationModal}
            setVisible={setJustificationModal}
            setReload={setReload}
            reload={reload}
            liberation
          />
          <JustificationModal
            user={displayedDoc.id}
            visible={justificationDenyModal}
            setVisible={setJustificationDenyModal}
            setReload={setReload}
            reload={reload}
            liberation={false}
          />
        </>
      )}
      <ImageModal
        visible={imageModal}
        setVisible={setImageModal}
        url={selectedImage}
      />
      <Container>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <h1>Análise conta PF</h1>
              <h2>Utilize o filtro para pesquisar</h2>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 3 }}
              lg={{ span: 3 }}
              style={{ marginBottom: '5px' }}
            >
              <SelectInput
                defaultValue="u.document"
                onChange={e => {
                  setFilter(String(e));
                  setFilterData('');
                  setInputData('');
                }}
              >
                <SelectInput.Option value="u.document">CPF</SelectInput.Option>
                <SelectInput.Option value="u.name">NOME</SelectInput.Option>
                <SelectInput.Option value="a.client_name">
                  BANCO
                </SelectInput.Option>
              </SelectInput>
            </Col>

            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              style={{ marginBottom: '5px', marginLeft: '5px' }}
            >
              <RawInput
                placeholder="Buscar"
                value={
                  filter === 'u.document' ? documentMask(inputData) : inputData
                }
                onChange={e => {
                  if (filter === 'u.document') {
                    setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                  } else {
                    setInputData(e.target.value);
                  }
                }}
              />
            </Col>
            <Col
              style={{ marginLeft: '5px' }}
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Button
                htmlType="submit"
                style={{ height: '44px', width: '44px' }}
                type="dashed"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={() => setFiterValue(filterData)}
              />
            </Col>
          </Row>
        </form>
        <Table
          dataSource={accounts}
          pagination={false}
          rowKey="user_id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={5}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: record => (
              <>
                <Row
                  style={{
                    justifyContent: 'space-around',
                    margin: '0 50px',
                  }}
                >
                  <Col>
                    <Row
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ fontWeight: 'bold' }}>
                        {categoryEnum[displayedDoc[0]?.categoria]}
                      </div>
                    </Row>
                    <Row
                      onClick={() => openImageModal(displayedDoc[0]?.link)}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        height={200}
                        width={140}
                        src={displayedDoc[0]?.link}
                        alt="falha ao carregar imagem"
                      />
                    </Row>
                  </Col>
                  <Col className="bar" />
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {categoryEnum[displayedDoc[1]?.categoria]}
                      </div>
                    </Row>
                    <Row
                      onClick={() => openImageModal(displayedDoc[1]?.link)}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        height={200}
                        width={140}
                        src={displayedDoc[1]?.link}
                        alt="falha ao carregar imagem"
                      />
                    </Row>
                  </Col>
                  <Col className="bar" />
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {categoryEnum[displayedDoc[2]?.categoria]}
                      </div>
                    </Row>

                    <Row
                      onClick={() => openImageModal(displayedDoc[2]?.link)}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        height={200}
                        width={140}
                        src={displayedDoc[2]?.link}
                        alt="falha ao carregar imagem"
                      />
                    </Row>
                  </Col>
                  <Col className="bar" />
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {categoryEnum[displayedDoc[3]?.categoria]}
                      </div>
                    </Row>

                    <Row
                      onClick={() => openImageModal(displayedDoc[3]?.link)}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        height={200}
                        width={140}
                        src={displayedDoc[3]?.link}
                        alt="imagem não encontrada"
                      />
                    </Row>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: '5px',
                  }}
                >
                  <DontAceptButton
                    onClick={() => setJustificationDenyModal(true)}
                  >
                    Não liberar
                  </DontAceptButton>
                  <UnlockButton onClick={() => setJustificationModal(true)}>
                    <FaUnlock />
                    Liberar
                  </UnlockButton>
                </Row>
              </>
            ),
            expandIcon: ({ expanded, onExpand, record }) => {
              return expanded ? (
                <UpCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    onExpand(record, e);
                  }}
                />
              ) : (
                <DownCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadDocs({
                      user_id: record.user_id,
                      document: record.document,
                      client_name: record.client_name,
                    });
                    onExpand(record, e);
                  }}
                />
              );
            },
          }}
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="CPF"
            dataIndex="document"
            key="document"
            width={160}
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => documentMask(text)}
          />
          <Column
            title="Banco"
            dataIndex="client_name"
            key="client_name"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Criado em"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
            }}
          />
        </Table>
        <Pagination>
          {currentPage > 0 && (
            <span
              onClick={() => {
                setCurrentPage(currentPage - 1);
                prepareLoadData(currentPage - 1);
              }}
            >
              <LeftOutlined /> Anterior
            </span>
          )}
          {metaData.total_items_page >= 20 &&
            metaData.current_page !== metaData.total_pages && (
              <span
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  prepareLoadData(currentPage + 1);
                }}
              >
                Próximo <RightOutlined />
              </span>
            )}
        </Pagination>

        {/* <ICol>{generateTable}</ICol> */}
      </Container>
    </>
  );
};
export default AprovePfAccount;

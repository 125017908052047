/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Button, Table, Tag, notification } from 'antd';

import { Link } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import {
  // LoadingOutlined,
  SearchOutlined,
  RightCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { Container, Pagination, ICol } from './styles';

import { RowGutter, Col, RawInput, SelectInput } from '../../styles/components';
import AccountsSheet from '../../components/AccountsSheet';

import api from '../../services/api';

import { documentMask } from '../../services/utils';

import { useAuth } from '../../hooks/auth';

const { Column, ColumnGroup } = Table;

interface User {
  account_id: string;
  bank_account: string;
  bank_branch: string;
  bank_code: string;
  blocked: boolean;
  client_name: string;
  pending_approval?: boolean;
  document: string;
  email: string;
  name: string;
  phone: string;
  status: number;
  social_name: string;
  user_id: string;
  account_active: boolean;
  created_at: string;
}

interface Account {
  id: string;
  bank_code: string;
  bank_branch: string;
  bank_account: string;
  is_pool: boolean;
  suspended_at: string | null;
  active: boolean;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

const Dashboard: React.FC = () => {
  const [accounts, setAccounts] = useState([] as Array<User>);
  const [activeAccounts, setActiveAccounts] = useState([] as Array<Account>);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [filter, setFilter] = useState('u.document');
  const [currentPage, setCurrentPage] = useState(0);
  const [inputData, setInputData] = useState('');
  const { signOut, admin } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingActiveAccounts, setLoadingActiveAccounts] = useState(false);

  const [rowActive, setRowActive] = useState('');

  const loadData = useCallback(
    async (page = 0, filters = '') => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/admin/accounts?page=${page}${filters}`,
        );
        setAccounts(
          data.items.map((item: User) => {
            return { ...item, key: item.user_id };
          }),
        );
        setMetaData({
          current_page: data.current_page,
          total_pages: data.total_pages,
          total_items: data.total_items,
          total_items_page: data.total_items_page,
        });
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar a lista de contas',
          });
        }
      }
    },
    [signOut],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const prepareLoadData = useCallback(
    (page = 0, state = '0') => {
      if (inputData === '' || inputData === undefined || inputData === null) {
        loadData(page || 0);
      } else {
        const input = String(
          inputData.replace(/[/,.,-]/g, ''),
        ).toLocaleUpperCase();
        if (state !== '0') {
          loadData(
            page || 0,
            `&filter=${filter}&value=${input}&&state=${state}`,
          );
        } else {
          loadData(page || 0, `&filter=${filter}&value=${input}`);
        }
      }
    },
    [filter, inputData, loadData],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      prepareLoadData();
    },
    [prepareLoadData],
  );

  const loadUserAccount = useCallback(async (user_id: string) => {
    setRowActive(user_id);
    setActiveAccounts([]);
    setLoadingActiveAccounts(true);
    try {
      const { data } = await api.get(`/admin/accounts/${user_id}`);
      setActiveAccounts(data);
      setLoadingActiveAccounts(false);
    } catch (err) {
      setLoadingActiveAccounts(false);
      setRowActive('');
    }
  }, []);

  const formattedActiveAccounts = useMemo(
    () =>
      activeAccounts.map(account => {
        return {
          active: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!account.active ? (
                <Tag
                  style={{ fontWeight: 700, marginRight: 0 }}
                  color="#f80850"
                >
                  Não
                </Tag>
              ) : account.suspended_at ? (
                <Tag
                  style={{ fontWeight: 700, marginRight: 0 }}
                  color="#f80850"
                >
                  Suspensa
                </Tag>
              ) : (
                <Tag
                  style={{ fontWeight: 700, marginRight: 0 }}
                  color="#87d068"
                >
                  Sim
                </Tag>
              )}
            </div>
          ),
          bank_account: `${account.bank_account} ${
            account.is_pool === true ? '' : ''
          }`,
          bank_branch: account.bank_branch,
          bank_code: account.bank_code,
          id: account.id,
        };
      }),
    [activeAccounts],
  );

  const handleValue: any = (type: string) => {
    switch (type) {
      case 'u.document': {
        return documentMask(inputData).substring(0, 18);
      }
      case 'u.name': {
        return inputData;
      }
      case 'u.social_name': {
        return inputData;
      }
      case 'a.bank_account': {
        return inputData.substring(0, 11);
      }
      case 'c.name': {
        return inputData;
      }
      default: {
        notification.error({
          message: 'Selecione um filtro para a busca',
        });
        return inputData;
      }
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <RowGutter>
          <Col>
            <h1>Contas</h1>
            <h2>Utilize o filtro para pesquisar</h2>
          </Col>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            style={{ marginBottom: '5px' }}
          >
            <SelectInput
              defaultValue="CPF/CNPJ"
              onChange={e => {
                setFilter(String(e));
                setInputData('');
              }}
            >
              <SelectInput.Option value="u.document">
                CPF/CNPJ
              </SelectInput.Option>
              <SelectInput.Option value="u.name">NOME</SelectInput.Option>
              <SelectInput.Option value="u.social_name">
                NOME SOCIAL
              </SelectInput.Option>
              <SelectInput.Option value="a.bank_account">
                CONTA
              </SelectInput.Option>
              <SelectInput.Option value="c.name">BANCO</SelectInput.Option>
            </SelectInput>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px' }}
          >
            <RawInput
              placeholder="Buscar"
              value={handleValue(filter)}
              onChange={e => {
                switch (filter) {
                  case 'u.document': {
                    setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                    break;
                  }
                  case 'u.name': {
                    setInputData(e.target.value);
                    break;
                  }
                  case 'u.social_name': {
                    setInputData(e.target.value);
                    break;
                  }
                  case 'a.bank_account': {
                    setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                    break;
                  }
                  case 'c.name': {
                    setInputData(e.target.value);
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{
              marginBottom: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SelectInput
              defaultValue="Status"
              style={{ maxWidth: '160px' }}
              onChange={e => {
                if (!inputData.length) {
                  loadData(0, `&&state=${e}`);
                } else {
                  prepareLoadData(0, e.toString());
                }
              }}
            >
              <SelectInput.Option value="3">ATIVO</SelectInput.Option>
              <SelectInput.Option value="1">INCOMPLETO</SelectInput.Option>
              <SelectInput.Option value="4">NEGADO</SelectInput.Option>
              <SelectInput.Option value="2">EM ANÁLISE</SelectInput.Option>
              <SelectInput.Option value="pending_approval">
                PENDENTE
              </SelectInput.Option>
              <SelectInput.Option value="blocked">BLOQUEADO</SelectInput.Option>
            </SelectInput>
            <Button
              htmlType="submit"
              style={{
                marginLeft: '14px',
              }}
            >
              Limpar filtro
            </Button>
          </Col>
        </RowGutter>
      </form>

      <RowGutter style={{ marginTop: '30px' }} />
      <AccountsSheet client={admin.bank} />
      <ICol>
        <Table
          dataSource={accounts}
          pagination={false}
          rowKey="user_id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={3}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: () => (
              <Table
                dataSource={formattedActiveAccounts}
                pagination={false}
                rowKey="account_id"
                loading={loadingActiveAccounts}
                locale={{
                  emptyText: (
                    <span style={{ color: 'grey' }}>
                      Nenhuma conta para mostrar,{' '}
                      <Link
                        to={`/users/${rowActive}`}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        clique aqui para ver informações desse usuário.
                      </Link>
                    </span>
                  ),
                }}
              >
                <Column
                  title="Banco"
                  dataIndex="bank_code"
                  key="bank_code"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Agência"
                  dataIndex="bank_branch"
                  key="bank_branch"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Conta"
                  dataIndex="bank_account"
                  key="bank_account"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Conta Ativa?"
                  dataIndex="active"
                  key="active"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Detalhes"
                  dataIndex="detail"
                  key="detail"
                  align="center"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={(text, record: Account) => {
                    return (
                      <>
                        <Link
                          to={`/accounts/${record.id}`}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <RightCircleOutlined
                            style={{
                              fontSize: 21,
                              color: '#333',
                            }}
                          />
                        </Link>
                      </>
                    );
                  }}
                />
              </Table>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    onExpand(record, e);
                  }}
                />
              ) : (
                <DownCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadUserAccount(record.user_id);
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        >
          <ColumnGroup title="Dados">
            <Column
              title="Nome"
              dataIndex="name"
              key="name"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
            {admin?.bank?.name === 'Diverse Bank' ? (
              <Column
                title="Nome social"
                dataIndex="social_name"
                key="name"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
            ) : (
              <></>
            )}
            <Column
              title="Documento"
              dataIndex="document"
              width={160}
              key="document"
              responsive={['xs', 'sm', 'md', 'xl']}
              render={text => documentMask(text)}
            />
            <Column
              title="Banco"
              dataIndex="client_name"
              key="client_name"
              align="center"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
          </ColumnGroup>
          <Column
            title="Criado em"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record: User) => {
              if (record.pending_approval) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="orange"
                    >
                      Pendente
                    </Tag>
                  </div>
                );
              }
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {record.blocked && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#f80850"
                    >
                      Bloqueado
                    </Tag>
                  )}

                  {!record.blocked && record.status === 1 && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#ffbb00"
                    >
                      Incompleto
                    </Tag>
                  )}

                  {!record.blocked && record.status === 2 && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#0985FA"
                    >
                      Em análise
                    </Tag>
                  )}

                  {!record.blocked && record.status === 3 && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#87d068"
                    >
                      Ativo
                    </Tag>
                  )}

                  {!record.blocked && record.status === 4 && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="#f80850"
                    >
                      Cadastro negado
                    </Tag>
                  )}
                </div>
              );
            }}
          />
        </Table>
      </ICol>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              prepareLoadData(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                prepareLoadData(currentPage + 1);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </Container>
  );
};

export default Dashboard;

/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Menu,
  Dropdown,
  DatePicker,
  notification,
  Table,
  Divider,
  Space,
} from 'antd';
import {
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { format, parseISO, startOfMonth } from 'date-fns';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { useMemo } from 'react';
import Search from 'antd/lib/input/Search';
import { PropsAccount, PropsUserAccount } from '../../Account';
import api from '../../../../../services/api';
import bankApi from '../../../../../services/bank_api';
import { HandleFormatMoney } from '../../../../../services/utils';
import { useAuth } from '../../../../../hooks/auth';
import 'moment/locale/pt-br';
import { ContainerContent } from '../../Account/styles';
import { Pagination } from '../../../styles';
import { SelectsContainer } from './styles';
import { RowGutter } from '../../../../../styles/components';

moment.updateLocale('pt-br', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
});

const { Column } = Table;

interface ExtractItem {
  amount: number;
  amount_iof: number;
  balance_available: number;
  credit: boolean;
  date: Date;
  description: string;
  id: string;
  type: string;
  type_id: string;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

const SearchPage: React.FC<{
  user: PropsUserAccount;
  account: PropsAccount;
  allowDownload?: boolean;
}> = ({ user: userParam, account, allowDownload = true }) => {
  const [transactionId, setTransactionId] = useState('');
  const [SearchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [extracts, setExtracts] = useState<ExtractItem[]>([]);
  const [extractPix, setExtractPix] = useState([] as any);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [reportLoading, setReportLoading] = useState(false);
  const [user] = useState(userParam);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedKey, setExpandedKey] = useState('');
  const { admin } = useAuth();

  const { signOut } = useAuth();

  useMemo(
    async (page = currentPage) => {
      setLoading(true);
      try {
        const response = await api.get(
          `/admin/accounts/statement?&page=${page}${transactionId}`,
          {
            headers: {
              start: '2000-01-01',
              end: format(new Date(), 'yyyy-MM-dd'),
              account: account.id,
            },
          },
        );

        {
          transactionId !== ''
            ? setExtracts(response.data.items)
            : setExtracts([]);
        }
        setMetaData({
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
          total_items: response.data.total_items,
          total_items_page: response.data.total_items_page,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar o extrato',
          });
        }
      }
    },
    [account.id, signOut, transactionId, currentPage],
  );

  const loadPix = useCallback(
    async (_expandable, record) => {
      setLoading(true);
      setExpandedKey(record.transaction_id);
      try {
        const response = await api.get(
          `/admin/accounts/pix/${record.type_id}`,
          {
            headers: {
              account: account.id,
            },
          },
        );

        const fix = [];
        fix.push(response.data);
        setExtractPix(fix);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar o extrato',
          });
        }
      }
    },
    [account.id, signOut],
  );

  useEffect(() => {
    if (SearchValue !== '') {
      setTransactionId(`&transaction-id=${SearchValue}`);
      setCurrentPage(0);
      return;
    }
    setTransactionId('');
  }, [SearchValue]);

  return (
    <ContainerContent>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Pesquisa
      </Divider>

      <RowGutter
        style={{ marginBottom: '12px', marginLeft: '0', marginRight: '0' }}
      >
        <Search
          placeholder="insira aqui a Transação Id para fazer a busca"
          onSearch={(value: string) => setSearchValue(value)}
          enterButton
        />
      </RowGutter>

      <Table
        dataSource={extracts}
        pagination={false}
        rowKey="transaction_id"
        loading={loading}
        locale={{
          emptyText: 'Nada para mostrar.',
        }}
        scroll={{ x: '500px' }}
        expandable={{
          expandedRowKeys: [expandedKey],
          onExpand: (expanded, record) =>
            expanded ? loadPix(expanded, record) : setExtractPix([]),
          expandedRowRender: () => (
            <Table
              dataSource={extractPix}
              pagination={false}
              rowKey="id"
              loading={loading}
              locale={{
                emptyText: (
                  <span style={{ color: 'grey' }}>
                    Nenhuma transação para mostrar
                  </span>
                ),
              }}
            >
              <Column
                title="Autorização"
                dataIndex="end_to_end_id"
                key="id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Transação ID"
                dataIndex="transaction_id"
                key="transaction_id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Tipo ID"
                dataIndex="id"
                key="id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Tipo"
                key="tipo"
                render={() => (
                  <Space size="middle">
                    <span>PIX</span>
                  </Space>
                )}
              />
            </Table>
          ),
          rowExpandable: record => record.type === 'PIX',
        }}
      >
        <Column
          title="Data"
          dataIndex="date"
          key="date"
          render={text => format(parseISO(text), 'dd/MM/yyyy HH:mm:ss')}
        />
        <Column title="Descrição" dataIndex="description" key="description" />
        <Column
          title="Valor"
          dataIndex="amount"
          key="amount"
          render={(text, record: ExtractItem) => {
            if (record.amount === 0) {
              return <span>{HandleFormatMoney(text)}</span>;
            }
            if (!record.credit) {
              return (
                <span style={{ color: 'green' }}>
                  {HandleFormatMoney(text)}
                </span>
              );
            }

            if (record.credit) {
              return (
                <span style={{ color: 'red' }}>-{HandleFormatMoney(text)}</span>
              );
            }

            return <span>-{HandleFormatMoney(text)}</span>;
          }}
        />
      </Table>
      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </ContainerContent>
  );
};

export default SearchPage;

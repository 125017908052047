import styled from 'styled-components';
import { Col } from '../../styles/components';

export const Container = styled.div``;

export const Pagination = styled.div`
  margin: 0 auto;
  display: flex;
  width: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  span {
    cursor: pointer;
  }

  span:nth-child(2) {
    padding-left: 15px;

    &::before {
      content: '|';
      padding-right: 15px;
    }
  }
`;

export const ICol = styled(Col)`
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > td
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0;
  }
  .ant-col.bar {
    color: black;
    border: solid;
    border-width: 1px;
    height: 215px;
    width: 1px;
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    align-content: center;
  }
`;
export const ChoseMainPartner = styled.span`
  color: #42c1c7;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

import React, { useState, useEffect, useCallback } from 'react';
import { Button, notification, Input, Upload, Row, Col } from 'antd';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Axios from 'axios';

import { FaFilePdf } from 'react-icons/fa';

import { useAuth } from '../../../hooks/auth';

import { Modal } from './styled';
import api from '../../../services/bank_api';
import { putMainPartner } from '../../../services/choseMainPartner';

const { TextArea } = Input;

interface IModalProps {
  visible: boolean;
  hide: () => void;
  type: boolean;
  allow?: string;
  analysi?: string;
  id: string;
  statusBtn: boolean;
  account_id: string;
  partners: Array<any>;
}

const AllowBlockAccontModal: React.FC<IModalProps> = ({
  visible,
  hide,
  type,
  statusBtn,
  id,
  account_id,
  partners,
}) => {
  const [inputData, setInputData] = useState('');
  const { admin } = useAuth();
  const [loading, setLoading] = useState(false);
  // const [permition, setPermition] = useState(false);
  const [currentFile, setCurrentFile] = useState<null | string>(null);
  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const [enablePreview, setEnablePreview] = useState(true);
  const [FileList, setFileList] = useState([]);

  const handlePermition = useCallback(
    async (permition: boolean) => {
      setLoading(true);

      const formData = new FormData();
      await FileList.forEach((file: any) => {
        formData.append('files', file.originFileObj);
      });

      try {
        if (partners?.length === 1) {
          await putMainPartner(account_id, id, partners[0].document);
        }
        const { data } = await api.put(
          `/admin/user-account/active/${id}?status=${permition}&&info=${inputData}`,
          formData,
          {
            headers: {
              'Content-Type': `multipart/form-data;`,
              admintoken: localStorage.getItem('@BaasQesh:token'),
            },
          },
        );

        notification.success({
          message: `Conta Pj ${
            permition ? 'Aprovada' : 'Bloqueada'
          } com Sucesso`,
        });
        setInputData('');
        setFileList([]);
        setLoading(false);
        hide();
      } catch (err: any) {
        setLoading(false);

        if (inputData === '' || inputData === undefined || inputData === null) {
          notification.error({
            message: `Fovar inserir um motivo para ${
              permition ? 'a aprovação' : 'o bloqueio'
            } da conta PJ`,
          });
        } else {
          notification.error({
            message: `Houve um erro na tentativa de ${
              permition ? 'Aprovada' : 'Bloqueada'
            } a conta PJ`,
          });
        }
        console.log(err);
      }
    },
    [FileList, inputData],
  );

  useEffect(() => {
    setInputData('');
  }, []);

  const beforeUpload = useCallback(
    file => {
      setEnablePreview(true);
      if (file.size > 5000000) {
        setCurrentFile('');
        FileList.pop();
        notification.warning({
          message: `O arquivo passa do tamanha de 5MB e não podera ser enviado`,
        });
      }
      return false;
    },
    [FileList],
  );

  return (
    <Modal
      wrapClassName="AddDocumentBlackListModal"
      centered
      visible={visible}
      onOk={() => {
        setInputData('');
        setFileList([]);
        hide();
      }}
      closable={false}
      onCancel={() => {
        setInputData('');
        setFileList([]);
        hide();
      }}
      footer={false}
      destroyOnClose
      bodyStyle={{
        padding: 20,
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <div>
        <CloseOutlined
          onClick={() => {
            setInputData('');
            setFileList([]);
            hide();
          }}
          style={{
            display: 'flex',
            justifyContent: 'right',
            color: 'red',
          }}
          size={30}
        />
        <h2
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: '#42C1C7',
          }}
        >
          Detalhamento do motivo
        </h2>
        <TextArea
          rows={6}
          style={{ background: '#F4F4F4', resize: 'none' }}
          placeholder={`Escreva aqui o motivo que justifique a ${
            statusBtn ? 'aprovação' : 'negação'
          } do usuário`}
          // value={inputData}
          // onChange={(e: any) => setInputData(e?.target?.value)}
          value={inputData.substring(0, 102285)}
          onChange={(e: any) => {
            setInputData(e.target.value);
            if (inputData.length >= 102287) {
              notification.error({
                message: 'Limite de caracteres atingido',
              });
            }
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList
              beforeUpload={beforeUpload}
              multiple
              fileList={FileList}
              accept=".pdf, image/png, image/jpeg"
              onChange={async (info: any) => {
                setFileList(info.fileList);
                if (info.fileList[info.fileList.length - 1]?.size > 5000000) {
                  await info.fileList.pop();
                }
              }}
            >
              {currentFile ? (
                <>
                  {enablePreview ? (
                    <img
                      src={currentFile || ''}
                      alt="Selfie"
                      style={{
                        width: '100%',
                        marginTop: '60px',
                        marginBottom: '60px',
                      }}
                    />
                  ) : (
                    <FaFilePdf size={125} />
                  )}
                </>
              ) : (
                <div>
                  <div style={{ marginBottom: '5px' }}>
                    Clique para fazer upload
                  </div>
                  {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                </div>
              )}
            </Upload>
          </div>
          <div>
            <Row style={{ display: 'flex', flexWrap: 'nowrap', marginTop: 40 }}>
              <Col>
                <LoadingOutlined
                  width={50}
                  height={50}
                  style={{
                    opacity: `${loading ? 1 : 0}`,
                    marginRight: 10,
                    marginTop: 9,
                  }}
                />
              </Col>
              {statusBtn && (
                <Button
                  onClick={() => {
                    handlePermition(true);
                  }}
                  type="default"
                  style={{
                    borderRadius: 6,
                    color: 'white',
                    background: 'green',
                    borderWidth: 0,
                  }}
                  disabled={loading}
                >
                  <span>Aprovar</span>
                </Button>
              )}
              {!statusBtn && (
                <Button
                  onClick={() => {
                    handlePermition(false);
                  }}
                  type="default"
                  style={{
                    borderRadius: 6,
                    color: 'white',
                    background: 'red',
                    borderWidth: 0,
                  }}
                  disabled={loading}
                >
                  <span>Negar</span>
                </Button>
              )}
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllowBlockAccontModal;

import React, { useState, useCallback } from 'react';
import { Form, Divider, Spin, Input, notification, Button } from 'antd';
import { v4 } from 'uuid';
import { InputsContainer } from './styles';

import api from '../../../services/api';

const CreateAdmin: React.FC<{
  bank: any;
}> = ({ bank }) => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState(v4().split('-')[0]);

  const createAdmin = useCallback(async () => {
    try {
      const { data } = await api.post(`/admin/users`, {
        client_id: bank.id,
        name: Name,
        email: Email,
        password: Password,
      });
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });
      } else {
        notification.error({
          message: 'Não foi possível cria a conta',
        });
      }
      return 0; // to return when no user has been created
    }
    notification.success({
      message: `A senha será gerada aleatoriamente somente uma vez para a segurança do usuário. Favor anotá-la.`,
      description: `Usuário: ${Email} Senha: ${Password}`,
      duration: null,
      key: 'senha',
      btn: (
        <Button
          type="text"
          value="enviar"
          htmlType="submit"
          style={{
            borderColor: '#d9d9d9',
          }}
          onClick={() => {
            notification.close('notific');
            notification.close('senha');
            setPassword(v4().split('-')[0]);
            setName('');
            setEmail('');
          }}
        >
          Fechar
        </Button>
      ),
    });
    return 0;
  }, [Password, Name, Email, bank]);

  const handleEnviar = useCallback(() => {
    if (Name && Email) {
      notification.info({
        message: `Favor confirmar se usuário que sera criado é : ${Name}, utilizando o e-mail: ${Email}`,
        duration: null,
        closeIcon: <div> </div>,
        key: 'notifica',
        btn: (
          <>
            <Button
              type="text"
              value="enviar"
              htmlType="submit"
              style={{
                borderColor: '#d9d9d9',
              }}
              onClick={() => {
                notification.close('notifica');
              }}
            >
              Negar
            </Button>

            <Button
              type="text"
              value="enviar"
              htmlType="submit"
              style={{
                borderColor: '#d9d9d9',
              }}
              onClick={() => {
                notification.close('notifica');
                createAdmin();
              }}
            >
              Confirmar
            </Button>
          </>
        ),
      });
    }
  }, [createAdmin, Email, Name]);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Usuário Administrador
      </Divider>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Form layout="vertical">
          <InputsContainer>
            <Form.Item
              label="Nome"
              style={{
                width: '40%',
              }}
            >
              <Input
                key="name"
                type="text"
                value={Name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label="E-mail"
              style={{
                width: '70%',
              }}
            >
              <Input
                key="email"
                type="text"
                value={Email}
                onChange={e => {
                  setEmail(e.target.value.toLocaleLowerCase());
                }}
              />
            </Form.Item>
            <Form.Item
              label=" "
              style={{
                width: '10%',
              }}
            >
              <Button
                type="text"
                value="enviar"
                htmlType="submit"
                style={{
                  borderColor: '#d9d9d9',
                }}
                onClick={handleEnviar}
              >
                Criar
              </Button>
            </Form.Item>
          </InputsContainer>
        </Form>
      )}
    </div>
  );
};

export default CreateAdmin;

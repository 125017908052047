import styled from 'styled-components';

import { Modal as modal } from 'antd';

interface IButton {
  ih: number;
}

export const Modal = styled(modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    width: 600px;
    magin-top: auto;
    margin-bottom: auto;
  }
  .button {
    display: flex;
    justify-content: space-between;
    width: 600px;
    margin-left: -50px;
  }
`;
export const ButtonStyle = styled.div<IButton>`
  top: ${props =>
    props.ih < window.innerHeight
      ? props.ih * 0.5 + 40
      : window.innerHeight * 0.5}px;
  position: ${props => (props.ih < window.innerHeight ? 'absolute' : 'fixed')};
`;

import styled from 'styled-components';

import InputMask from 'react-input-mask';
import { Menu as MenuANTD } from 'antd';

export const IMaskedInput = styled(InputMask)`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  height: 44px;

  box-sizing: border-box;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;

  &:disabled {
    cursor: default;
    color: #333;
    background: #f5f5f5;

    :hover {
      border-color: #d9d9d9;
    }
  }

  :hover {
    border-color: #40a9ff;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const CreatedAtContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
`;

export const MainAssociate = styled.span`
  display: flex;
  width: 100%;
  font-weight: bold;
  justify-content: space-between;
  border-bottom: 0.16rem solid var(--primary);
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border: 0.16rem solid grey;
  font-size: 0.7rem;
  margin: 0.5rem;
  height: 4rem;
`;

export const Associate = styled.span`
  display: flex;
  width: 100%;
  font-weight: bold;
  justify-content: space-between;
  border-bottom: 0.16rem solid var(--primary);
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.5rem;
`;

export const Container = styled.section``;

export const Menu = styled(MenuANTD)`
  .ant-menu-submenu-title {
    margin-left: -5px;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

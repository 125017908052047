import React, { useEffect, useState } from 'react';
import { Form, Divider, Spin } from 'antd';
import { CurrecyMaskedInput, InputsContainer } from './styles';
import { RowButton } from '../../../styles/components';

const General: React.FC<{
  bank: any;
}> = ({ bank }) => {
  const [feesForm] = Form.useForm();

  const [tedInputValue, setTedInputValue] = useState<number | null>(
    bank.ted_cost,
  );
  const [tefInputValue, setTefInputValue] = useState<number | null>(
    bank.tef_cost,
  );
  const [billInputValue, setBillInputValue] = useState<number | null>(
    bank.bill_cost,
  );

  useEffect(() => {
    feesForm.setFieldsValue({
      ted: Number(bank.ted_cost),
      tef: Number(bank.tef_cost),
      bill: Number(bank.bill_cost),
    });

    setTedInputValue(bank.ted_cost);
    setTefInputValue(bank.tef_cost);
    setBillInputValue(bank.bill_cost);
  }, [bank, feesForm]);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Taxas cobradas pela Qesh
      </Divider>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Form
          form={feesForm}
          layout="vertical"
          onFinish={() => {
            //
          }}
        >
          <InputsContainer>
            <Form.Item
              rules={[
                {
                  required: !!(
                    tedInputValue === undefined && billInputValue === undefined
                  ),
                  message: 'Preencha ao menos um campo.',
                },
              ]}
              name="tef"
              label="TEFs"
              initialValue={bank.tef_cost}
            >
              <CurrecyMaskedInput
                allowEmpty
                onChangeEvent={(
                  event: { target: { value: string } },
                  maskedvalue: string,
                  floatvalue: number,
                ) => setTefInputValue(floatvalue)}
                decimalSeparator=","
                thousandSeparator="."
                value={tefInputValue}
                autoComplete="off"
                disabled
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: !!(
                    tefInputValue === undefined && billInputValue === undefined
                  ),
                  message: 'Preencha ao menos um campo.',
                },
              ]}
              name="ted"
              label="TEDs"
              initialValue={bank.ted_cost}
            >
              <CurrecyMaskedInput
                allowEmpty
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={(
                  event: { target: { value: string } },
                  maskedvalue: string,
                  floatvalue: number,
                ) => setTedInputValue(floatvalue)}
                value={tedInputValue}
                type="text"
                disabled
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: !!(
                    tedInputValue === undefined && tefInputValue === undefined
                  ),
                  message: 'Preencha ao menos um campo.',
                },
              ]}
              name="bill"
              label="Boletos"
              initialValue={bank.bill_cost}
            >
              <CurrecyMaskedInput
                allowEmpty
                autoComplete="off"
                type="text"
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={(
                  event: { target: { value: string } },
                  maskedvalue: string,
                  floatvalue: number,
                ) => setBillInputValue(floatvalue)}
                value={billInputValue}
                initialvalue={null}
                disabled
              />
            </Form.Item>
          </InputsContainer>
          {/* <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <RowButton
              type="primary"
              htmlType="submit"
              style={{ width: '100px' }}
            >
              Salvar
            </RowButton>
          </div> */}
        </Form>
      )}
    </div>
  );
};

export default General;

import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Divider,
  Table,
  notification,
  Tooltip,
  Button,
  Row,
  Input,
  Col as ColANTD,
  Menu as MenuANTD,
  Tag,
} from 'antd';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import Column from 'antd/lib/table/Column';
import { FiUpload, FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { LoadingOutlined } from '@ant-design/icons';

import { FaPen } from 'react-icons/fa';
import { documentMask } from '../../../../../../services/utils';
import { ContainerContent } from '../../../Account/styles';
import { PropsUserAccount, PropsAccount } from '../../../Account/index';

import {
  ButtonContainer,
  Container,
  MainAssociate,
  IMaskedInput,
} from '../styles';
import { CreatedAtContainer, Menu } from '../styles';
import {
  RowGutter,
  Col,
  RawInput,
  RowButton,
} from '../../../../../../styles/components';

import { useAuth } from '../../../../../../hooks/auth';
import DocumentUploadModal from '../../../../../../components/Modals/DocumentUploadModal';

interface ICepResponseData {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

const DataPersonalPj: React.FC<{
  user: PropsUserAccount;
  account?: PropsAccount;
  getRisk: (conditions: any) => JSX.Element;
  handleSavePersonalData: any;
  handleUpdateAddress: any;
  handleUpdateAdditionalPj: any;
  handleUpdatePartner: any;
  formattedDocuments: any;
  loading: boolean;
}> = ({
  user: userParam,
  account,
  getRisk,
  handleSavePersonalData,
  handleUpdateAddress,
  handleUpdateAdditionalPj,
  handleUpdatePartner,
  formattedDocuments,
  loading,
}) => {
  const [user] = useState(userParam);
  const { admin } = useAuth();
  const [personalDataForm] = Form.useForm();
  const [additionalData] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [userAccount] = useState(user);
  const [editPartner, setEditPartner] = useState(false);

  const [
    isDocumentUploadModalOpened,
    setIsDocumentUploadModalOpened,
  ] = useState(false);

  const clientQesh =
    admin.profile === 'ADMIN' && admin.bank.name === 'Qesh Payments';

  const oppeningDate = user?.additional?.opening_date
    ? format(parseISO(user?.additional?.opening_date), 'dd/MM/yyyy')
    : '';

  const handleCepValueChange = useCallback(
    async (value: string) => {
      if (value.split('').length !== 8) {
        return;
      }

      try {
        const { data } = await axios.get<ICepResponseData>(
          `https://viacep.com.br/ws/${value}/json/`,
        );

        addressForm.setFieldsValue({
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [addressForm],
  );

  const [activePartner, setActivePartner] = useState(0);
  const [formPartner] = Form.useForm();
  const valuesDefaults =
    user?.additional?.partners?.length !== 0
      ? {
          name: user?.additional?.partners?.[activePartner]?.name,
          document: documentMask(
            user?.additional?.partners?.[activePartner]?.document,
          ),
          email: user?.additional?.partners?.[activePartner]?.email,
          qualification:
            user?.additional?.partners?.[activePartner]?.qualification,
        }
      : { name: '', document: '', email: '', qualification: '' };

  const ToogleEditPartner = (index: number): void => {
    setActivePartner(index);
    if (index === activePartner || !editPartner) {
      setEditPartner(!editPartner);
    }
  };

  const EditPartner = (index: any): any => {
    return (
      <>
        <Form
          layout="vertical"
          onFinish={handleUpdatePartner}
          initialValues={valuesDefaults}
          form={formPartner}
        >
          <RowGutter>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="name"
                label="Nome do Socio"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira o nome completo',
                  },
                  {
                    max: 200,
                    message: 'Excedeu o numero de caracteres',
                  },
                ]}
              >
                <RawInput name="name" disabled={!clientQesh} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="document"
                label="Documento"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira o Documento',
                  },
                  {
                    pattern: /\d{11}|(\d{3}.\d{3}.\d{3}-\d{2})/,
                    message: 'Numeros de caracteres invalido',
                  },
                ]}
              >
                <RawInput name="name" disabled={!clientQesh} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira o E-mail',
                  },
                  {
                    type: 'email',
                    message: 'Esse e-mail não é válido!',
                  },
                ]}
              >
                <RawInput name="name" disabled={!clientQesh} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="qualification"
                label="Qualificação do Sócio"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira o Qualificação do Sócio',
                  },
                  {
                    max: 30,
                    message: 'Excedeu o numero de caracteres',
                  },
                ]}
              >
                <RawInput name="name" disabled={!clientQesh} />
              </Form.Item>
            </Col>
          </RowGutter>
          <Form.Item name="index" initialValue={index} />
          <ButtonContainer>
            <RowButton
              style={{ width: '100px' }}
              type="primary"
              disabled={!clientQesh && loading}
              htmlType="submit"
            >
              Salvar
            </RowButton>
          </ButtonContainer>
        </Form>
      </>
    );
  };

  useEffect(() => {
    formPartner.setFieldsValue(valuesDefaults);
  }, [formPartner, valuesDefaults]);

  return (
    <ContainerContent>
      <span style={{ float: 'right' }}>{user?.user?.bank?.name}</span>
      <br />
      <CreatedAtContainer>
        <span>
          Data de criação do usuário:{' '}
          {format(parseISO(String(user.user.created_at)), 'dd/MM/yyyy')}
        </span>
      </CreatedAtContainer>
      <CreatedAtContainer>
        {getRisk(userAccount?.generalConditions)}
      </CreatedAtContainer>
      {/* <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Dados Pessoais
      </Divider>

      <Form
        scrollToFirstError
        form={personalDataForm}
        layout="vertical"
        onFinish={handleUpdatePartner}
      >
        <RowGutter>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name="name"
              label="Nome do Socio"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira o nome completo',
                },
              ]}
              initialValue={
                user?.additional?.partners
                  ? user?.additional?.partners[0]?.name
                  : ''
              }
            >
              <RawInput disabled={!clientQesh} name="name" />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              label="Documento"
              name="document"
              initialValue={
                user?.additional?.partners
                  ? documentMask(user?.additional?.partners[0]?.document)
                  : ''
              }
            >
              <RawInput name="document" disabled />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="qualification"
              label="Qualificação do Sócio"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira a qualificação do sócio',
                },
              ]}
              initialValue={
                user?.additional?.partners
                  ? user?.additional?.partners[0]?.qualification
                  : ''
              }
            >
              <RawInput disabled={!clientQesh} name="qualification" />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira o e-mail',
                },
                {
                  type: 'email',
                  message: 'Esse e-mail não é válido!',
                },
              ]}
              initialValue={user.user.email}
            >
              <RawInput disabled={!clientQesh} name="email" />
            </Form.Item>
          </Col>
        </RowGutter>
        <Form.Item name="index" initialValue={0} />
        <ButtonContainer>
          <RowButton
            disabled={!clientQesh}
            style={{ width: '100px' }}
            type="primary"
            htmlType="submit"
          >
            Salvar
          </RowButton>
        </ButtonContainer>
      </Form> */}

      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Dados da Empresa
      </Divider>

      <Form
        scrollToFirstError
        form={additionalData}
        layout="vertical"
        onFinish={handleUpdateAdditionalPj}
      >
        <RowGutter>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name="name"
              label="Razão Social"
              initialValue={user?.user?.name}
            >
              <RawInput name="name" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              label="Data de Abertura"
              name="opening_date"
              initialValue={oppeningDate}
            >
              <RawInput name="opening_date" disabled={!clientQesh} />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="document"
              label="CNPJ"
              initialValue={documentMask(user?.user?.document)}
            >
              <RawInput name="document" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="trading_name"
              label="Nome Fantasia"
              initialValue={user?.additional?.trading_name}
            >
              <RawInput name="trading_name" disabled={!clientQesh} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="share_capital"
              label="Capital Social"
              initialValue={user?.additional?.share_capital}
            >
              <RawInput name="share_capital" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="number_of_employees"
              label="Número de Funcionários"
              initialValue={user?.additional?.number_of_employees}
            >
              <RawInput name="number_of_employees" disabled={!clientQesh} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="revenues"
              label="Faturamento Anual"
              initialValue={user?.additional?.revenues}
            >
              <RawInput name="revenues" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="number_of_partners"
              label="Número de Sócios / Representante Legal"
              initialValue={user?.additional?.number_of_partners}
            >
              <RawInput name="number_of_partners" disabled={!clientQesh} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="legal_nature"
              label="Natureza Jurídica"
              initialValue={user?.additional?.legal_nature}
            >
              <RawInput name="legal_nature" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="cnae"
              label="Categoria de Negócio"
              initialValue={user?.additional?.cnae}
            >
              <RawInput name="cnae" disabled={!clientQesh} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="number_of_affiliates"
              label="Quantidade de filiais"
              initialValue={user?.additional?.number_of_affiliates}
            >
              <RawInput name="number_of_affiliates" disabled={!clientQesh} />
            </Form.Item>
          </Col>
        </RowGutter>
        {clientQesh && (
          <ButtonContainer>
            <RowButton
              style={{ width: '100px' }}
              type="primary"
              disabled={!clientQesh && loading}
              htmlType="submit"
            >
              Salvar
            </RowButton>
          </ButtonContainer>
        )}
      </Form>

      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Socios da Empresa
      </Divider>
      {(user?.additional?.partners?.length === 0 ||
        user?.additional?.partners === undefined) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3>Sócios/Representantes não cadastrados</h3>
        </div>
      )}
      <Row>
        {user?.additional?.partners?.map((partner, index) => {
          return (
            <ColANTD style={{ marginRight: '5px', display: 'table' }}>
              <MainAssociate style={{ cursor: 'default' }}>
                {`${partner?.name} / ${documentMask(partner?.document)}`}
                {clientQesh && (
                  <>
                    <Menu
                      style={{
                        background: 'white',
                        borderRight: '0px',
                        width: '15px',
                      }}
                      expandIcon={<FiMoreVertical />}
                    >
                      <Menu.SubMenu key="1" style={{ background: 'white' }}>
                        <Menu.Item
                          key="1"
                          onClick={() => ToogleEditPartner(index)}
                          icon={<FaPen style={{ marginRight: '10px' }} />}
                        >
                          Editar
                        </Menu.Item>
                      </Menu.SubMenu>
                    </Menu>
                  </>
                )}
              </MainAssociate>
            </ColANTD>
          );
        })}
      </Row>
      {editPartner && EditPartner(activePartner)}

      {user?.address && (
        <>
          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
            }}
          >
            Endereço
          </Divider>
          <Form
            form={addressForm}
            layout="vertical"
            onFinish={handleUpdateAddress}
          >
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  initialValue={user.address.zip_code}
                  label="Cep"
                  name="zip_code"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o cep.',
                    },
                    { len: 8, message: 'Digite 8 dígitos.' },
                  ]}
                >
                  <RawInput
                    onChange={e => handleCepValueChange(e.target.value)}
                    name="zip_code"
                    disabled={!clientQesh}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  initialValue={user.address.street}
                  label="Rua"
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a rua',
                    },
                  ]}
                >
                  <RawInput name="street" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
              >
                <Form.Item
                  initialValue={user.address.number}
                  label="N°"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o número.',
                    },
                    {
                      // eslint-disable-next-line consistent-return
                      validator: async (_, number) => {
                        if (Number.isNaN(Number(number))) {
                          return Promise.reject(
                            new Error('Por favor insira apenas números.'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <RawInput name="number" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="complement"
                  label="Complemento"
                  initialValue={user.address?.complement}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o complemento',
                    },
                  ]}
                >
                  <RawInput name="complement" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
              >
                <Form.Item
                  initialValue={user.address.neighborhood}
                  name="neighborhood"
                  label="Bairro"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o bairro',
                    },
                  ]}
                >
                  <RawInput name="neighborhood" disabled={!clientQesh} />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  initialValue={user.address.city}
                  label="Cidade"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira a cidade',
                    },
                  ]}
                >
                  <RawInput name="city" disabled={!clientQesh} />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
              >
                <Form.Item
                  initialValue={user.address.state}
                  name="state"
                  label="Estado"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor insira o estado',
                    },
                  ]}
                >
                  <RawInput name="state" disabled={!clientQesh} />
                </Form.Item>
              </Col>
            </RowGutter>
            {clientQesh && (
              <ButtonContainer>
                <RowButton
                  type="primary"
                  style={{ width: '100px' }}
                  htmlType="submit"
                  disabled={!clientQesh}
                >
                  Salvar
                </RowButton>
              </ButtonContainer>
            )}
          </Form>
        </>
      )}

      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Documentos
      </Divider>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          marginBottom: '8px',
        }}
      >
        <Tooltip title="Fazer upload de um documento">
          <RowButton
            onClick={() => setIsDocumentUploadModalOpened(true)}
            type="primary"
            disabled={!clientQesh}
            style={{ width: '50px' }}
          >
            <FiUpload />
          </RowButton>
        </Tooltip>
      </div>
      {userAccount?.documents && userAccount.documents.length > 0 && (
        <>
          <Table
            showHeader
            dataSource={formattedDocuments}
            rowKey="id"
            pagination={false}
            // loading={loading}
            locale={{
              emptyText: 'Nenhum documento para mostrar.',
            }}
            scroll={{ x: '500px' }}
          >
            <Column title="Categoria" dataIndex="category" key="category" />
            <Column title="Data" dataIndex="date" key="date" />
            <Column title="" dataIndex="link_to_pic" key="link_to_pic" />
          </Table>
        </>
      )}
      <DocumentUploadModal
        userId={user.user.id}
        visible={isDocumentUploadModalOpened}
        hide={() => {
          setIsDocumentUploadModalOpened(false);
        }}
      />
    </ContainerContent>
  );
};

export default DataPersonalPj;

import React, { useState, useCallback } from 'react';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';

import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-dark.css';

import Workbook from 'react-xlsx-workbook';

import { Button, Table, notification } from 'antd';

import api from '../../services/api';

import { Container } from './styles';

const { Column } = Table;

const Blank: React.FC = () => {
  const [code, setCode] = useState(() => {
    return (
      localStorage.getItem('statement') ||
      'select id, document, name, status as st, type from users order by created_at desc limit 20'
    );
  });

  const [results, setResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [keys, setKeys] = useState([] as Array<string>);
  const [originalKeys, setOriginalKeys] = useState([] as Array<string>);
  const [loading, setLoading] = useState(false);

  const runQuery = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.post('/admin/logs/raw', {
        raw: code,
      });

      let formattedData = {};

      setResults(
        data.map((d: any, index: number) => {
          const keysColuns = Object.keys(d);

          keysColuns.forEach(k => {
            if (typeof d[k] === 'object' || typeof d[k] === 'boolean') {
              formattedData = { ...formattedData, [k]: JSON.stringify(d[k]) };
            } else {
              formattedData = { ...formattedData, [k]: d[k] };
            }
          });
          return { ...formattedData, '#': String(index + 1) };
        }),
      );

      setOriginalResults(
        data.map((d: any, index: number) => {
          const keysColuns = Object.keys(d);

          keysColuns.forEach(k => {
            if (typeof d[k] === 'object' || typeof d[k] === 'boolean') {
              formattedData = { ...formattedData, [k]: JSON.stringify(d[k]) };
            } else {
              formattedData = { ...formattedData, [k]: d[k] };
            }
          });
          return { ...formattedData };
        }),
      );

      const KEYS = Object.keys(data?.[0] ? data[0] : undefined);
      setKeys(['#', ...KEYS]);
      setOriginalKeys([...KEYS]);
      setLoading(false);
      localStorage.setItem('statement', code);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'Invalid.',
      });
    }
  }, [code]);

  return (
    <Container>
      <Editor
        className="editor"
        value={code}
        onValueChange={codeInput => setCode(codeInput)}
        highlight={codeInput => highlight(codeInput, languages.sql, 'sql')}
        padding={20}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 15,
        }}
      />

      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button htmlType="button" type="dashed" onClick={runQuery}>
          Executar
        </Button>

        <div style={{ marginLeft: '30px' }}>
          <Workbook
            filename="repost.xlsx"
            element={<Button type="dashed">Exportar</Button>}
          >
            <Workbook.Sheet data={originalResults} name="Report">
              {originalKeys.map(column => (
                <Workbook.Column
                  key={column}
                  label={column}
                  value={(row: any) => row[column]}
                />
              ))}
            </Workbook.Sheet>
          </Workbook>
        </div>
      </div>

      <Table
        dataSource={results}
        pagination={{ pageSize: 50 }}
        rowKey="#"
        loading={loading}
        locale={{
          emptyText: 'Nenhum resultado.',
        }}
        // scroll={{ x: true, y: 250 }}
        scroll={{ y: 440, x: true }}
      >
        {keys.map(column => (
          <Column
            title={column}
            dataIndex={column}
            key={column}
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => (
              <div
                style={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  minWidth: column === '#' ? '20px' : '200px',
                }}
              >
                {text}
              </div>
            )}
          />
        ))}
      </Table>
    </Container>
  );
};

export default Blank;

import React, { useState, useCallback, useEffect } from 'react';
import { Divider, Spin, notification, Table } from 'antd';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

interface IUserAdmin {
  id: string;
  name: string;
  email: string;
  profile: string;
}

const { Column } = Table;

const ShowAdminUsers: React.FC<{
  bank: any;
}> = ({ bank }) => {
  const [userAdmin, setUserAdmin] = useState([] as IUserAdmin[]);
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useAuth();

  const getUsers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/admin/users/all', {
        headers: {
          client: bank.id,
        },
      });

      setUserAdmin(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      notification.error({
        message: 'Houve algum erro.',
        description: 'Tente mais tarde.',
      });
    }
  }, [bank]);

  useEffect(() => {
    getUsers();
  }, [bank]);

  return (
    <div>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Contas Cadastradas
      </Divider>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Table
          dataSource={userAdmin}
          pagination={false}
          rowKey="id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
        >
          <Column
            title="Nome"
            dataIndex="name"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Email"
            dataIndex="email"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          {admin.profile === 'ADMIN' && (
            <Column
              title="Permissão"
              dataIndex="profile"
              align="center"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
          )}
        </Table>
      )}
    </div>
  );
};

export default ShowAdminUsers;

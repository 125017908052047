import React, { useState } from 'react';
import { Button, Tag, Row, Input, Col } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { FaFilePdf } from 'react-icons/fa';

import { Modal } from './styled';

import ImagensModal from './ImagensModal';

const { TextArea } = Input;

interface IModalProps {
  visible: boolean;
  hide: () => void;
  imagens: [];
  details: string;
}

const ShowJustificationModal: React.FC<IModalProps> = ({
  visible,
  hide,
  imagens,
  details = '',
}) => {
  const [indexImagen, setIndexImagen] = useState(0);
  const [openImagensModal, setOpenImagensModal] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  return (
    <Modal
      wrapClassName="AddDocumentBlackListModal"
      centered
      visible={visible}
      onOk={hide}
      closable={false}
      onCancel={hide}
      footer={false}
      destroyOnClose
      bodyStyle={{
        padding: 20,
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <div>
        <CloseOutlined
          onClick={hide}
          style={{
            display: 'flex',
            justifyContent: 'right',
            color: 'red',
          }}
          size={30}
        />

        <h2
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: '#42C1C7',
          }}
        >
          Detalhamento do motivo
        </h2>

        <Row style={{ marginBottom: '10px' }}>
          {imagens.length > 0 &&
            imagens.map((link: string, index: number) => (
              <Col style={{ marginRight: 10 }}>
                <Tag
                  onClick={() => {
                    setIndexImagen(index);
                    if (loadingImage) {
                      if (link.includes('pdf')) {
                        window.open(link, '_blank', 'noopener,noreferrer');
                      } else {
                        setOpenImagensModal(true);
                      }
                    }
                  }}
                  style={{
                    borderStyle: 'hidden',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    marginBottom: 50,
                  }}
                >
                  {link.includes('pdf') ? (
                    <>
                      <Col>
                        <Row>
                          <FaFilePdf size={75} />
                        </Row>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <h6>{`PDF_${index}`}</h6>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <>
                      <img
                        src={link}
                        alt="not found"
                        height={100}
                        onLoad={(e: any) => {
                          setLoadingImage(true);
                        }}
                        onError={() => {
                          setLoadingImage(false);
                        }}
                      />
                    </>
                  )}
                </Tag>
              </Col>
            ))}
        </Row>
        <TextArea
          rows={6}
          style={{
            background: '#F4F4F4',
            resize: 'none',
            color: 'transparent',
            textShadow: '0px 0px 0px black',
          }}
          value={details}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <Button
            onClick={hide}
            type="default"
            style={{
              borderRadius: 6,
              color: 'white',
              background: '#24A148',
              borderWidth: 0,
            }}
          >
            <span>Entendido</span>
          </Button>
        </div>
      </div>
      <ImagensModal
        visible={openImagensModal}
        hide={() => {
          setOpenImagensModal(false);
        }}
        img={imagens}
        index={indexImagen}
      />
    </Modal>
  );
};

export default ShowJustificationModal;

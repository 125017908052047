import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Divider, notification, Spin, Tooltip } from 'antd';
import bankApi from '../../../../../services/bank_api';
import api from '../../../../../services/api';
import { CurrecyMaskedInput, InputsContainer } from './styles';
import { ContainerContent } from '../../Account/styles';
import { RawInput, RowButton } from '../../../../../styles/components';
import { PropsAccount, PropsUserAccount } from '../../Account';

interface PropsParams {
  account_id: string;
}

const Fees: React.FC<{
  user: PropsUserAccount;
  account: PropsAccount;
}> = ({ account: accountParam }) => {
  const { account_id } = useParams<PropsParams>();
  const [feesForm] = Form.useForm();
  const [pixForm] = Form.useForm();
  const [monthlyPaymentForm] = Form.useForm();
  const [freeFeesForm] = Form.useForm();
  const [monthlyFreeBillsForm] = Form.useForm();
  const [limitsForm] = Form.useForm();

  const [tedInputValue, setTedInputValue] = useState<number | null>(0);
  const [tefInputValue, setTefInputValue] = useState<number | null>(0);
  const [billInputValue, setBillInputValue] = useState<number | null>(0);
  const [limitPaymentInputValue, setLimitPaymentInputValue] = useState<
    number | null
  >(0);
  const [limitTedInputValue, setLimitTedInputValue] = useState<number | null>(
    0,
  );

  const [pixTransferInputValue, setPixTransferInputValue] = useState<
    number | null
  >(0);

  const [pixQrCodeInputValue, setPixQrCodeInputValue] = useState<number | null>(
    0,
  );

  const [pixReceivementInputValue, setPixReceivementInputValue] = useState<
    number | null
  >(accountParam.pix_receivement_fee || 0);

  const [monthlyPaymentInputValue, setMonthlyPaymentInputValue] = useState<
    number | null
  >(accountParam.monthly_payment || 0);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    limitsForm.setFieldsValue({
      limitPayment: accountParam.limit_payment,
      limitTed: accountParam.limit_ted,
    });
  }, [accountParam, limitsForm]);

  useEffect(() => {
    const token = localStorage.getItem('@BaasQesh:token');

    bankApi
      .get(`/admin/user-accounts/monthly-free-bills/${accountParam.id}`, {
        headers: {
          admintoken: token,
        },
      })
      .then(response => {
        const { data } = response;

        monthlyFreeBillsForm.setFieldsValue({
          monthlyFreeBills: data.monthly_free_bills,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    bankApi
      .get(`/admin/user-accounts/free-fees/${accountParam.id}`, {
        headers: {
          admintoken: token,
        },
      })
      .then(response => {
        const { data } = response;

        freeFeesForm.setFieldsValue({
          ted: data.free_teds,
          tef: data.free_tefs,
          bills: data.free_bills,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    bankApi
      .get(`/admin/users/fees/${accountParam.id}`, {
        headers: {
          admintoken: token,
        },
      })
      .then(response => {
        const { data } = response;

        feesForm.setFieldsValue({
          ted: Number(data.ted_fee),
          tef: Number(data.tef_fee),
          bill: Number(data.bill_fee),
          pix_transfer: Number(data.pix_fee),
          pix_qrcode: Number(data.pix_qrcode_fee),
        });

        setTedInputValue(data.ted_fee);
        setTefInputValue(data.tef_fee);
        setBillInputValue(data.bill_fee);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [accountParam.id, feesForm, freeFeesForm, monthlyFreeBillsForm]);

  const handleUpdateFreeFees = useCallback(
    async values => {
      try {
        const token = localStorage.getItem('@BaasQesh:token');

        await bankApi.patch(
          `/admin/user-accounts/free-fees/${accountParam.id}`,
          {
            free_bills: values.bills,
            free_teds: values.ted,
            free_tefs: values.tef,
          },
          {
            headers: {
              admintoken: token,
            },
          },
        );

        notification.success({
          message: 'Número de taxas gratuitas atualizadas com sucesso.',
        });
      } catch (err) {
        notification.error({
          message: 'Não foi possível atualizar o número de taxas gratuitas.',
        });
      }
    },
    [accountParam.id],
  );

  const handleUpdateMonthlyFreeBills = useCallback(
    async values => {
      try {
        const token = localStorage.getItem('@BaasQesh:token');

        await bankApi.patch(
          `/admin/user-accounts/monthly-free-bills/${accountParam.id}`,
          {
            monthly_free_bills: values.monthlyFreeBills,
          },
          {
            headers: {
              admintoken: token,
            },
          },
        );

        notification.success({
          message: 'Número de taxas padrões gratuitas atualizadas com sucesso.',
        });
      } catch (err) {
        notification.error({
          message:
            'Não foi possível atualizar o número de taxas padrões gratuitas.',
        });
      }
    },
    [accountParam.id],
  );

  const handleUpdateFees = useCallback(async (): Promise<void> => {
    try {
      const token = localStorage.getItem('@BaasQesh:token');
      const data = {};

      String(tedInputValue) &&
        Object.assign(data, {
          ted_fee: tedInputValue,
        });

      String(tefInputValue) &&
        Object.assign(data, {
          tef_fee: tefInputValue,
        });

      String(billInputValue) &&
        Object.assign(data, {
          bill_fee: billInputValue,
        });

      String(pixTransferInputValue) &&
        Object.assign(data, {
          pix_fee:
            accountParam.user.document.length < 12 ? 0 : pixTransferInputValue,
        });

      String(pixQrCodeInputValue) &&
        Object.assign(data, {
          pix_qrcode_fee:
            accountParam.user.document.length < 12 ? 0 : pixQrCodeInputValue,
        });

      await bankApi.patch(`/admin/users/fees/${account_id}`, data, {
        headers: {
          admintoken: token,
        },
      });

      notification.success({
        message: 'Taxas atualizadas com sucesso.',
      });
    } catch (e) {
      notification.error({
        message: 'Não foi possível atualizar as taxas.',
      });
    }
  }, [
    account_id,
    billInputValue,
    tedInputValue,
    tefInputValue,
    pixTransferInputValue,
    pixQrCodeInputValue,
    accountParam,
  ]);

  const handleReceivementFeesSubmit = useCallback(async (): Promise<void> => {
    try {
      const data = {};

      String(pixReceivementInputValue) &&
        Object.assign(data, {
          fee:
            accountParam.user.document.length < 12
              ? 0
              : pixReceivementInputValue,
        });

      await api.patch(`/admin/accounts/pix-receivement-fee`, data, {
        headers: {
          account: account_id,
        },
      });

      notification.success({
        message: 'Taxas de recebimento atualizadas com sucesso.',
      });
    } catch (e) {
      notification.error({
        message: 'Não foi possível atualizar as taxas.',
      });
    }
  }, [account_id, pixReceivementInputValue, accountParam]);

  const handleUpdateMonthlyPaymentSubmit = useCallback(async (): Promise<
    void
  > => {
    try {
      const data = {};

      String(monthlyPaymentInputValue) &&
        Object.assign(data, {
          monthly_payment: monthlyPaymentInputValue,
        });

      await api.patch(`/admin/accounts/monthly-payment`, data, {
        headers: {
          account: account_id,
        },
      });

      notification.success({
        message: 'Taxa de mensalidade atualizada com sucesso.',
      });
    } catch (e) {
      notification.error({
        message: 'Não foi possível atualizar as taxas.',
      });
    }
  }, [account_id, monthlyPaymentInputValue]);

  return (
    <ContainerContent>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Valores das Tarifas
      </Divider>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : (
        <>
          <Form form={feesForm} layout="vertical" onFinish={handleUpdateFees}>
            <InputsContainer style={{ marginBottom: 10 }}>
              <Form.Item
                rules={[
                  {
                    required: !!(
                      tedInputValue === undefined &&
                      billInputValue === undefined
                    ),
                    message: 'Preencha ao menos um campo.',
                  },
                ]}
                name="tef"
                label="TEFs"
                initialValue={tefInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setTefInputValue(floatvalue)}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={tefInputValue}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: !!(
                      tefInputValue === undefined &&
                      billInputValue === undefined
                    ),
                    message: 'Preencha ao menos um campo.',
                  },
                ]}
                name="ted"
                label="TEDs"
                initialValue={tedInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  decimalSeparator=","
                  thousandSeparator="."
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setTedInputValue(floatvalue)}
                  value={tedInputValue}
                  type="text"
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: !!(
                      tedInputValue === undefined && tefInputValue === undefined
                    ),
                    message: 'Preencha ao menos um campo.',
                  },
                ]}
                name="bill"
                label="Boletos"
                initialValue={billInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  autoComplete="off"
                  type="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setBillInputValue(floatvalue)}
                  value={billInputValue}
                  initialvalue={null}
                />
              </Form.Item>
            </InputsContainer>
            {accountParam.user.document.length < 12 && (
              <div style={{ textAlign: 'center' }}>
                <span
                  style={{
                    color: '#e39842',
                    width: '100%',
                  }}
                >
                  As taxas Pix só podem ser cobradas de contas Pessoa Jurídica.
                </span>
              </div>
            )}
            <InputsContainer style={{ marginTop: 10 }}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                name="pix_transfer"
                label="Transferência PIX"
                initialValue={pixTransferInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setPixTransferInputValue(floatvalue)}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={pixTransferInputValue}
                  autoComplete="off"
                  disabled={accountParam.user.document.length < 12}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Preencha este campo.',
                  },
                ]}
                name="pix_qrcode"
                label="Geração de QRCode Pix"
                initialValue={pixQrCodeInputValue}
                style={{ maxWidth: 230 }}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  decimalSeparator=","
                  thousandSeparator="."
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setPixQrCodeInputValue(floatvalue)}
                  value={pixQrCodeInputValue}
                  type="text"
                  disabled={accountParam.user.document.length < 12}
                />
              </Form.Item>
            </InputsContainer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <RowButton
                type="primary"
                htmlType="submit"
                style={{ width: '100px' }}
              >
                Salvar
              </RowButton>
            </div>
          </Form>

          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
              marginTop: 70,
            }}
          >
            Tarifas de recebimento
          </Divider>

          <Form
            form={pixForm}
            layout="vertical"
            onFinish={handleReceivementFeesSubmit}
            style={{ textAlign: 'center' }}
          >
            {accountParam.user.document.length < 12 && (
              <div style={{ textAlign: 'center' }}>
                <span
                  style={{
                    color: '#e39842',
                    width: '100%',
                  }}
                >
                  As taxas Pix só podem ser cobradas de contas Pessoa Jurídica.
                </span>
              </div>
            )}
            <InputsContainer style={{ marginTop: 10 }}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Preencha este campo.',
                  },
                ]}
                name="pix_receivement"
                label="Recebimento de Pix"
                initialValue={pixReceivementInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  decimalSeparator=","
                  thousandSeparator="."
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setPixReceivementInputValue(floatvalue)}
                  value={pixReceivementInputValue}
                  type="text"
                  disabled={accountParam.user.document.length < 12}
                />
              </Form.Item>
            </InputsContainer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <RowButton
                type="primary"
                htmlType="submit"
                style={{ width: '100px' }}
                disabled={accountParam.user.document.length < 12}
              >
                Salvar
              </RowButton>
            </div>
          </Form>

          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
              marginTop: 70,
            }}
          >
            Taxa de mensalidade
          </Divider>

          <Form
            form={pixForm}
            layout="vertical"
            onFinish={handleUpdateMonthlyPaymentSubmit}
            style={{ textAlign: 'center' }}
          >
            <InputsContainer style={{ marginTop: 10 }}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Preencha este campo.',
                  },
                ]}
                name="monthly_payment"
                label="Mensalidade"
                initialValue={monthlyPaymentInputValue}
              >
                <CurrecyMaskedInput
                  allowEmpty
                  decimalSeparator=","
                  thousandSeparator="."
                  onChangeEvent={(
                    event: { target: { value: string } },
                    maskedvalue: string,
                    floatvalue: number,
                  ) => setMonthlyPaymentInputValue(floatvalue)}
                  value={monthlyPaymentInputValue}
                  type="text"
                />
              </Form.Item>
            </InputsContainer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <RowButton
                type="primary"
                htmlType="submit"
                style={{ width: '100px' }}
              >
                Salvar
              </RowButton>
            </div>
          </Form>

          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
              marginTop: 70,
            }}
          >
            Quantidade de TEDs, TEFs e boletos gratuitos
          </Divider>
          <Form
            form={freeFeesForm}
            onFinish={handleUpdateFreeFees}
            layout="vertical"
          >
            <InputsContainer>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor digite a quantidade TEDs gratuitos.',
                  },
                  {
                    // eslint-disable-next-line consistent-return
                    validator: async (_, number) => {
                      if (Number.isNaN(Number(number))) {
                        return Promise.reject(
                          new Error('Por favor insira apenas números.'),
                        );
                      }
                    },
                  },
                ]}
                name="ted"
                label="TEDs"
              >
                <RawInput name="ted" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor digite a quantidade TEFs gratuitos.',
                  },
                  {
                    // eslint-disable-next-line consistent-return
                    validator: async (_, tef) => {
                      if (Number.isNaN(Number(tef))) {
                        return Promise.reject(
                          new Error('Por favor insira apenas números.'),
                        );
                      }
                    },
                  },
                ]}
                name="tef"
                label="TEFs"
              >
                <RawInput />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor digite a quantidade boletos gratuitos.',
                  },
                  {
                    // eslint-disable-next-line consistent-return
                    validator: async (_, bill) => {
                      if (Number.isNaN(Number(bill))) {
                        return Promise.reject(
                          new Error('Por favor insira apenas números.'),
                        );
                      }
                    },
                  },
                ]}
                name="bills"
                label="Boletos"
              >
                <RawInput />
              </Form.Item>
            </InputsContainer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <RowButton
                htmlType="submit"
                type="primary"
                style={{ width: '100px' }}
              >
                Salvar
              </RowButton>
            </div>
          </Form>

          <Divider
            style={{
              fontSize: '24px',
              letterSpacing: '0.5px',
              color: 'rgb(102,102,102)',
              fontWeight: 300,
              marginTop: 70,
            }}
          >
            Quantidade de boletos gratuitos mensais
          </Divider>
          <Form
            form={monthlyFreeBillsForm}
            onFinish={handleUpdateMonthlyFreeBills}
            layout="vertical"
          >
            <InputsContainer>
              <Tooltip title="A cada início de mês o usuário receberá a quantidade parametrizada de boletos gratuitos. (Valor não cumulativo)">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message:
                        'Por favor digite a quantidade boletos gratuitos.',
                    },
                    {
                      // eslint-disable-next-line consistent-return
                      validator: async (_, bill) => {
                        if (Number.isNaN(Number(bill))) {
                          return Promise.reject(
                            new Error('Por favor insira apenas números.'),
                          );
                        }
                      },
                    },
                  ]}
                  name="monthlyFreeBills"
                  label="Boletos Mensais"
                >
                  <RawInput />
                </Form.Item>
              </Tooltip>
            </InputsContainer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <RowButton
                htmlType="submit"
                type="primary"
                style={{ width: '100px' }}
              >
                Salvar
              </RowButton>
            </div>
          </Form>
        </>
      )}

      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
          marginTop: 70,
        }}
      >
        Limites por Transações
      </Divider>

      <Form form={limitsForm} layout="vertical">
        <InputsContainer>
          <Form.Item
            // rules={[
            //   {
            //     required: !!(
            //       tedInputValue === undefined && billInputValue === undefined
            //     ),
            //     message: 'Preencha ao menos um campo.',
            //   },
            // ]}
            name="limitPayment"
            label="Limite de Pagamento"
            initialValue={limitPaymentInputValue}
            style={{ width: '50%' }}
          >
            <CurrecyMaskedInput
              allowEmpty
              onChangeEvent={(
                event: { target: { value: string } },
                maskedvalue: string,
                floatvalue: number,
              ) => setTefInputValue(floatvalue)}
              decimalSeparator=","
              thousandSeparator="."
              value={limitPaymentInputValue}
              autoComplete="off"
              disabled
            />
          </Form.Item>

          <Form.Item
            name="limitTed"
            label="Limite de TED"
            initialValue={limitTedInputValue}
          >
            <CurrecyMaskedInput
              allowEmpty
              onChangeEvent={(
                event: { target: { value: string } },
                maskedvalue: string,
                floatvalue: number,
              ) => setTefInputValue(floatvalue)}
              decimalSeparator=","
              thousandSeparator="."
              value={limitTedInputValue}
              autoComplete="off"
              disabled
            />
          </Form.Item>
        </InputsContainer>
      </Form>
    </ContainerContent>
  );
};

export default Fees;

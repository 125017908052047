import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
    :root{
    --error: #DA1E28;
    --success: #24A148;
    --warning: #faad14;
    --monitoring: #1890FF;
  }
  body {
    background: #fafafa;
    /* color: #FFF; */
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  .ant-layout-header {
    background: #E6E6E6;
  }

  .ant-layout-sider-children {
    background: #E6E6E6;
  }

  .ant-menu  {
    background: #E6E6E6;

    .ant-menu-sub {
      background: #E6E6E6;
    }
  }

  .ant-layout-sider-trigger {
    background: #E6E6E6;

    color: #000;
    border-top: 1px solid #000;
  }


  .ant-card {
    border-radius: 6px;
  }

  .ant-table {
    background: #f0f2f5;
  }

  .ant-table table {
    border-spacing: 0 8px;
  }

  .ant-table-tbody tr {
    background: #ffffff;
  }

  .ant-table-tbody tr td {
    padding-top: 20px;
    padding-bottom: 20px;
}
  .ant-table-tbody tr td:first-child {
    /* border-left-style: solid; */
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
  .ant-table-tbody tr td:last-child {
      /* border-right-style: solid; */
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
  }


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}


  /* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #bddedf;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #bddedf;
}



`;

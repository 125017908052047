/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';

import { FiX, FiLoader } from 'react-icons/fi';
import { Content, CurrecyMaskedInput, Header, IButton } from './styles';
import api from '../../../services/api';

interface IDocumentsModalProps {
  id: string;
  current_limit: number;
  requested_limit: number;
  visible: boolean;
  hide: () => void;
}

const AcceptRequestModal: React.FC<IDocumentsModalProps> = ({
  visible,
  hide,
  id,
  requested_limit,
  current_limit,
}) => {
  const [loading, setLoading] = useState(false);
  const [valueToApprove, setValueToApprove] = useState(Number(requested_limit));

  useEffect(() => setValueToApprove(Number(requested_limit)), [
    requested_limit,
  ]);

  const handleApprove = useCallback(async () => {
    setLoading(true);

    try {
      await api.patch(`limit-requests/${id}`, {
        status: 'ACCEPTED',
        value: valueToApprove,
      });

      notification.success({ message: 'Limite alterado com sucesso.' });
    } catch (e) {
      notification.error({
        message: 'Ocorreu um erro tente novamente mais tarde',
      });
    }

    setLoading(false);
    hide();
  }, [hide, id, valueToApprove]);

  return (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={() => {
        hide();
      }}
      closable={false}
      onCancel={() => {
        hide();
      }}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0, borderRadius: '8px' }}
    >
      <Header>
        <FiX
          onClick={() => {
            hide();
          }}
        />
      </Header>
      <Content>
        <p>Valor do limite a ser aprovado</p>

        <div className="value">
          <span>R$</span>
          <CurrecyMaskedInput
            allowEmpty
            decimalSeparator=","
            thousandSeparator="."
            value={Number(valueToApprove)}
            onChangeEvent={(
              _e: any,
              _maskedvalue: string,
              floatvalue: number,
            ) => {
              setValueToApprove(floatvalue);
            }}
            autoComplete="off"
          />
        </div>

        <span
          style={{
            color: '#cccccc',
            marginTop: '4px',
            fontSize: '12px',
          }}
        >
          {`Limite atual do usuário ${Number(current_limit).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          )}`}
        </span>

        <IButton disabled={!!loading} onClick={handleApprove}>
          {loading ? <FiLoader /> : <span>Aprovar</span>}
        </IButton>
      </Content>
    </Modal>
  );
};

export default AcceptRequestModal;

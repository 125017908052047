import styled from 'styled-components';

import { Modal as modal } from 'antd';

import * as Antd from 'antd';

interface IButton {
  ih: number;
}

export const Modal = styled(modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    width: 600px;
    magin-top: auto;
    margin-bottom: auto;
  }
  .button {
    // position: fixed;
    display: flex;
    justify-content: space-between;
    width: 600px;
    margin-left: -50px;
    // top: ${window.innerHeight * 0.5}px;
  }
`;
export const ButtonStyle = styled.div<IButton>`
  top: ${props =>
    props.ih < window.innerHeight
      ? props.ih * 0.5 + 40
      : window.innerHeight * 0.5}px;
  position: ${props => (props.ih < window.innerHeight ? 'absolute' : 'fixed')};
`;
export const ChoseMainPartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ChoseMainPartnerBtn = styled.button`
  background: #42c1c7;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  padding: 10px;
`;
export const PartnersInfo = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
  }
`;
export const PartnersDisplay = styled(Antd.Radio)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import styled from 'styled-components';
import { RowButton } from '../../../../../styles/components';

export const SuspendButton = styled(RowButton)`
  background: #ff4252;
  border: #ff4252;

  :hover {
    background: #fc5c69;
    border: #ff4252;
  }

  :focus {
    background: #ff4252;
    border: #ff4252;
  }

  :disabled {
    background: #ccc;
    border: #ccc;
    color: #000;

    :hover {
      background: #ccc;
      border: #ccc;
    }
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const UnsuspendButton = styled(RowButton)`
  background: #87d068;
  border: #87d068;

  :hover {
    background: #a6f584;
    border: #a6f584;
  }

  :focus {
    background: #a6f584;
    border: #a6f584;
  }

  :disabled {
    background: #ccc;
    border: #ccc;
    color: #000;

    :hover {
      background: #ccc;
      border: #ccc;
    }
  }
`;

import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignores
import CurrencyInput from 'react-currency-input';

export const CurrecyMaskedInput = styled(CurrencyInput)`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  height: 44px;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;

  &:disabled {
    cursor: default;
    color: #333;
  }

  :hover {
    border-color: #40a9ff;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div.ant-row + div.ant-row {
    margin-left: 8px;
    flex: 1;
    min-width: 100px;
    background-color: 'palevioletred';
  }
`;

/* eslint-disable @typescript-eslint/ban-ts-comment */

import { format, parseISO } from 'date-fns';
// @ts-ignore
import xl from 'excel4node';

// @ts-ignore
import FileSaver from 'file-saver';

interface IAccount {
  account_id?: string;
  user_id: string;
  active?: boolean;
  bank_code: string;
  bank_branch: string;
  bank_account: string;
  blocked: boolean;
  bv_canceled?: boolean;
  bv_completed?: boolean;
  bv_status?: number;
  canceled_at?: string;
  client_name: string;
  client_id: string;
  created_at: string;
  daily_limit?: number;
  daily_limit_used?: number;
  document: string;
  email: string;
  is_pool?: boolean;
  limit_payment?: number;
  limit_ted?: number;
  migration: false;
  name: string;
  phone: string;
  status: number;
  suspended_at?: string;
  type: 'PJ' | 'PF';
}

export const generateSheet = async (
  client: { name: string; id: string },
  accounts: IAccount[],
): Promise<void> => {
  const wb = new xl.Workbook();

  const ws = wb.addWorksheet('Sheet 1');

  const style = wb.createStyle({
    font: {
      color: '#000000',
      size: 12,
    },
  });

  const headersStyle = wb.createStyle({
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#cccccc',
      fgColor: '#cccccc',
    },
    font: {
      color: '#000000',
      size: 12,
    },
  });

  const linhaInicial = 2;

  ws.column(1).setWidth(20);
  ws.column(2).setWidth(50);
  ws.column(3).setWidth(40);
  ws.column(4).setWidth(20);
  ws.column(5).setWidth(20);
  ws.column(6).setWidth(20);
  ws.column(7).setWidth(20);
  ws.column(8).setWidth(20);
  ws.column(9).setWidth(20);
  ws.column(10).setWidth(20);

  ws.cell(linhaInicial - 1, 1)
    .string('Documento')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 2)
    .string('Nome')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 3)
    .string('E-mail')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 4)
    .string('Telefone')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 5)
    .string('Data de criação')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 6)
    .string('Número da conta')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 7)
    .string('Conta ativa?')
    .style(headersStyle);
  ws.cell(linhaInicial - 1, 8)
    .string('Acesso bloqueado?')
    .style(headersStyle);

  accounts.forEach((account, i) => {
    ws.cell(i + linhaInicial, 1)
      .string(account.document)
      .style(style);
    ws.cell(i + linhaInicial, 2)
      .string(account.name)
      .style(style);
    ws.cell(i + linhaInicial, 3)
      .string(account.email)
      .style(style);
    ws.cell(i + linhaInicial, 4)
      .string(account.phone)
      .style(style);
    ws.cell(i + linhaInicial, 5)
      .string(format(parseISO(account.created_at), 'dd-MM-yyyy'))
      .style(style);
    ws.cell(i + linhaInicial, 6)
      .string(account.bank_account || '')
      .style(style);
    ws.cell(i + linhaInicial, 7)
      .string(
        !account.canceled_at && !account.suspended_at && account.active
          ? 'CONTA ATIVA'
          : 'NÃO ATIVA',
      )
      .style(style);
    ws.cell(i + linhaInicial, 8)
      .string(account.blocked ? 'BLOQUEADA' : '')
      .style(style);
  });

  const excelBuffer = await wb.writeToBuffer();
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    `ListaDeContas_${client.name}_${new Date().toISOString()}.xlsx`,
  );
};

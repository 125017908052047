/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Tag, Col, Row, notification } from 'antd';

import { format, parseISO } from 'date-fns';

import {
  SearchOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  FaCheck,
  FaTimes,
  FaFilePdf,
  FaCircle,
  FaAngleRight,
} from 'react-icons/fa';

import Axios from 'axios';
import { Container, Pagination, ICol, ChoseMainPartner } from './styles';

import { RawInput, SelectInput } from '../../styles/components';

import api from '../../services/api';
import bank from '../../services/bank_api';

import { documentMask, HandleFormatMoney } from '../../services/utils';

import { useAuth } from '../../hooks/auth';
import AllowBusinessAccountModal from './Modal/AllowBusinessAccountModal';
import JustificationModal from './Modal/ShowJustificationModal';
import ImagensModal from './Modal/ImagensModal';
import ChoseMainPartnerModal from './Modal/ChoseMainPartnerModal';

const { Column } = Table;

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

interface IPJ {
  user_name: string;
  user_type: string;
  id: string;
  document: string;
  name: string;
  created_at: string;
  pending_approval: boolean;
  states_documents: string;
  user_status: string;
  user_blocked: string;
  acc_active: string;
  acc_id: string;
  bank_account: string;
  client_approval_pending: string;
}
interface IPartners {
  name: string;
  document: string;
  email: string;
  mainPartner: boolean;
  mother_name: string;
  phone: string;
}
interface IPJDetails {
  type: string;
  user_id: string; // user_id
  document: string;
  name: string;
  pending_approval: boolean;
  created_at: Date;
  legal_nature: string;
  last_year_change: boolean;
  revenues: number;
  number_of_employees: number;
  declaration_number: string;
  partners: [IPartners];
  trading_name: string; // fantasial
  number_of_affiliates: string;
  opening_date: string;
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: number;
  country: string;
  detail: string;
  share_capital: string;
  states_documents: string;
  additional_image: [];
  url: [];
  user_blocked: string;
  acc_active: boolean;
  acc_id: string;
  cnae: string;
  number_of_partners: number;
}

const BusinessAccount: React.FC = () => {
  const [accounts, setAccounts] = useState([] as Array<IPJ>);
  const [activeAccounts, setActiveAccounts] = useState({} as IPJDetails);
  const [metaData, setMetaData] = useState({} as PropsMetaData);

  const [filter, setFilter] = useState('u.document');
  const [currentPage, setCurrentPage] = useState(0);
  const [inputData, setInputData] = useState('');

  const [loading, setLoading] = useState(true);

  const [rowActive, setRowActive] = useState('');
  const [openAllowBlockModal, setOpenAllowBlockModal] = useState(false);
  const [statusBtn, setStatusBtn] = useState(false);
  const [openJustificationModal, setOpenJustificationModal] = useState(false);
  const [reload, setReload] = useState(false);

  const [typeDoc, setTypeDoc] = useState('');

  const [indexImagen, setIndexImagen] = useState(0);
  const [openImagensModal, setOpenImagensModal] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const [partnerActive, setPartnerActive] = useState([]);
  const [awaitStatusPartner, setAwaitStatusPartner] = useState(false);

  const allow = 'APPROVED';
  const bloc = 'BLOCKED';
  const analysis = 'ANALYSIS';

  const documentMaskCNPJ = (docVal: string): string => {
    const cnpj = /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})\.*([\d]{2})/;
    return docVal.replace(cnpj, '$1.$2.$3/$4-$5');
  };

  const loaddata = useCallback(async (page = 0, filters = '') => {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/pj/?page=${page}${filters}`, {});
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setAccounts(
        data.items.map((item: IPJ) => {
          return { ...item, key: item.acc_id };
        }),
      );

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err);
    }
  }, []);
  useEffect(() => {
    if (reload) {
      loaddata(currentPage);
      setReload(false);
      setRowActive('');
    } else if (typeDoc !== '') {
      prepareLoadData();
    } else {
      loaddata();
    }
  }, [loaddata, reload, typeDoc]);

  const prepareLoadData = useCallback(
    (page = 0) => {
      let finput = '';
      let ftype = '';
      if (
        inputData === '' ||
        inputData === undefined ||
        inputData === null ||
        !inputData
      ) {
        finput = '';
      } else if (filter === 'u.bank') {
        finput = `&&bank=${inputData}`;
      } else {
        finput = `${
          filter === 'u.document'
            ? `&&doc=${inputData.replace(/[/,.,-]/g, '')}`
            : `&&name=${inputData}`
        }`;
      }
      if (
        typeDoc === '' ||
        typeDoc === undefined ||
        typeDoc === null ||
        !typeDoc
      ) {
        ftype = '';
      } else {
        ftype = `${typeDoc !== '' && `&&state=${typeDoc}`}`;
      }
      const ff = finput + ftype;
      if (ff === '' || ff === undefined || ff === null || !ff) {
        loaddata(page || 0);
      } else {
        loaddata(page || 0, ff);
      }
    },
    [filter, inputData, loaddata, typeDoc],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      prepareLoadData();
    },
    [prepareLoadData],
  );

  const [openMainPartnerModal, setOpenMainPartnerModal] = useState(false);

  const handleMainPartnerModal = (): any => {
    setOpenMainPartnerModal(true);
  };
  const loadUserAccount = useCallback(async (doc: string, account) => {
    setLoading(true);

    try {
      const { data } = await api.get(`/admin/pj/info/${doc}`, {
        headers: {
          account,
        },
      });
      setRowActive(data.acc_id);
      setActiveAccounts(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setRowActive('');
      console.log(err);
      notification.error({
        message: 'Houve um erro na busca dos detalhes da conta.',
      });
    }
  }, []);

  const SetStatusPartner: any = useCallback((status: string) => {
    let resp;
    switch (status) {
      case 'INVITED': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="yellow" />
          </div>
        );
        break;
      }
      case 'CREATING': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="yellow" />
          </div>
        );
        break;
      }
      case 'ACCOUNT CREATED': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="yellow" />
          </div>
        );
        break;
      }
      case 'PARTNER ACCOUNT DENIED': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="red" />
          </div>
        );
        break;
      }
      case 'PARTNER ACCOUNT ACCEPT': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="green" />
          </div>
        );
        break;
      }
      case 'INVALID STATUS': {
        resp = (
          <div
            style={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <FaCircle color="red" />
          </div>
        );
        break;
      }
      default: {
        resp = <></>;
        break;
      }
    }
    return resp;
  }, []);
  const loadPartner: any = useCallback(
    async (u_id: string, a_id: string) => {
      try {
        const { data } = await bank.get('/admin/partner', {
          headers: {
            user: u_id,
            account: a_id,
            admintoken: localStorage.getItem('@BaasQesh:token'),
          },
        });
        setAwaitStatusPartner(
          // eslint-disable-next-line no-unneeded-ternary
          (data.partners
            .map((p: any) => {
              return p.status === 'PARTNER ACCOUNT ACCEPT';
            })
            .find((p: any) => p === false) as never) === false
            ? false
            : true,
        );
        setPartnerActive(data.partners);
      } catch (error) {
        setAwaitStatusPartner(false);
        setPartnerActive([]);
        console.log(error);
      }
    },
    [activeAccounts, rowActive],
  );

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h1>Análise conta PJ</h1>
            <h2>Utilize o filtro para pesquisar</h2>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            style={{ marginBottom: '5px' }}
          >
            <SelectInput
              defaultValue="CNPJ"
              onChange={e => {
                setFilter(String(e));
                setInputData('');
              }}
            >
              <SelectInput.Option value="u.document">CNPJ</SelectInput.Option>
              <SelectInput.Option value="u.name">NOME</SelectInput.Option>
              <SelectInput.Option value="u.bank">BANCO</SelectInput.Option>
            </SelectInput>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px', marginLeft: '5px' }}
          >
            <RawInput
              placeholder="Buscar"
              value={
                filter === 'u.document'
                  ? documentMaskCNPJ(inputData).substring(0, 18)
                  : inputData
              }
              onChange={e => {
                if (filter === 'u.document') {
                  setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                } else {
                  setInputData(e.target.value);
                }
              }}
            />
          </Col>
          <Col
            style={{ marginLeft: '5px' }}
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
          <Col
            xs={{ span: 2, offset: 2 }}
            sm={{ span: 2, offset: 2 }}
            md={{ span: 2, offset: 2 }}
            lg={{ span: 2, offset: 5 }}
            style={{ marginTop: '5px' }}
          >
            <Button
              htmlType="button"
              type="dashed"
              onClick={() => {
                setInputData('');
                setTypeDoc('');
              }}
            >
              Limpar Filtros
            </Button>
          </Col>
        </Row>
      </form>

      <Row
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: 30,
        }}
      >
        <Tag
          style={{
            fontWeight: 700,
            marginRight: 10,
            fontSize: 18,
            height: 25,
            opacity: `${typeDoc === allow ? 1 : 0.6}`,
          }}
          color="green"
          onClick={() => {
            if (typeDoc === allow) {
              setTypeDoc('');
            } else {
              setTypeDoc(allow);
            }
          }}
        >
          Aprovados
        </Tag>
        <Tag
          style={{
            fontWeight: 700,
            marginRight: 10,
            fontSize: 18,
            height: 25,
            opacity: `${typeDoc === analysis ? 1 : 0.6}`,
          }}
          color="yellow"
          onClick={() => {
            if (typeDoc === analysis) {
              setTypeDoc('');
            } else {
              setTypeDoc(analysis);
            }
          }}
        >
          Em análise
        </Tag>
        <Tag
          style={{
            fontWeight: 700,
            marginRight: 10,
            fontSize: 18,
            height: 25,
            opacity: `${typeDoc === bloc ? 1 : 0.6}`,
          }}
          color="red"
          onClick={() => {
            if (typeDoc === bloc) {
              setTypeDoc('');
            } else {
              setTypeDoc(bloc);
            }
          }}
        >
          Negados
        </Tag>
      </Row>

      <ICol>
        <Table
          dataSource={accounts}
          pagination={false}
          rowKey="acc_id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={7}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: () => (
              <div>
                <Row>
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <b>Dados da empresa</b>
                    </Row>
                    <Row>
                      <b>Razão social:&nbsp;</b> {activeAccounts?.name}
                    </Row>
                    <Row>
                      <b>CNPJ:&nbsp;</b>
                      {documentMask(activeAccounts?.document)}
                    </Row>
                    <Row>
                      <b>Nome fantasia:&nbsp;</b> {activeAccounts?.trading_name}
                    </Row>
                    <Row>
                      <b>Capital social:&nbsp;</b>
                      {activeAccounts?.share_capital}
                    </Row>
                    <Row>
                      <b>Número de funcionários:&nbsp;</b>
                      {activeAccounts?.number_of_employees}
                    </Row>
                    <Row>
                      <b>Faturamento anual:&nbsp;</b>
                      {HandleFormatMoney(activeAccounts?.revenues)}
                    </Row>
                    <Row>
                      <b>Número de sócios:&nbsp;</b>
                      {activeAccounts?.number_of_partners}
                    </Row>
                    <Row>
                      <b>CNAE:&nbsp;</b>
                      {activeAccounts?.cnae}
                    </Row>
                    <Row>
                      <b>Quantidade de filiais:&nbsp;</b>
                      {activeAccounts?.number_of_affiliates}
                    </Row>
                    {activeAccounts?.declaration_number && (
                      <Row>
                        <b>Número da declaração:&nbsp;</b>
                        {activeAccounts?.declaration_number}
                      </Row>
                    )}
                  </Col>
                  <Col className="bar" />
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <b>Endereço da empresa</b>
                    </Row>
                    <Row>
                      <b>CEP:&nbsp;</b> {activeAccounts?.zip_code}
                    </Row>
                    <Row>
                      <b>Endereço:&nbsp;</b> {activeAccounts?.street}
                    </Row>
                    <Row>
                      <b>Bairro:&nbsp;</b> {activeAccounts?.neighborhood}
                    </Row>
                    <Row>
                      <b>Número:&nbsp;</b> {activeAccounts?.number}
                    </Row>
                    <Row>
                      <b>Complemento:&nbsp;</b> {activeAccounts?.complement}
                    </Row>
                    <Row>
                      <b>Estado:&nbsp;</b> {activeAccounts?.state}
                    </Row>
                    <Row>
                      <b>Cidade:&nbsp;</b> {activeAccounts?.city}
                    </Row>
                  </Col>
                  <Col className="bar" />
                  <Col>
                    <Row style={{ marginBottom: 10 }}>
                      <b>Dados dos Sócios</b>
                    </Row>
                    {activeAccounts?.partners?.map(e => (
                      <Row style={{ justifyContent: 'space-between' }}>
                        <Col>
                          <Row>
                            <b>{e.name}</b>
                            {partnerActive.map((o: any) => {
                              return (
                                o.document === e.document &&
                                o.mainPartner && (
                                  <b
                                    style={{
                                      color: '#42C1C7',
                                      paddingLeft: '8px',
                                    }}
                                  >
                                    {' (sócio principal)'}
                                  </b>
                                )
                              );
                            })}
                          </Row>
                          <Row style={{ marginTop: '-5px', marginBottom: 3 }}>
                            {documentMask(e.document)}
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {partnerActive.map((o: any) => {
                            return (
                              o.document === e.document && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {SetStatusPartner(o.status)}
                                </div>
                              )
                            );
                          })}
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      {partnerActive.length > 1 && (
                        <ChoseMainPartner
                          onClick={() => handleMainPartnerModal()}
                        >
                          Escolher sócio principal
                          <FaAngleRight color="#521AA3" />
                        </ChoseMainPartner>
                      )}
                    </Row>
                  </Col>
                  <Col style={{ marginLeft: 50 }}>
                    <Row style={{ marginBottom: 20 }}>
                      <b>Contrato Social</b>
                    </Row>
                    <Row>
                      {activeAccounts?.url?.length > 0 &&
                        activeAccounts?.url.map(
                          (link: string, index: number) => (
                            <Col style={{ marginRight: 10 }}>
                              <Tag
                                onClick={(e: any) => {
                                  setIndexImagen(index);
                                  if (link.includes('pdf')) {
                                    window.open(
                                      link,
                                      '_blank',
                                      'noopener,noreferrer',
                                    );
                                  } else {
                                    // eslint-disable-next-line no-lonely-if
                                    if (loadingImage) {
                                      setOpenImagensModal(true);
                                    } else {
                                      notification.error({
                                        message: 'Erro ao carregar o arquivo',
                                      });
                                    }
                                  }
                                }}
                                style={{
                                  borderStyle: 'hidden',
                                  boxShadow: 'none',
                                  backgroundColor: 'transparent',
                                  marginBottom: 50,
                                }}
                              >
                                {link.includes('pdf') ? (
                                  <>
                                    <Col>
                                      <Row
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <FaFilePdf size={75} />
                                      </Row>
                                      <Row
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <h6>
                                          {`${activeAccounts?.name}_${index}`}
                                        </h6>
                                      </Row>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={link}
                                      alt="not found"
                                      height={100}
                                      onLoad={(e: any) => {
                                        setLoadingImage(true);
                                      }}
                                      onError={() => {
                                        setLoadingImage(false);
                                      }}
                                    />
                                  </>
                                )}
                              </Tag>
                            </Col>
                          ),
                        )}
                    </Row>
                  </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {awaitStatusPartner ? (
                    <>
                      {activeAccounts?.detail?.length > 0 && (
                        <Button
                          onClick={() => {
                            setOpenJustificationModal(true);
                          }}
                          type="primary"
                          style={{
                            borderRadius: 10,
                            background: '#42C1C7',
                            borderWidth: 0,
                            marginRight: 10,
                          }}
                        >
                          Conferir justificativa
                        </Button>
                      )}
                      {activeAccounts?.state !== 3 &&
                        !activeAccounts?.acc_active && (
                          // eslint-disable-next-line react/jsx-indent
                          <>
                            <Button
                              onClick={() => {
                                setOpenAllowBlockModal(true);
                                setStatusBtn(true);
                              }}
                              type="primary"
                              style={{
                                borderRadius: 10,
                                background: '#24A148',
                                borderWidth: 0,
                                color: 'white',
                              }}
                            >
                              <FaCheck style={{ marginRight: 10 }} /> Aprovar
                            </Button>
                          </>
                        )}
                      {activeAccounts?.state !== 4 &&
                        !activeAccounts?.user_blocked &&
                        activeAccounts?.acc_active && (
                          // eslint-disable-next-line react/jsx-indent
                          <div
                            style={{
                              marginLeft: `${
                                activeAccounts?.pending_approval
                                  ? '10px'
                                  : '0px'
                              }`,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setOpenAllowBlockModal(true);
                                setStatusBtn(false);
                              }}
                              style={{
                                borderRadius: 10,
                                background: '#DA1E28',
                                color: 'white',
                                borderWidth: 0,
                              }}
                            >
                              <FaTimes style={{ marginRight: 10 }} />
                              Negar
                            </Button>
                          </div>
                        )}
                    </>
                  ) : (
                    <>
                      <Tag color="yellow" style={{ color: '#9E9600' }}>
                        Cadastro dos socios incompleto
                      </Tag>
                    </>
                  )}
                </Row>
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    setPartnerActive([]);
                    setAwaitStatusPartner(false);
                    onExpand(record, e);
                  }}
                />
              ) : (
                <DownCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadUserAccount(record.document, record.acc_id);
                    loadPartner(record.id, record.acc_id);
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        >
          <Column
            title="Nome"
            dataIndex="user_name"
            key="user_name"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Documento"
            dataIndex="document"
            key="document"
            width={160}
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => documentMask(text)}
          />
          <Column
            title="N° da conta"
            dataIndex="bank_account"
            key="bank_account"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Banco"
            dataIndex="name"
            key="name"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="Criado em"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
            }}
          />
          <Column
            title="Status"
            dataIndex="states_documents"
            key="states_documents"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record: IPJ) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {record.client_approval_pending ? (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="yellow"
                    >
                      Documento em análise
                    </Tag>
                  ) : record.user_status === 'DENIED' && record.user_blocked ? (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="red"
                    >
                      Documento reprovado
                    </Tag>
                  ) : (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="green"
                    >
                      Documento aprovado
                    </Tag>
                  )}
                </div>
              );
            }}
          />
        </Table>
      </ICol>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              prepareLoadData(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages - 1 && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                prepareLoadData(currentPage + 1);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>

      <AllowBusinessAccountModal
        visible={openAllowBlockModal}
        hide={() => {
          setOpenAllowBlockModal(false);
          setReload(true);
        }}
        type={activeAccounts?.pending_approval}
        id={activeAccounts?.user_id}
        account_id={activeAccounts.acc_id}
        partners={activeAccounts.partners}
        statusBtn={statusBtn}
      />
      <JustificationModal
        visible={openJustificationModal}
        hide={() => {
          setOpenJustificationModal(false);
        }}
        imagens={activeAccounts?.additional_image || []}
        details={activeAccounts?.detail}
      />
      <ImagensModal
        visible={openImagensModal}
        hide={() => {
          setOpenImagensModal(false);
        }}
        img={activeAccounts?.url}
        index={indexImagen}
      />

      <ChoseMainPartnerModal
        visible={openMainPartnerModal}
        setVisible={setOpenMainPartnerModal}
        partners={activeAccounts?.partners}
        accountId={activeAccounts?.acc_id}
        userId={activeAccounts?.user_id}
        reload={reload}
        setReload={setReload}
      />
    </Container>
  );
};
export default BusinessAccount;

import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Menu, notification, Skeleton } from 'antd';

import api from '../../../../services/api';
import bank from '../../../../services/bank_api';

import { HandleFormatMoney } from '../../../../services/utils';

import { RowGutter, Col } from '../../../../styles/components';
import { Container, Content, TopSidebar, Avatar } from './styles';
import { useAuth } from '../../../../hooks/auth';

import ModalCancelAccount from '../../../../components/Modals/ModalCancelAccount/index';

import Personal from '../menus/Personal';
import Extract from '../menus/Extract';
import Fees from '../menus/Fees';
import Transactions from '../menus/Transactions';
import Settings from '../menus/Settings';
import SearchPage from '../menus/Search';

interface PropsParams {
  account_id: string;
}

export interface PropsAccount {
  id: string;
  active: boolean;
  bank_account: string;
  bank_branch: string;
  bank_code: string;
  classify: string;
  client_approval_pending: boolean;
  is_pool: boolean;
  limit_payment: number;
  limit_ted: number;
  suspended_at?: Date;
  alias?: string;
  pix_receivement_fee: number | null;
  monthly_payment: number | null;
  qeshemployee: boolean;
  balance: {
    balance_available: number;
    updated: Date;
  };
  user: {
    id: string;
    name: string;
    document: string;
    blocked: boolean;
    email: string;
    phone: string;
    status: {
      name: string;
    };
    bank: {
      name: string;
      manual_approval?: boolean;
    };
  };
}

export interface PropsDocument {
  id: string;
  name: string;
  original_name: string;
  category: string;
  created_at: Date;
  urlDoc?: string;
}
interface IPartners {
  name: string;
  document: string;
  qualification: string;
  email: string;
}
export interface PropsUserAccount {
  user: {
    id: string;
    name: string;
    social_name: string;
    document: string;
    blocked: boolean;
    email: string;
    phone: string;
    status: {
      name: string;
    };
    pending_approval?: boolean;
    bank: {
      name: string;
    };
    created_at: Date;
  };
  additional: {
    birth_date: string;
    opening_date: string;
    mother_name: string;
    education: string;
    share_capital: string;
    number_of_affiliates?: string;
    cnae?: string;
    declaration_number: string;
    isPEP?: boolean;
    monthly_income: string;
    nationality: string;
    occupation: string;
    patrimony: string;
    legal_nature: string;
    pronoun: string;
    relationship: string;
    revenues: string;
    trading_name: string;
    number_of_employees: string;
    number_of_partners: string;
    partners: IPartners[];
  };
  address: {
    id: string;
    street: string;
    number: string;
    neighborhood: string;
    complement?: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
  };
  documents: Array<PropsDocument>;
  generalConditions?: { [key: string]: string };
}

const Detail: React.FC = () => {
  const { account_id } = useParams<PropsParams>();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState({} as PropsAccount);
  const [userAccount, setUserAccount] = useState({} as PropsUserAccount);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const { signOut, admin } = useAuth();

  const loadAccountInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/accounts/details`, {
        headers: {
          account: account_id,
        },
      });

      setAccount(data);

      const { data: user } = await api.get(`/admin/users/details`, {
        headers: {
          user: data.user.id,
        },
      });

      setUserAccount(user);
      setLoading(false);
    } catch (err: any) {
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });
        signOut();
      } else {
        notification.error({
          message: 'Não foi possível recuperar os dados da conta informada.',
        });
      }
      setLoading(false);
    }
  }, [account_id, signOut]);

  const [type, setType] = useState('personal_data');

  useEffect(() => {
    loadAccountInfo();
  }, [loadAccountInfo, account_id]);

  const onDelete = useCallback(async () => {
    try {
      const { data } = await api.get(`/admin/accounts/details`, {
        headers: {
          account: account_id,
        },
      });

      setAccount(data);
      const { data: user } = await api.get(`/admin/users/details`, {
        headers: {
          user: data.user.id,
        },
      });

      setUserAccount(user);
    } catch (err: any) {
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });
        signOut();
      } else {
        notification.error({
          message: 'Não foi possível recuperar os dados da conta informada.',
        });
      }
    }
  }, [account_id, signOut]);

  const handleBlockUnblockAccount = useCallback(() => {
    const token = localStorage.getItem('@BaasQesh:token');
    bank
      .put(
        '/admin/security/approval',
        {},
        {
          headers: {
            user: account.user.id,
            account: account_id,
            pending: !userAccount.user.pending_approval,
            admintoken: token,
          },
        },
      )
      .then(() => {
        notification.success({ message: 'Sucesso.' });
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((erro: any) => {
        if (erro?.response?.data?.message?.message) {
          notification.error({
            message: 'Pessoa esta registrada na Blacklist',
          });
        } else if (
          erro?.response?.data?.message?.includes(
            'It missing data to activate an account',
          )
        ) {
          notification.error({
            message: 'Este usuario não completou o cadastro',
            description: `Esta faltando informações sobre '${
              erro?.response?.data?.message.split('-')[1]
            }'`,
          });
        } else {
          notification.error({ message: 'Erro ao aprovar/bloquear' });
        }
      });
  }, [userAccount, account_id]);

  const handleCancelAccount = (): void => {
    const token = localStorage.getItem('@BaasQesh:token');

    api
      .patch(
        `admin/accounts/cancel/${account_id}`,
        {
          reason: cancelReason,
        },
        {
          headers: {
            token,
          },
        },
      )
      .then(() => {
        notification.success({ message: 'Sucesso.' });
        setTimeout(() => window.history.back(), 1000);
      })
      .catch((erro: any) => {
        notification.error({ message: 'Erro ao cancelar a conta' });
      });

    setIsCancelVisible(true);
  };

  const toogleMenu = useCallback(e => {
    switch (e.key) {
      case '1':
        setType('personal_data');
        break;

      case '2':
        setType('extract');
        break;

      case '3':
        setType('transactions');
        break;

      case '4':
        setType('fees');
        break;

      case '5':
        setType('settings');
        break;

      case '6':
        setType('search');
        break;

      default:
        setType('personal_data');
    }
  }, []);

  return (
    <Container
      style={{
        background: loading ? '#fff' : 'transparent',
        padding: loading ? '26px' : 'inheirt',
      }}
    >
      {loading ? (
        <>
          <Skeleton active={loading} avatar paragraph={{ rows: 4 }} />
          <Skeleton active={loading} paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          <h1>
            Detalhes da conta - {account?.bank_account}{' '}
            {account?.alias ? ` - ${account.alias}` : ''}
          </h1>
          <RowGutter>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
            >
              <Content>
                <TopSidebar>
                  <Avatar>
                    {
                      String(account?.user?.name)
                        .split(' ')?.[0]
                        ?.split('')?.[0]
                    }
                    {
                      String(account?.user?.name)
                        .split(' ')?.[1]
                        ?.split('')?.[0]
                    }
                  </Avatar>
                  {account?.qeshemployee ||
                  admin.email === 'markusdaniel.mkd@gmail.com' ? (
                    <></>
                  ) : (
                    <>
                      <div>Saldo</div>
                      <h3>
                        {HandleFormatMoney(account?.balance?.balance_available)}
                      </h3>
                    </>
                  )}
                </TopSidebar>

                <Menu
                  onClick={toogleMenu}
                  style={{ width: '100%', background: '#fff' }}
                  defaultSelectedKeys={['1']}
                  mode="inline"
                >
                  <Menu.Item key="1">Dados pessoais</Menu.Item>
                  {!account?.qeshemployee ||
                  admin?.id === 'd21538cb-b4f0-4553-9f6e-be5bbfeabb08' || // janaina
                  admin?.id === '41129b1b-c233-4cbd-89b4-15e9c0fc1e0f' ? ( // cristiano
                    <>
                      <Menu.Item key="2">Extrato</Menu.Item>
                      {account?.user?.bank?.name === 'Sorte Esportiva' && (
                        <Menu.Item key="6">Pesquisa</Menu.Item>
                      )}
                      <Menu.Item key="3">Transações</Menu.Item>
                    </>
                  ) : (
                    <></>
                  )}

                  <Menu.Item key="4">Tarifas e benefícios</Menu.Item>
                  {(admin?.bank.name === 'Qesh Payments' ||
                    admin?.bank.name === 'BancoMare') && (
                    <Menu.Item key="5">Ajustes</Menu.Item>
                  )}
                </Menu>
              </Content>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
            >
              {(admin?.bank.name === 'Qesh Payments' ||
                account.user.bank.manual_approval) && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    marginTop: -40,
                    marginLeft: -20,
                  }}
                >
                  <Button
                    style={{
                      marginRight: 15,
                    }}
                    /* onClick={handleCancelAccount} */
                    onClick={() => setIsCancelVisible(true)}
                  >
                    Cancelar conta
                  </Button>
                  <Button
                    onClick={handleBlockUnblockAccount}
                    // disabled={handleValidateButton()}
                  >
                    {userAccount?.user?.pending_approval
                      ? 'Aprovar usuário'
                      : 'Bloquear usuário'}
                  </Button>
                  <ModalCancelAccount
                    color="var(--error)"
                    setIsvisible={setIsCancelVisible}
                    isVisible={isCancelVisible}
                    textAreaPlaceholder="Escreva aqui o motivo que justifique o cancelamento da conta"
                    buttonText="Cancelar conta"
                    cancelReason={cancelReason}
                    setCancelReason={setCancelReason}
                    handleCancelAccount={handleCancelAccount}
                  />
                </div>
              )}
              <Content>
                {type === 'personal_data' && (
                  <Personal user={userAccount} account={account} />
                )}
                {type === 'extract' && (
                  <Extract user={userAccount} account={account} />
                )}
                {type === 'transactions' && <Transactions account={account} />}
                {type === 'fees' && (
                  <Fees user={userAccount} account={account} />
                )}
                {type === 'settings' && (
                  <Settings
                    onDelete={onDelete}
                    user={userAccount}
                    account={account}
                  />
                )}
                {type === 'search' && (
                  <SearchPage user={userAccount} account={account} />
                )}
              </Content>
            </Col>
          </RowGutter>
        </>
      )}
    </Container>
  );
};

export default Detail;

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';

import { Button, Table, Tag, notification, Col, Row } from 'antd';

import { format, parseISO } from 'date-fns';

import {
  SearchOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { FaPlus, FaCheck, FaFilePdf } from 'react-icons/fa';

import Axios from 'axios';

import { decode as decodeSTR } from 'html-entities';
import { Container, Pagination, ICol } from './styles';

import { RawInput, SelectInput } from '../../styles/components';

import api from '../../services/api';
import bank from '../../services/bank_api';

import { documentMask } from '../../services/utils';

import { useAuth } from '../../hooks/auth';
import AddDocumentBlackListModal from './Modal/AddDocumentBlackListModal';
import AllowBlockAccontModal from './Modal/AllowBlockAccontModal';
import ModalImagens from './Modal/ImagensModal';

const { Column } = Table;
interface ISaffeOcr {
  birthdate?: string;
  parents?: string;
  name?: string;
  cpf?: string;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

interface IBlackList {
  id: string;
  client_id: string;
  document: string;
  user_name: string;
  blacklisted: boolean;
  created_at: Date;
  pc_id: string;
  rule_id: string;
  status: string;
}
interface IRules {
  blacklisted?: boolean;
  created_at?: Date;
  data?: string;
  description?: Record<string, IDescription> | string | [];
  id?: number;
  pid?: string;
  rule_id?: string;
  rule_scope?: string;
  status?: string;
  updated_at?: Date;
}

interface IDescription {
  motherName?: string;
  birthData?: string;
  document?: string;
  frontDoc?: string;
  self?: string;
  email?: string;
}

interface ILink {
  index: number;
  link: string;
  type: string;
}

const BlackList: React.FC = () => {
  const [accounts, setAccounts] = useState([] as Array<IBlackList>);
  const [activeAccounts, setActiveAccounts] = useState([] as Array<IRules>);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [filter, setFilter] = useState('u.document');
  const [currentPage, setCurrentPage] = useState(0);
  const [inputData, setInputData] = useState('');
  const { signOut, banks } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingActiveAccounts, setLoadingActiveAccounts] = useState(false);

  const [rowActive, setRowActive] = useState('');
  const [subrowActive, setSubRowActive] = useState('');
  const [openNewBlockModal, setOpenNewBlockModal] = useState(false);
  const [openAllowBlockModal, setOpenAllowBlockModal] = useState(false);
  const [openImagensModal, setOpenImagensModal] = useState(false);
  const [modalImagens, setModalImagens] = useState([]);
  const [reload, setReload] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const [doc, setDocument] = useState('');
  const [client, setClient] = useState('');

  const [imagesWithError, setImagesWithError] = useState([] as number[]);

  const [indexImagen, setIndexImagen] = useState(0);

  const loadData = useCallback(
    async (page = 0, filters = '') => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/admin/blacklist/?page=${page}${filters}`,
          {},
        );
        setAccounts(
          data.items.map((item: IBlackList) => {
            return { ...item, key: item.pc_id };
          }),
        );
        setMetaData({
          current_page: data.current_page,
          total_pages: data.total_pages,
          total_items: data.total_items,
          total_items_page: data.total_items_page,
        });
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar a lista de contas',
          });
        }
      }
    },
    [signOut],
  );

  const prepareLoadData = useCallback(
    (page = 0) => {
      let finput = '';
      if (
        inputData === '' ||
        inputData === undefined ||
        inputData === null ||
        !inputData
      ) {
        finput = '';
      } else if (filter === 'u.bank') {
        finput = `&&bank=${inputData}`;
      } else {
        finput = `${
          filter === 'u.document'
            ? `&&doc=${inputData.replace(/[/,.,-]/g, '')}`
            : `&&name=${inputData}`
        }`;
      }
      const ff = finput;
      if (ff === '' || ff === undefined || ff === null || !ff) {
        loadData(page || 0);
      } else {
        loadData(page || 0, ff);
      }
    },
    [filter, inputData, loadData],
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      prepareLoadData();
    },
    [prepareLoadData],
  );

  const loadUserAccount = useCallback(
    async (user_id: string, pc_id: string) => {
      setRowActive(pc_id);
      setActiveAccounts([]);
      setLoading(true);
      setImagesWithError([]);
      setModalImagens([]);

      try {
        const { data } = await api.get(`/admin/blacklist/show/${pc_id}`, {});
        setActiveAccounts(data);
        setLoadingActiveAccounts(false);
        setLoading(false);
      } catch (err) {
        setLoadingActiveAccounts(false);
        setRowActive('');
        setLoading(false);
      }
    },
    [],
  );

  const ModalAllow = useCallback(() => {
    return (
      <AllowBlockAccontModal
        visible={openAllowBlockModal}
        hide={() => {
          setOpenAllowBlockModal(false);
          setReload(true);
          setDocument('');
        }}
        id={(activeAccounts as any)?.pid}
        doc={doc}
        clientId={client}
      />
    );
  }, [doc, activeAccounts, openAllowBlockModal, client]);

  const ModalImg = useCallback(() => {
    return (
      <>
        <ModalImagens
          visible={openImagensModal}
          hide={() => {
            setModalImagens([]);
            setIndexImagen(-1);
            setOpenImagensModal(false);
          }}
          img={modalImagens}
          index={indexImagen}
        />
      </>
    );
  }, [
    openImagensModal,
    modalImagens,
    indexImagen,
    rowActive,
    imagesWithError,
    activeAccounts,
  ]);

  const onClickImageEvent = useCallback(
    async (link: ILink) => {
      if (!imagesWithError.includes(link.index)) {
        setIndexImagen(link.index);
        if (link.link.includes('pdf')) {
          window.open(link.link, '_blank', 'noopener,noreferrer');
        } else {
          setOpenImagensModal(true);
        }
      }
    },
    [imagesWithError, activeAccounts, modalImagens],
  );

  const ShowImage: any = useCallback(
    (props: ILink) => {
      return (
        <Tag
          onClick={() => onClickImageEvent(props)}
          style={{
            borderStyle: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            marginBottom: 50,
          }}
        >
          {props.link.includes('pdf') ? (
            <>
              <Col>
                <Row>
                  <FaFilePdf size={75} />
                </Row>
              </Col>
            </>
          ) : (
            <>
              <img
                src={props.link}
                alt={`not found ${props.type}`}
                height={100}
                onError={() => {
                  if (!imagesWithError.includes(props.index))
                    setImagesWithError([...imagesWithError, props.index]);
                }}
              />
              <h6>{props.type}</h6>
            </>
          )}
        </Tag>
      );
    },
    [onClickImageEvent, imagesWithError, activeAccounts],
  );

  const RuleChange: any = () => {
    let response = <></>;
    const op: any = activeAccounts as IRules;
    const getvalue: any = (op1: any, op2: any, val: any) => {
      const resp: any = op1.filter((e: any) => {
        return e.id === op2.pid;
      });
      if (resp === undefined || resp.length < 1 || !resp) return resp;
      return resp[0][val];
    };
    const getDataValue: any = (data: any) => {
      if (data === null || data === undefined || JSON.parse(String(data)) < 1)
        return data;
      if (JSON.parse(String(data)).length > 0)
        if (JSON.parse(String(data)).length === 1)
          return JSON.parse(String(data));
        else return JSON.parse(String(data));
      return JSON.parse(String(data));
    };

    switch (op?.rule_id) {
      case 'HasOFACData': {
        if (
          op?.status === 'APPROVED' &&
          op?.blacklisted === false &&
          !op?.data
        ) {
          response = <> Sem historico Criminal</>;
          break;
        }
        response = (
          <div>
            {op?.data.includes('ArrestWarrantNumber') && (
              <Row>
                <Table
                  dataSource={getDataValue(op?.data)?.map((ele: any) => {
                    return Object.assign(ele, {
                      key: ele?.Details?.ArrestWarrantNumber,
                    });
                  })}
                  pagination={false}
                  rowKey={getDataValue(op?.data)?.Details?.ArrestWarrantNumber}
                  loading={loading}
                  locale={{
                    emptyText: 'Nenhum usuário para mostrar.',
                  }}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                  scroll={{ x: true }}
                  expandIconColumnIndex={5}
                  expandRowByClick={false}
                  expandedRowKeys={[subrowActive]}
                  expandable={{
                    expandedRowRender: (record: any) => (
                      <div key="expandedRowRender">
                        <div>
                          <Row>
                            Número do mandado de prisão :&nbsp;
                            {record?.Details?.ArrestWarrantNumber}
                          </Row>
                          <Row>Município: {record?.Details?.County}</Row>
                          <Row>Estado: {record?.Details?.State}</Row>
                          <Row>
                            Regime:&nbsp;
                            {record?.Details?.PrisonRegime}
                          </Row>
                          <Row>Fonte: {record?.Source}</Row>
                          <Row>Tipo: {record?.Type}</Row>
                          <Row>Atuação: {record?.Details?.Agency}</Row>
                          <Row>Decisão: {record?.Details?.Decision}</Row>
                        </div>
                      </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <UpCircleOutlined
                          style={{
                            fontSize: 21,
                            color: '#333',
                          }}
                          onClick={e => {
                            setSubRowActive('');
                            onExpand(record, e);
                          }}
                        />
                      ) : (
                        <DownCircleOutlined
                          style={{
                            fontSize: 21,
                            color: '#333',
                          }}
                          onClick={e => {
                            const idkey = (record as any).Details
                              .ArrestWarrantNumber;
                            setSubRowActive(idkey);
                            onExpand(idkey, e);
                          }}
                        />
                      ),
                  }}
                >
                  <Column
                    title="Numero do Processo"
                    dataIndex="Details.ProcessNumber"
                    key="Details.ProcessNumber"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record: any, index) => {
                      return record.Details.ProcessNumber;
                    }}
                  />
                  <Column
                    title="Data de Inicio"
                    dataIndex="StartDate"
                    key="StartDate"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={text => {
                      return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
                    }}
                  />
                  <Column
                    title="Ultima Atualização"
                    dataIndex="LastUpdateDate"
                    key="LastUpdateDate"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={text => {
                      return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
                    }}
                  />
                  <Column
                    title="Magistrado"
                    dataIndex="Details.Magistrate"
                    key="Details.Magistrate"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record: any, index) => {
                      return record.Details.Magistrate;
                    }}
                  />
                  <Column
                    title="Tempo de penalidade "
                    dataIndex="Details.PenaltyTime"
                    key="Details.PenaltyTime"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record: any, index) => {
                      return record.Details.PenaltyTime;
                    }}
                  />
                </Table>
              </Row>
            )}
            {op?.data.includes('Ibama') && (
              <Row>
                <Table
                  dataSource={[getDataValue(op?.data)]}
                  rowKey={getDataValue(op?.data)?.Details?.TadNumber}
                  pagination={false}
                  loading={loading}
                  locale={{
                    emptyText: 'Nenhum usuário para mostrar.',
                  }}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                  scroll={{ x: true }}
                  expandIconColumnIndex={6}
                  expandRowByClick={false}
                  expandedRowKeys={[subrowActive]}
                  expandable={{
                    expandedRowRender: (record: any) => (
                      <div key="expandedRowRender">
                        <div>
                          <Row>
                            Fator de Negócios BDC:&nbsp;
                            {record?.Details?.BDCBusinessFactor}
                          </Row>
                          <Row>
                            Fonte de dados: {record?.Details?.DataSource}
                          </Row>
                          <Row>
                            Área desmatada:{' '}
                            {decodeSTR(record?.Details?.DeforestedAreaHa)}
                          </Row>
                          <Row>
                            Data do Julgamento:&nbsp;
                            {record?.Details?.JudgementDate}
                          </Row>
                          <Row>
                            Município da Propriedade:{' '}
                            {decodeSTR(record?.Details?.PropertyCounty)}
                          </Row>
                          <Row>
                            Localização da Propriedade:{' '}
                            {decodeSTR(record?.Details?.PropertyLocation)}
                          </Row>
                          <Row>
                            Estado da Propriedade: {record?.Details?.PropertyUF}
                          </Row>
                          <Row>
                            Data da consulta:{' '}
                            {format(
                              parseISO(record?.Details?.QueryDate),
                              'dd/MM/yyyy',
                            )}
                          </Row>
                          <Row>
                            Número de Autoinfração:{' '}
                            {record?.Details?.SelfInfringementNumber}
                          </Row>
                          <Row>
                            Infração: {decodeSTR(record?.Details?.Infraction)}
                          </Row>
                        </div>
                      </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <UpCircleOutlined
                          style={{
                            fontSize: 21,
                            color: '#333',
                          }}
                          onClick={e => {
                            setSubRowActive('');
                            onExpand(record, e);
                          }}
                        />
                      ) : (
                        <DownCircleOutlined
                          style={{
                            fontSize: 21,
                            color: '#333',
                          }}
                          onClick={e => {
                            const idkey = (record as any).Details
                              .ArrestWarrantNumber;
                            setSubRowActive(idkey);
                            onExpand(idkey, e);
                          }}
                        />
                      ),
                  }}
                >
                  <Column
                    title="Lista de Sanções de Nome "
                    dataIndex="NameInSanctionList"
                    key="Details.NameInSanctionList"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record: any, index) => {
                      return decodeSTR(record.Details.NameInSanctionList);
                    }}
                  />
                  <Column
                    title="Tipo de Sanção Padronizado "
                    dataIndex="StandardizedSanctionType"
                    key="StandardizedSanctionType"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Tipo"
                    dataIndex="Type"
                    key="Type"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Fonte"
                    dataIndex="Source"
                    key="Source"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Data de Registro"
                    dataIndex="RegistrationDate"
                    key="Details.RegistrationDate"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record: any, index) => {
                      return record.Details.RegistrationDate;
                    }}
                  />
                </Table>
              </Row>
            )}
          </div>
        );
        break;
      }
      case 'HasCriminalHistory': {
        if (
          op?.data === null ||
          op?.data === undefined ||
          op?.data === '' ||
          !op?.data
        ) {
          response = <> Sem historico Criminal</>;
          break;
        }

        response = (
          <div>
            <Row>
              <Table
                dataSource={getDataValue(op?.data).map((ele: any) => {
                  return Object.assign(ele, {
                    key: ele?.Details?.numeroProcesso,
                  });
                })}
                pagination={false}
                rowKey={getDataValue(op?.data)?.numeroProcesso}
                loading={loading}
                locale={{
                  emptyText: 'Nenhum usuário para mostrar.',
                }}
                scroll={{ x: true }}
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '10px',
                }}
              >
                <Column
                  title="Numero do Mandado"
                  dataIndex="numeroMandado"
                  key="numeroMandado"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Numero do Processo"
                  dataIndex="numeroProcesso"
                  key="numeroProcesso"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Magistrado"
                  dataIndex="magistrado"
                  key="magistrado"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
                <Column
                  title="Data do Mandado"
                  dataIndex="dataMandado"
                  key="dataMandado"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={text => {
                    return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
                  }}
                />
                <Column
                  title="Data de Validade"
                  dataIndex="dataValidade"
                  key="dataValidade"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={text => {
                    return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
                  }}
                />
                <Column
                  title="Situacao do Mandado"
                  dataIndex="situacaoMandado"
                  key="situacaoMandado"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />
              </Table>
            </Row>
          </div>
        );
        break;
      }
      case 'HasProcessAdmin': {
        response = (
          <>
            <Row>Tipo: Processo Civil/Administrativo</Row>
            {getDataValue(op?.data) ? (
              <Row>
                Numero de processos passivos:{' '}
                {getDataValue(op?.data).qtdPartePassiva}
              </Row>
            ) : (
              <>Encontrado nenhum processo passivo</>
            )}
          </>
        );
        break;
      }
      case 'HasProcessTax': {
        let numProc;
        if (getDataValue(op?.data)?.message.includes('Process active ')) {
          numProc = getDataValue(op?.data)?.message.replace(
            'Process active ',
            '',
          );
          response = (
            <>
              <Row>Tipo: Processo Tributário</Row>
              <Row>Numero de processos passivos: {numProc}</Row>
            </>
          );
        }
        if (getDataValue(op?.data)?.message.includes('No passive processes')) {
          response = <>Sem processos passivos</>;
        }
        if (getDataValue(op?.data)?.message.includes('Not found process')) {
          response = <>Não foi encontrado nenhum processo</>;
        }
        if (
          !op?.data ||
          op?.data === undefined ||
          op?.data === null ||
          op?.data === ''
        ) {
          response = <></>;
        }
        break;
      }
      case 'IsAlreadyRegisteredAddress': {
        response = (
          <>
            <div />
            {String(op?.description).length === 17 ? (
              <Row>{op?.description}</Row>
            ) : (
              <>
                <Row>Endereço cadastrado</Row>
                <Row>Cidade: {getDataValue(op?.description).city}</Row>
                <Row>
                  Complemento: {getDataValue(op?.description).complement}
                </Row>
                <Row>País: {getDataValue(op?.description).country}</Row>
                <Row>Bairro: {getDataValue(op?.description).neighborhood}</Row>
                <Row>Numero: {getDataValue(op?.description).number}</Row>
                <Row>Estado: {getDataValue(op?.description).state}</Row>
                <Row>Rua: {getDataValue(op?.description).street}</Row>
                <Row>
                  CEP:{' '}
                  {getDataValue(op?.description).zip_code.replace(
                    /([\d]{5})*([\d]{3})/,
                    '$1-$2',
                  )}
                </Row>
              </>
            )}
          </>
        );
        break;
      }
      case 'IsDead': {
        response = (
          <>
            <Row>
              {getDataValue(op?.data)?.anoFalecimento
                ? `O Ano do Falecimento: ${
                    getDataValue(op?.data)?.anoFalecimento
                  }`
                : 'Não foi encontrado o ano do seu Falecimento'}
            </Row>
          </>
        );
        break;
      }
      case 'IsDuplicatedEmail': {
        response = (
          <>
            <Row>
              {String(op?.description).length === 15 ? (
                op?.description
              ) : (
                <>O E-mail {op?.description} ja esta em uso</>
              )}
            </Row>
          </>
        );
        break;
      }
      case 'IsOverAge': {
        response = (
          <>
            <div />
            {!op?.data ? (
              <>Menor de idade ou dados não encontrados</>
            ) : (
              <>
                <Row>
                  Data de Nascimento:&nbsp;
                  {format(
                    parseISO(getDataValue(op?.data).dataNascimento),
                    'dd/MM/yyyy',
                  )}
                </Row>
                <Row>Idade: {getDataValue(op?.data).idade}</Row>
              </>
            )}
          </>
        );
        break;
      }
      case 'IsYoungConduct': {
        response = (
          <>
            <div />
            {!op?.data ? (
              <>Menor de idade ou dados não encontrados</>
            ) : (
              <>
                <Row>
                  Data de Nascimento:&nbsp;
                  {format(
                    parseISO(getDataValue(op?.data).dataNascimento),
                    'dd/MM/yyyy',
                  )}
                </Row>
                <Row>Idade: {getDataValue(op?.data).idade}</Row>
              </>
            )}
          </>
        );
        break;
      }
      case 'IsPEP': {
        response = (
          <>
            {getDataValue(op.data) && !getDataValue(op.data).isPep ? (
              <div>
                <Row>
                  Nível Pep: {getDataValue(op?.data)?.pessoaPEPNivelPrincipal}
                </Row>
                {getDataValue(op?.data)?.orgao && (
                  <>
                    <Row>Orgão: {getDataValue(op?.data)?.orgao}</Row>
                    <Row>
                      Data de Inicio do Exercicio:{' '}
                      {format(
                        parseISO(getDataValue(op?.data)?.dataInicioExercicio),
                        'dd/MM/yyyy',
                      )}
                    </Row>
                    <Row>
                      Data do Fim do Exercicio:{' '}
                      {format(
                        parseISO(getDataValue(op?.data)?.dataFimExercicio),
                        'dd/MM/yyyy',
                      )}
                    </Row>
                    <Row>
                      Data do Fim da Carência:{' '}
                      {format(
                        parseISO(getDataValue(op?.data)?.dataFimCarencia),
                        'dd/MM/yyyy',
                      )}
                    </Row>
                  </>
                )}
                {getDataValue(op?.data)?.primarios && (
                  <>
                    <Row style={{ marginBottom: '5px' }}>Primários</Row>
                    {getDataValue(op?.data)?.primarios?.map((e: any) => {
                      return (
                        <>
                          <Row>CPF: {documentMask(e?.cpf)}</Row>
                          <Row>Nome: {e?.nome}</Row>
                          <Row>Função: {e?.funcao}</Row>
                          <Row style={{ marginBottom: '5px' }}>
                            Relação: {e?.relacao}
                          </Row>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            ) : (
              <>A pessoa não é considerada politicamente expostas</>
            )}
          </>
        );
        break;
      }
      case 'IsValidCNPJ': {
        response = (
          <>
            <Row>
              {op?.status === 'REFUSED' ? 'CNPJ invalido' : 'CNPJ invalido'}(
            </Row>
          </>
        );
        break;
      }
      case 'IsValidCPF': {
        response = (
          <>
            {op?.status === 'REFUSED' ? (
              <>
                {!!Array.isArray(op.data) &&
                op?.data.includes('Match Porcentage') ? (
                  <>
                    <Row>
                      Erro na validação do nome, correspondência deve ser maior
                      de 60%
                    </Row>
                    <Row>
                      Correspondência{' '}
                      {
                        getDataValue(op?.data).message?.split(
                          'Match Porcentage',
                        )[1]
                      }
                    </Row>
                  </>
                ) : (
                  <Row>CPF invalido</Row>
                )}
              </>
            ) : (
              <Row>CPF Valido</Row>
            )}
          </>
        );
        break;
      }
      case 'IsValidDocument': {
        if (typeof op?.description?.document !== 'string') {
          setModalImagens(op?.description?.document);
        }

        const objMatch =
          typeof getDataValue(op?.data) === 'string'
            ? JSON.parse(getDataValue(op?.data))?.detectbigid?.CheckInfo
            : getDataValue(op?.data)?.detectbigid?.CheckInfo;

        let ocrData = op.data.replace(/\\/g, '');
        ocrData = JSON.parse(ocrData);
        const obj: ISaffeOcr = {};
        !!Array.isArray(ocrData) &&
          ocrData.forEach((item: Record<keyof ISaffeOcr, string>) => {
            const key = Object.keys(item)[0] as keyof ISaffeOcr;
            const value = item[key];
            obj[key] = value;
          });

        const match = [
          objMatch?.BIRTHDATEMATCH < 0.95 || obj?.birthdate,
          objMatch?.DOCTYPEMATCH < 0.8,
          objMatch?.MOTHERNAMEMATCH < 0.6 || obj?.parents,
          objMatch?.NAMEMATCH < 0.95 || obj?.name,
          objMatch?.SIDEMATCH < 0.8,
          obj?.cpf,
        ];

        response = (
          <div>
            <Row>Comparação entre as infomações do cadastro e o documento</Row>
            <Row style={{ marginTop: 15 }}>
              <Col style={{ marginRight: 20 }}>
                <Row>Nome: {getvalue(accounts, op, 'user_name')}</Row>
                <Row>
                  Documento: {documentMask(getvalue(accounts, op, 'document'))}
                </Row>
                <Row>Nome de Mãe: {op?.description?.motherName}</Row>
                <Row>Data de Nascimento: {op?.description?.birthData}</Row>
              </Col>
              <Col>
                <Row>
                  {op?.description?.document?.length === 18 &&
                  typeof op?.description?.document === 'string' ? (
                    <>{op?.description?.document}</>
                  ) : (
                    <>
                      <Row>
                        {(op?.description?.document as Array<any>).map(
                          ({ key: type, value: link }, index: number) => (
                            <Col style={{ marginRight: 10 }}>
                              <ShowImage
                                link={link}
                                index={index}
                                type={type}
                              />
                            </Col>
                          ),
                        )}
                      </Row>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            {match.find(elem => elem) && (
              <Row style={{ display: 'contents' }}>
                <Row>Dados que acusou erro:</Row>
                <Row>{match[0] && '- Data de nascimento'}</Row>
                <Row>{match[1] && '- Tipo do documento'}</Row>
                <Row>{match[2] && '- Nome da mãe'}</Row>
                <Row>{match[3] && '- Nome'}</Row>
                <Row>{match[4] && '- Lado'}</Row>
                <Row>{match[5] && '- CPF não validado'}</Row>
              </Row>
            )}
          </div>
        );
        break;
      }
      case 'IsValidDocumentPicture': {
        if (
          typeof op?.description?.frontDoc !== 'string' &&
          modalImagens.length <= 1
        ) {
          modalImagens.push(op?.description?.frontDoc as never);
        }
        if (
          typeof op?.description?.self !== 'string' &&
          modalImagens.length <= 1
        ) {
          modalImagens.push(op.description.self as never);
        }
        response = (
          <div>
            <Row>Comparação entre a foto do documento e self</Row>
            <Row style={{ marginTop: '5px', marginBottom: '5px' }}>
              {getDataValue(op?.data)?.message?.search(
                /(similarity)|(confidence)/i,
              ) !== -1 && (
                <Row>
                  {getDataValue(op?.data)?.message?.search(/(similarity)/i) !==
                  -1
                    ? `Similaridade de ${
                        getDataValue(op?.data)
                          ?.message?.split(',')[1]
                          ?.split('index')[1]
                      } % com a Confiança de ${
                        getDataValue(op?.data)
                          ?.message?.split(',')[2]
                          ?.split('match')[1]
                      } %`
                    : `Sem similaridade com a Confiança de ${
                        getDataValue(op?.data)
                          ?.message?.split(',')[1]
                          ?.split('unmatch')[1]
                      } %`}
                </Row>
              )}
            </Row>
            <Row>
              <Col>
                {op?.description?.frontDoc?.length === 18 &&
                typeof op?.description?.frontDoc === 'string' ? (
                  <>{op?.description?.frontDoc}</>
                ) : (
                  <div>
                    <Tag
                      onClick={() => {
                        setIndexImagen(0);
                        onClickImageEvent({
                          link: op?.description?.frontDoc?.value,
                          index: 0,
                          type: op?.description?.frontDoc?.key,
                        });
                      }}
                      style={{
                        borderStyle: 'hidden',
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        marginBottom: 50,
                      }}
                    >
                      <img
                        src={op?.description?.frontDoc?.value}
                        alt={`not found ${op?.description?.frontDoc?.key}`}
                        height={100}
                        onError={async () => {
                          if (!imagesWithError.includes(0))
                            setImagesWithError([...imagesWithError, 0]);
                        }}
                      />
                    </Tag>
                  </div>
                )}
              </Col>
              <Col>
                {op?.description?.self?.length === 14 &&
                typeof op?.description?.self === 'string' ? (
                  <>{op?.description?.self}</>
                ) : (
                  <div>
                    <Button
                      onClick={() => {
                        setIndexImagen(1);
                        onClickImageEvent({
                          link: op?.description?.frontDoc?.value,
                          index: 1,
                          type: op?.description?.frontDoc?.key,
                        });
                      }}
                      style={{
                        borderStyle: 'hidden',
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        marginBottom: 50,
                      }}
                    >
                      <img
                        src={op?.description?.self?.value}
                        alt={`not found ${op?.description?.self?.key}`}
                        height={100}
                        onError={() => {
                          if (!imagesWithError.includes(0))
                            setImagesWithError([...imagesWithError, 1]);
                        }}
                      />
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        );
        break;
      }
      case 'IsValidEmail': {
        response = (
          <>
            <Row>
              {String(op?.description).length === 15 ? (
                op?.description
              ) : (
                <>Esta sendo usado este E-mail : {op?.description}</>
              )}
            </Row>
          </>
        );
        break;
      }
      default: {
        if (op?.rule_scope === 'MANUAL') {
          if (op?.description.file && rowActive && modalImagens.length === 0) {
            const n = op?.description?.file.map((e: any, i: number) => {
              // eslint-disable-next-line prefer-object-spread
              return Object.assign({
                value: e,
                key: `untitled_${i}`,
              });
            });
            setModalImagens(n);
          }
          response = (
            <div>
              <Row>Criado por: {op?.uid_name}</Row>
              <Row>
                Data da criação:&nbsp;
                {format(parseISO(op?.manualCheck_created_at), 'dd/MM/yyyy')}
              </Row>
              <Row>Motivo: {op?.description?.comment}</Row>
              <Row>
                {op?.description.file &&
                  (op?.description?.file as Array<any>).map(
                    (link: string, index: number) => (
                      <Col style={{ marginRight: 10 }}>
                        <ShowImage link={link} index={index} type="not found" />
                      </Col>
                    ),
                  )}
              </Row>
            </div>
          );
        }
        break;
      }
    }
    return response;
  };

  useEffect(() => {
    if (reload) {
      loadData(currentPage);
      setReload(false);
      setRowActive('');
    } else {
      loadData();
    }
  }, [loadData, reload]);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h1>Blacklist</h1>
            <h2>Utilize o filtro para pesquisar</h2>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            style={{ marginBottom: '5px' }}
          >
            <SelectInput
              defaultValue="CPF/CNPJ"
              onChange={e => {
                setFilter(String(e));
                setInputData('');
              }}
            >
              <SelectInput.Option value="u.document">
                CPF/CNPJ
              </SelectInput.Option>
              <SelectInput.Option value="u.name">NOME</SelectInput.Option>
              <SelectInput.Option value="u.bank">BANCO</SelectInput.Option>
            </SelectInput>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px', marginLeft: '5px' }}
          >
            {filter === 'u.bank' ? (
              <SelectInput
                defaultValue="Selecione um banco..."
                onChange={e => {
                  setFilter('u.bank');
                  setInputData(String(e));
                }}
              >
                {banks &&
                  (banks as Array<any>).map(data => (
                    <SelectInput.Option value={data.id} key={data.id}>
                      {data.name}
                    </SelectInput.Option>
                  ))}
              </SelectInput>
            ) : (
              <RawInput
                placeholder="Buscar"
                value={
                  filter === 'u.document'
                    ? documentMask(inputData).substring(0, 18)
                    : inputData
                }
                onChange={e => {
                  if (filter === 'u.document') {
                    setInputData(e.target.value.replace(/[a-z]|[\s]/gi, ''));
                  } else {
                    setInputData(e.target.value);
                  }
                }}
              />
            )}
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
            style={{ marginLeft: '5px' }}
          >
            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
        </Row>
      </form>

      <Row
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: 30,
        }}
      >
        <Button
          value="Novo"
          type="primary"
          htmlType="submit"
          style={{ borderRadius: 6 }}
          onClick={() => setOpenNewBlockModal(true)}
        >
          Adicionar <FaPlus style={{ marginLeft: 10 }} />
        </Button>
      </Row>

      <ICol>
        <Table
          dataSource={accounts}
          pagination={false}
          rowKey="pc_id"
          loading={loading}
          locale={{
            emptyText: 'Nenhum usuário para mostrar.',
          }}
          scroll={{ x: true }}
          expandIconColumnIndex={7}
          expandRowByClick={false}
          expandedRowKeys={[rowActive]}
          expandable={{
            expandedRowRender: () => (
              <div key="expandedRowRender">
                {!!rowActive && !!activeAccounts && RuleChange()}
                <div>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Col>
                      {(activeAccounts as IRules)?.status !== 'APPROVED' && (
                        <Row>
                          <Button
                            onClick={() => {
                              setOpenAllowBlockModal(true);
                            }}
                            type="primary"
                            style={{
                              borderRadius: 6,
                              background: '#24A148',
                              borderColor: 'transparent',
                              width: 160,
                            }}
                          >
                            <FaCheck style={{ marginRight: 10 }} /> Liberar
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    setRowActive('');
                    setActiveAccounts([]);
                    setSubRowActive('');
                    setModalImagens([]);
                    onExpand(record, e);
                    setDocument('');
                    setClient('');
                  }}
                />
              ) : (
                <DownCircleOutlined
                  style={{
                    fontSize: 21,
                    color: '#333',
                  }}
                  onClick={e => {
                    loadUserAccount(
                      (record as IBlackList).id,
                      (record as IBlackList).pc_id,
                    );
                    onExpand(record, e);
                    setDocument((record as IBlackList).document);
                    setClient((record as IBlackList).client_id);
                  }}
                />
              ),
          }}
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record) => {
              return (record as IBlackList).user_name === '' ||
                (record as IBlackList).user_name === undefined
                ? `Sem Nome`
                : (record as IBlackList).user_name;
            }}
          />
          <Column
            title="Documento"
            dataIndex="document"
            key="document"
            width={160}
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => documentMask(text)}
          />
          <Column
            title="Banco"
            dataIndex="client_name"
            key="client_name"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record) => {
              return (banks as Array<any>).filter((b: any) => {
                return b.id === (record as IBlackList).client_id && b;
              }).length > 0
                ? ((banks as Array<any>).filter((b: any) => {
                    return b.id === (record as IBlackList).client_id && b;
                  }) as any)[0].name
                : 'cliente não identificado';
            }}
          />
          <Column
            title="Criado em"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
            }}
          />
          <Column
            title="Origem ou Regras"
            dataIndex="rule_id"
            key="rule_id"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record) => {
              let response = <></>;
              switch ((record as IBlackList).rule_id) {
                case 'HasOFACData': {
                  response = <div>Dados da OFAC</div>;
                  break;
                }
                case 'HasCriminalHistory': {
                  response = <div>Historico Criminal</div>;
                  break;
                }
                case 'HasProcessAdmin': {
                  response = <>Processo Administrativo</>;
                  break;
                }
                case 'HasProcessTax': {
                  response = <>Processo Tributário</>;
                  break;
                }
                case 'IsAlreadyRegisteredAddress': {
                  response = <>Endereço Registrado</>;
                  break;
                }
                case 'IsDead': {
                  response = <>Falecido</>;
                  break;
                }
                case 'IsDuplicatedEmail': {
                  response = <>E-mail Duplicado</>;
                  break;
                }
                case 'IsOverAge': {
                  response = <>Maior de idade</>;
                  break;
                }
                case 'IsYoungConduct': {
                  response = <>Menor de idade</>;
                  break;
                }
                case 'IsPEP': {
                  response = <>Pessoas Politicamente Expostas</>;
                  break;
                }
                case 'IsValidCNPJ': {
                  response = <>CNPJ Valido</>;
                  break;
                }
                case 'IsValidCPF': {
                  response = <>CPF Valido</>;
                  break;
                }
                case 'IsValidDocument': {
                  response = <div>Documento Valido</div>;
                  break;
                }
                case 'IsValidDocumentPicture': {
                  response = <div>Foto dos Documentos Valido</div>;
                  break;
                }
                case 'IsValidEmail': {
                  response = <>E-mail Valido</>;
                  break;
                }
                case 'IsFaceReal': {
                  response = <>Fotos de rosto Valido</>;
                  break;
                }
                default: {
                  response = <div>MANUAL</div>;
                  break;
                }
              }
              return response;
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={(text, record: IBlackList) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {record.blacklisted && record.status === 'REFUSED' && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="red"
                    >
                      Bloqueado
                    </Tag>
                  )}
                  {record.status === 'APPROVED' && (
                    <Tag
                      style={{ fontWeight: 700, marginRight: 0 }}
                      color="green"
                    >
                      Liberado
                    </Tag>
                  )}
                </div>
              );
            }}
          />
        </Table>
      </ICol>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              prepareLoadData(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                prepareLoadData(currentPage + 1);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>

      <AddDocumentBlackListModal
        visible={openNewBlockModal}
        hide={() => {
          setOpenNewBlockModal(false);
          setReload(true);
        }}
      />
      <ModalAllow />
      <ModalImg />
    </Container>
  );
};

export default BlackList;

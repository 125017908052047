/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Menu,
  Dropdown,
  DatePicker,
  notification,
  Table,
  Divider,
  Space,
} from 'antd';
import {
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { format, parseISO, startOfMonth } from 'date-fns';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { useMemo } from 'react';
import { PropsAccount, PropsUserAccount } from '../../Account';
import api from '../../../../../services/api';
import bankApi from '../../../../../services/bank_api';
import { HandleFormatMoney } from '../../../../../services/utils';
import { useAuth } from '../../../../../hooks/auth';
import 'moment/locale/pt-br';
import { ContainerContent } from '../../Account/styles';
import { Pagination } from '../../../styles';
import { SelectsContainer } from './styles';

moment.updateLocale('pt-br', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
});

const { Column } = Table;

interface ExtractItem {
  amount: number;
  amount_iof: number;
  balance_available: number;
  credit: boolean;
  date: Date;
  description: string;
  id: string;
  type: string;
  type_id: string;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

const Extract: React.FC<{
  user: PropsUserAccount;
  account: PropsAccount;
  allowDownload?: boolean;
}> = ({ user: userParam, account, allowDownload = true }) => {
  const [loading, setLoading] = useState(true);
  const [extracts, setExtracts] = useState<ExtractItem[]>([]);
  const [extractPix, setExtractPix] = useState([] as any);
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [reportLoading, setReportLoading] = useState(false);
  const [user] = useState(userParam);
  const [startDate, setStartDate] = useState<string>(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState<string>(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedKey, setExpandedKey] = useState('');
  const { admin } = useAuth();

  const handleDownloadReport = useCallback(
    async type => {
      setReportLoading(true);

      try {
        let response: AxiosResponse;
        const token = localStorage.getItem('@BaasQesh:token');

        if (startDate || endDate) {
          response = await bankApi.get(
            `admin/transactions/reports/${type}?start=${startDate}&end=${endDate}`,
            {
              headers: {
                admintoken: token,
                account: account.id,
                user: user.user.id,
              },
            },
          );
        } else {
          response = await bankApi.get(
            `admin/transactions/reports/${type}?start=2019-01-01&end=${format(
              new Date(),
              'yyyy-MM-dd',
            )}`,
            {
              headers: {
                admintoken: token,
                account: account.id,
                user: user.user.id,
              },
            },
          );
        }

        setTimeout(() => {
          window.open(response.data.fileName, '_blank', 'noopener,noreferrer');
          setReportLoading(false);
        }, 4000);
      } catch (err) {
        setReportLoading(false);
        notification.error({
          message: 'Ocorreu um erro ao gerar o relatório.',
        });
      }
    },
    [startDate, endDate, account.id, user.user.id],
  );

  const handleDownloadCompleteReport = useCallback(async () => {
    setReportLoading(true);

    try {
      const token = localStorage.getItem('@BaasQesh:token');

      const response = await api.get(
        `admin/transaction/report?start=${startDate}&end=${endDate}`,
        {
          headers: {
            admintoken: token,
            account: account.id,
          },
        },
      );

      window.open(response.data.fileName, '_blank', 'noopener,noreferrer');
      setReportLoading(false);
    } catch (err) {
      setReportLoading(false);
      notification.error({
        message:
          err.response?.data?.message ||
          'Ocorreu um erro ao gerar o relatório.',
      });
    }
  }, [startDate, endDate, account.id]);

  const downloadOptions = useMemo(() => {
    return (
      <>
        <Menu>
          <Menu.Item
            onClick={() => {
              admin.bank.name === 'Galera' || admin.bank.name === 'Xmax Bank'
                ? handleDownloadCompleteReport()
                : handleDownloadReport('xlsx');
            }}
            key="0"
          >
            <span>.XLSX</span>
          </Menu.Item>
          {admin.bank.name !== 'Galera' && admin.bank.name !== 'Xmax Bank' && (
            <Menu.Item onClick={() => handleDownloadReport('pdf')} key="3">
              <span>.PDF</span>
            </Menu.Item>
          )}
        </Menu>
      </>
    );
  }, [admin.bank.name, handleDownloadReport, handleDownloadCompleteReport]);

  const { signOut } = useAuth();

  const loadData = useCallback(
    async (page = 0, start?: string, end?: string) => {
      setLoading(true);
      try {
        let response;

        if (start && end) {
          response = await api.get(
            `/admin/accounts/transactions?&start=${start}&end=${end}&page=${page}`,
            {
              headers: {
                account: account.id,
              },
            },
          );
        } else {
          response = await api.get(
            `/admin/accounts/transactions?start=2019-01-01&end=${format(
              new Date(),
              'yyyy-MM-dd',
            )}&page=${page}`,
            {
              headers: {
                account: account.id,
              },
            },
          );
        }

        setExtracts(response.data.items);
        setMetaData({
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
          total_items: response.data.total_items,
          total_items_page: response.data.total_items_page,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar o extratod',
          });
        }
      }
    },
    [account.id, signOut],
  );

  const loadPix = useCallback(
    async (_expandable, record) => {
      setLoading(true);
      setExpandedKey(record.id);
      try {
        const response = await api.get(
          `/admin/accounts/pix/${record.type_id}`,
          {
            headers: {
              account: account.id,
            },
          },
        );

        const fix = [];
        fix.push(response.data);
        setExtractPix(fix);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar o extrato',
          });
        }
      }
    },
    [account.id, signOut],
  );
  useEffect(() => {
    loadData(
      0,
      format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      format(new Date(), 'yyyy-MM-dd'),
    );
  }, [loadData]);

  const handleDatePickersChange = useCallback(
    (date, dateString: string, picker: 'start_date' | 'end_date') => {
      picker === 'start_date'
        ? setStartDate(format(new Date(date), 'yyyy-MM-dd'))
        : setEndDate(format(new Date(date), 'yyyy-MM-dd'));

      loadData(
        0,
        picker === 'start_date'
          ? format(new Date(date), 'yyyy-MM-dd')
          : startDate,
        picker === 'end_date' ? format(new Date(date), 'yyyy-MM-dd') : endDate,
      );
    },
    [endDate, loadData, startDate],
  );

  return (
    <ContainerContent style={{ background: '#f0f2f5' }}>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Extrato
      </Divider>

      <SelectsContainer>
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Data inicial"
          value={moment(String(startDate), 'YYYY-MM-DD')}
          clearIcon={null}
          locale={ptBR}
          onChange={
            (date, dateString) =>
              handleDatePickersChange(date, dateString, 'start_date')
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Data final"
          value={moment(String(endDate), 'YYYY-MM-DD')}
          clearIcon={null}
          locale={ptBR}
          onChange={
            (date, dateString) =>
              handleDatePickersChange(date, dateString, 'end_date')
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </SelectsContainer>

      {!!allowDownload && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <>
            {reportLoading ? (
              <LoadingOutlined
                size={20}
                style={{ marginRight: 'auto', marginLeft: 'auto' }}
              />
            ) : (
              <Dropdown overlay={downloadOptions} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  Exportar extrato <DownOutlined />
                </a>
              </Dropdown>
            )}
          </>
        </div>
      )}

      <Table
        dataSource={extracts}
        pagination={false}
        rowKey="id"
        loading={loading}
        locale={{
          emptyText: 'Nenhum lançamento para mostrar.',
        }}
        scroll={{ x: '500px' }}
        expandable={{
          expandedRowKeys: [expandedKey],
          onExpand: (expanded, record) =>
            expanded ? loadPix(expanded, record) : setExtractPix([]),
          expandedRowRender: () => (
            <Table
              dataSource={extractPix}
              pagination={false}
              rowKey="id"
              loading={loading}
              locale={{
                emptyText: (
                  <span style={{ color: 'grey' }}>
                    Nenhuma transação para mostrar
                  </span>
                ),
              }}
            >
              <Column
                title="Autorização"
                dataIndex="end_to_end_id"
                key="end_to_end_id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Transação ID"
                dataIndex="transaction_id"
                key="transaction_id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Tipo ID"
                dataIndex="id"
                key="id"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Tipo"
                key="tipo"
                render={() => (
                  <Space size="middle">
                    <span>PIX</span>
                  </Space>
                )}
              />
            </Table>
          ),
          rowExpandable: record => record.type === 'PIX',
        }}
      >
        <Column
          title="Data"
          dataIndex="date"
          key="date"
          render={text => format(parseISO(text), 'dd/MM/yyyy HH:mm:ss')}
        />
        <Column title="Descrição" dataIndex="description" key="description" />
        <Column
          title="Valor"
          dataIndex="amount"
          key="amount"
          render={(text, record: ExtractItem) => {
            if (record.amount === 0) {
              return <span>{HandleFormatMoney(text)}</span>;
            }
            if (record.credit) {
              return (
                <span style={{ color: 'green' }}>
                  {HandleFormatMoney(text)}
                </span>
              );
            }

            if (!record.credit) {
              return (
                <span style={{ color: 'red' }}>-{HandleFormatMoney(text)}</span>
              );
            }

            return <span>-{HandleFormatMoney(text)}</span>;
          }}
        />
      </Table>
      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              loadData(currentPage - 1, startDate, endDate);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                loadData(currentPage + 1, startDate, endDate);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </ContainerContent>
  );
};

export default Extract;

/* eslint-disable no-constant-condition */
import React, { useEffect, useMemo, useState } from 'react';

import { Table, notification, Select, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { format } from 'date-fns';

import Column from 'antd/lib/table/Column';
import { Pagination } from '../../../styles';
import { ISelect } from './styles';

import { ContainerContent } from '../../Account/styles';
import api from '../../../../../services/api';
import { PropsAccount } from '../../Account';
import { useAuth } from '../../../../../hooks/auth';
import { RowGutter } from '../../../../../styles/components';
import {
  getTransactionStatusConfig,
  HandleFormatMoney,
} from '../../../../../services/utils';

interface ExtractItem {
  id: string;
  amount: number;
  description: string;
  reason: string;
  beneficiary_bank: string;
  beneficiary_branch: string;
  beneficiary_account: string;
  beneficiary_name: string;
  digitable_line: string;
  status: string;
  date: Date;
  credit: boolean;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

interface IResponseData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
  items: ExtractItem[];
}

interface ITransactionsMenuProps {
  account: PropsAccount;
}

const Transactions: React.FC<ITransactionsMenuProps> = ({ account }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<ExtractItem[]>(
    [] as ExtractItem[],
  );
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectValue, setSelectValue] = useState('teds');
  const { Option } = Select;
  const { signOut } = useAuth();

  useEffect(() => {
    setLoading(true);

    api
      .get<IResponseData>(
        `/admin/accounts/transactions/${selectValue}?page=${currentPage}`,
        {
          headers: {
            account: account.id,
          },
        },
      )
      .then(response => {
        const { data } = response;
        const cashOutTransactions: ExtractItem[] = [] as ExtractItem[];

        data.items.forEach(transaction => {
          if (!transaction.credit) {
            cashOutTransactions.push(transaction);
          }
        });

        setTransactions(cashOutTransactions);
        setMetaData({
          current_page: data.current_page,
          total_pages: data.total_pages,
          total_items: data.total_items,
          total_items_page: data.total_items_page,
        });

        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Não foi possível buscar as transações',
        });

        setTransactions([]);

        setLoading(false);
      });
  }, [signOut, account.id, selectValue, currentPage]);

  const formattedTransactions = useMemo(
    () =>
      transactions.map(transaction => {
        const statusConfig = getTransactionStatusConfig(transaction.status);

        return {
          date: format(new Date(transaction.date), 'dd/MM/yyyy HH:mm:ss'),
          amount: (
            <span key={transaction.id} style={{ color: '#ff4252' }}>
              {HandleFormatMoney(transaction.amount)}
            </span>
          ),
          reason: transaction.reason,
          description: transaction.description,
          account: `Banco ${transaction.beneficiary_bank} / Ag. ${transaction.beneficiary_branch} / Conta ${transaction.beneficiary_account}`,
          name: transaction.beneficiary_name,
          digitable_line: transaction.digitable_line,
          status: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  background: statusConfig?.color,
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  marginRight: '10px',
                }}
              />
              <span>{statusConfig.status}</span>
            </div>
          ),
        };
      }),
    [transactions],
  );

  return (
    <ContainerContent>
      <Divider
        style={{
          fontSize: '24px',
          letterSpacing: '0.5px',
          color: 'rgb(102,102,102)',
          fontWeight: 300,
        }}
      >
        Transações
      </Divider>

      <RowGutter
        style={{ marginBottom: '12px', marginLeft: '0', marginRight: '0' }}
      >
        <ISelect
          defaultValue="teds"
          onChange={selected => {
            setSelectValue(String(selected));
            setCurrentPage(0);
          }}
        >
          <Option value="teds">Teds</Option>
          <Option value="tefs">Tefs</Option>
          <Option value="payments">Pagamentos</Option>
        </ISelect>
      </RowGutter>

      <Table
        dataSource={formattedTransactions}
        pagination={false}
        rowKey="id"
        loading={loading}
        locale={{
          emptyText: 'Nenhum lançamento para mostrar.',
        }}
        scroll={{ x: '500px' }}
      >
        <Column title="Data" dataIndex="date" key="date" />
        <Column title="Situação" dataIndex="status" key="status" />
        {(selectValue === 'teds' || selectValue === 'payments') && (
          <Column title="Razão" dataIndex="reason" key="reason" />
        )}
        {(selectValue === 'teds' || selectValue === 'tefs') && (
          <Column
            title="Conta do beneficiário"
            dataIndex="account"
            key="account"
          />
        )}
        {(selectValue === 'teds' || selectValue === 'tefs') && (
          <Column title="Nome do beneficiário" dataIndex="name" key="name" />
        )}
        <Column title="Valor" dataIndex="amount" key="amount" />
        {selectValue === 'payments' && (
          <Column
            title="Linha digitável"
            dataIndex="digitable_line"
            key="digitable_line"
          />
        )}
      </Table>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </ContainerContent>
  );
};

export default Transactions;

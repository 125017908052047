import styled from 'styled-components';

import {
  Row as AntRow,
  Col as AntCol,
  Input,
  Select,
  Button,
  Checkbox,
} from 'antd';

export const Row = styled(AntRow).attrs({
  gutter: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
  },
})``;

export const RowGutter = styled(AntRow).attrs({
  gutter: {
    xs: 8,
    sm: 16,
    md: 16,
    lg: 16,
  },
})`
  /* margin-bottom: 10px; */
`;

export const Col = styled(AntCol).attrs({
  xs: { span: 24 },
  sm: { span: 24 },
})``;

export const RawInput = styled(Input)`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;

  &:disabled {
    cursor: default;
    color: #333;
  }
`;

export const PasswordInput = styled(Input.Password)`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
`;

export const SelectInput = styled(Select)`
  border-radius: 6px;
  width: 100%;

  .ant-select-selector {
    height: 43px !important;
    align-items: center;
  }
`;

export const Chekbox = styled(Checkbox)`
  display: flex;
  align-items: flex-start;
`;

export const RowButton = styled(Button)`
  border-radius: 6px;
  width: 100%;
`;

import React from 'react';
import { AuthProvider } from './auth';
import { SettingsProvider } from './settings';

const AppProvider: React.FC = ({ children }) => (
  <SettingsProvider>
    <AuthProvider>{children}</AuthProvider>
  </SettingsProvider>
);

export default AppProvider;

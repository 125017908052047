/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as Antd from 'antd';
import React, { useState } from 'react';
import { putMainPartner } from '../../../services/choseMainPartner';
import * as Styles from './styled';

interface IModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  partners: IPartner[];
  accountId: string;
  userId: string;
  reload: boolean;
  setReload: (reload: boolean) => void;
}
interface IPartner {
  document: string;
  email?: string;
  name: string;
  qualification?: string;
}
const ChoseMainPartnerModal: React.FC<IModalProps> = ({
  visible,
  setVisible,
  partners,
  accountId,
  userId,
  reload,
  setReload,
}) => {
  const handleOk = async () => {
    await selectMainPartner();
    setValue('');
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setValue('');
  };
  const [value, setValue] = useState('');

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const selectMainPartner = async () => {
    await putMainPartner(accountId, userId, value);
    setReload(!reload);
  };

  const mapPartners = React.useMemo(() => {
    return partners?.map((partner: IPartner) => (
      <Styles.PartnersDisplay
        style={{ display: 'flex', flexDirection: 'row' }}
        value={partner.document}
      >
        <Styles.PartnersInfo>
          <span>{partner.name}</span>
          {partner.document.replace(
            /^(\d{3})(\d{3})(\d{3})(\d{2})/,
            '$1.$2.$3-$4',
          )}
        </Styles.PartnersInfo>
      </Styles.PartnersDisplay>
    ));
  }, [partners]);
  return (
    <Antd.Modal
      title="Escolher sócio principal"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Styles.ChoseMainPartnerContainer>
        <Antd.Radio.Group onChange={onChange} value={value}>
          <Antd.Space direction="vertical">{mapPartners}</Antd.Space>
        </Antd.Radio.Group>
        <Styles.ChoseMainPartnerBtn onClick={() => handleOk()}>
          Escolher como sócio principal
        </Styles.ChoseMainPartnerBtn>
      </Styles.ChoseMainPartnerContainer>
    </Antd.Modal>
  );
};

export default ChoseMainPartnerModal;

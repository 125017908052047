import styled from 'styled-components';
import { Select } from 'antd';

export const ISelect = styled(Select)`
  width: 100%;
  flex: 1;

  &:disabled {
    cursor: default;
    color: #333;
  }
`;

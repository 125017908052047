import React, { useState, useEffect, useCallback } from 'react';
import { Table, notification, DatePicker, Menu, Dropdown } from 'antd';
import { endOfDay, format, parseISO, startOfMonth } from 'date-fns';

import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import Axios, { AxiosResponse } from 'axios';
import { Container, Pagination, ICol } from './styles';
import { RowGutter, Col } from '../../styles/components';
import bankApi from '../../services/bank_api';
import { documentMask } from '../../services/utils';
import { useAuth } from '../../hooks/auth';
import { SelectsContainer } from './styles';

moment.updateLocale('pt-br', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
});
const { Column } = Table;

interface User {
  account_id: string;
  bank_account: string;
  bank_branch: string;
  bank_code: string;
  blocked: boolean;
  client_name: string;
  document: string;
  email: string;
  name: string;
  phone: string;
  status: number;
  user_id: string;
  account_active: boolean;
  created_at: string;
}

interface Account {
  id: string;
  bank_code: string;
  bank_branch: string;
  bank_account: string;
  is_pool: boolean;
  suspended_at: string | null;
  active: boolean;
}

interface PropsMetaData {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

interface Indication {
  id: string;
  name: string;
  document: string;
  text: string;
  created_at: string;
}
const Dashboard: React.FC = () => {
  const [metaData, setMetaData] = useState({} as PropsMetaData);
  const [currentPage, setCurrentPage] = useState(0);
  const { signOut, admin } = useAuth();
  const [indic, setIndic] = useState([] as Array<Indication>);

  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState<string>(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState<string>(
    endOfDay(new Date()).toISOString(),
  );

  const [reportLoading, setReportLoading] = useState(false);

  const handleDownloadReport = useCallback(
    async type => {
      setReportLoading(true);

      try {
        let response: AxiosResponse;
        const token = localStorage.getItem('@BaasQesh:token');

        if (startDate || endDate) {
          response = await bankApi.get(
            `/admin/users/indications/reports/${type}?start=${startDate}&end=${endDate}`,
            {
              headers: {
                admintoken: token,
                client: admin.bank.id,
              },
            },
          );
        } else {
          response = await bankApi.get(
            `/admin/users/indications/reports/${type}?start=2019-01-01&end=${format(
              new Date(),
              'yyyy-MM-dd',
            )}`,
            {
              headers: {
                admintoken: token,
                client_id: admin.bank.id,
              },
            },
          );
        }

        setTimeout(() => {
          window.open(response.data.fileName, '_blank', 'noopener,noreferrer');
          setReportLoading(false);
        }, 4000);
      } catch (err) {
        setReportLoading(false);
        notification.error({
          message: 'Ocorreu um erro ao gerar o relatório.',
        });
      }
    },
    [startDate, endDate, admin],
  );

  const downloadOptions = (
    <Menu>
      <Menu.Item onClick={() => handleDownloadReport('xlsx')} key="0">
        <span>.XLSX</span>
      </Menu.Item>

      {/* <Menu.Item onClick={() => handleDownloadReport('pdf')} key="3">
        <span>.PDF</span>
      </Menu.Item> */}
    </Menu>
  );

  const loadData = useCallback(
    async (page = 0, start_Date?, end_Date?) => {
      setLoading(true);
      try {
        const token = localStorage.getItem('@BaasQesh:token');

        const { data } = await bankApi.get(
          `/admin/users/indications/${admin.bank.id}?page=${page}&start_date=${start_Date}&end_date=${end_Date}`,
          {
            headers: {
              admintoken: token,
            },
          },
        );
        setIndic(
          data.items.map((item: User) => {
            return { ...item, key: item.user_id };
          }),
        );
        setMetaData({
          current_page: data.current_page,
          total_pages: data.total_pages,
          total_items: data.total_items,
          total_items_page: data.total_items_page,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message === 'There is another active token') {
          notification.error({
            message:
              'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
          });
          signOut();
        } else {
          notification.error({
            message: 'Não foi possível buscar a lista de contas',
          });
        }
      }
    },
    [signOut, admin],
  );

  const handleDatePickersChange = useCallback(
    (date, dateString: string, picker: 'start_date' | 'end_date') => {
      picker === 'start_date'
        ? setStartDate(format(new Date(date), 'yyyy-MM-dd'))
        : setEndDate(format(new Date(date), 'yyyy-MM-dd'));
    },
    [],
  );

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    loadData(currentPage, startDate, endDate);
  }, [loadData, handleDatePickersChange, currentPage, endDate, startDate]);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <RowGutter>
          <Col>
            <h1>Recomendações</h1>
            <h2>Utilize o filtro para pesquisar</h2>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18 }}
            md={{ span: 18 }}
            lg={{ span: 15 }}
            style={{ marginBottom: '5px' }}
          >
            <SelectsContainer>
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Data inicial"
                value={moment(String(startDate), 'YYYY-MM-DD')}
                clearIcon={null}
                locale={ptBR}
                style={{ marginRight: '5px' }}
                onChange={(date, dateString) => {
                  handleDatePickersChange(date, dateString, 'start_date');
                }}
              />
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Data final"
                value={moment(String(endDate), 'YYYY-MM-DD')}
                clearIcon={null}
                locale={ptBR}
                style={{ marginLeft: '-0.5px' }}
                onChange={(date, dateString) => {
                  handleDatePickersChange(date, dateString, 'end_date');
                }}
              />
            </SelectsContainer>
          </Col>
        </RowGutter>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <>
            {reportLoading ? (
              <LoadingOutlined
                size={20}
                style={{ marginRight: 'auto', marginLeft: 'auto' }}
              />
            ) : (
              <Dropdown overlay={downloadOptions} trigger={['click']}>
                <button
                  type="button"
                  style={{
                    borderWidth: 0,
                    color: '#1890ff',
                  }}
                  className="ant-dropdown-link"
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  Exportar Recomendação <DownOutlined />
                </button>
              </Dropdown>
            )}
          </>
        </div>
      </form>

      <RowGutter style={{ marginTop: '30px' }} />
      <ICol>
        <Table
          dataSource={indic}
          pagination={false}
          loading={loading}
          locale={{
            emptyText: 'Nenhum recomendação para mostrar.',
          }}
          scroll={{ x: '500px' }}
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
            responsive={['xs', 'sm', 'md', 'xl']}
          />
          <Column
            title="CPF/CNPJ"
            dataIndex="document"
            key="document"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => documentMask(text)}
          />
          <Column title="Comentario" dataIndex="text" key="text" />
          <Column
            title="Criado em"
            dataIndex="created_at"
            key="created_at"
            align="center"
            responsive={['xs', 'sm', 'md', 'xl']}
            render={text => {
              return <>{format(parseISO(text), 'dd/MM/yyyy')}</>;
            }}
          />
        </Table>
      </ICol>

      <Pagination>
        {currentPage > 0 && (
          <span
            onClick={() => {
              setCurrentPage(currentPage - 1);
              loadData(currentPage - 1, startDate, endDate);
            }}
          >
            <LeftOutlined /> Anterior
          </span>
        )}

        {metaData.total_items_page >= 20 &&
          metaData.current_page !== metaData.total_pages && (
            <span
              onClick={() => {
                setCurrentPage(currentPage + 1);
                loadData(currentPage + 1, startDate, endDate);
              }}
            >
              Próximo <RightOutlined />
            </span>
          )}
      </Pagination>
    </Container>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Divider, notification, Spin } from 'antd';
import { CurrecyMaskedInput, InputsContainer } from './styles';
import { RowButton } from '../../../styles/components';

const Costs: React.FC<{
  bank: any;
}> = ({ bank }) => {
  const [form] = Form.useForm();
  const [freeFeesForm] = Form.useForm();
  const [limitsForm] = Form.useForm();

  const [tedInputValue, setTedInputValue] = useState<number | null>(
    bank.ted_cost,
  );
  const [tefInputValue, setTefInputValue] = useState<number | null>(
    bank.tef_cost,
  );
  const [billInputValue, setBillInputValue] = useState<number | null>(
    bank.bill_cost,
  );

  const [loading, setLoading] = useState<boolean>(false);

  return <div>Em breve</div>;
};

export default Costs;

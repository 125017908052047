/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import ReactJson from 'react-json-view';

import { Button, notification } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Container } from './styles';

import { RowGutter, Col, RawInput, SelectInput } from '../../styles/components';

import api from '../../services/api';

const Dashboard: React.FC = () => {
  const [inputData, setInputData] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataNeoway, setDataNeoway] = useState(null);

  const prepareLoadData = useCallback(async () => {
    setLoading(true);
    setDataNeoway(null);
    const document = String(inputData).replace(/[-,/,-,.]/gi, '');
    try {
      const { data } = await api.get(`id/admin/${document}`);
      setDataNeoway(data);
      setLoading(false);
    } catch (err) {
      setDataNeoway(null);
      setLoading(false);
      notification.error({
        message: 'Não foi possível encontrar os dados.',
      });
    }
  }, [inputData]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      prepareLoadData();
    },
    [prepareLoadData],
  );

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <RowGutter>
          <Col>
            <h1>Consulta KYC</h1>
            {/* <h2>Utilize o campo par</h2> */}
          </Col>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            style={{ marginBottom: '5px' }}
          >
            <SelectInput defaultValue="CPF/CNPJ">
              <SelectInput.Option value="u.document">
                CPF/CNPJ
              </SelectInput.Option>
            </SelectInput>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{ marginBottom: '5px' }}
          >
            <RawInput
              placeholder="Buscar"
              value={inputData}
              onChange={e => setInputData(e.target.value)}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Button
              htmlType="submit"
              style={{ height: '44px', width: '44px' }}
              type="dashed"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
        </RowGutter>
      </form>
      <RowGutter style={{ marginTop: '30px' }}>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {dataNeoway && (
              <ReactJson src={dataNeoway || {}} style={{ fontSize: '14px' }} />
            )}
          </>
        )}
      </RowGutter>
    </Container>
  );
};

export default Dashboard;

/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';

import { Menu, Skeleton, Select, Spin, notification } from 'antd';
import { RowGutter, Col } from '../../styles/components';
import { Container, Content } from './styles';
import { useAuth } from '../../hooks/auth';
import Reports from './reports';
import General from './general';
import Costs from './costs';
import CreateAdmin from './createAdmin';
import AdminUsers from './adminUsers';
import api from '../../services/api';

interface IClients {
  bill_cost: number;
  id: string;
  name: string;
  pix_cost: number;
  pix_qrcode_cost: number;
  ted_cost: number;
  tef_cost: number;
}

const BanksConfigurations: React.FC = () => {
  const [loading] = useState(false);
  const [clients, setClients] = useState<any>(null);
  const [selectedBank, setSelectedBank] = useState<null | IClients>(null);
  const [type, setType] = useState('general');
  const { banks }: any = useAuth();

  const toogleMenu = useCallback(e => {
    switch (e.key) {
      case '1':
        setType('general');
        break;

      case '2':
        setType('costs');
        break;

      case '3':
        setType('reports');
        break;

      case '4':
        setType('createAdmin');
        break;
      case '5':
        setType('adminUsers');
        break;
      default:
        setType('general');
    }
  }, []);

  const handleGetCleints = useCallback(async () => {
    try {
      const { data } = await api.get('/admin/fee');
      setClients(data);
    } catch (err) {
      notification.error({
        message: 'Erro ao buscar informações.',
      });
    }
  }, []);

  const handleOptionChange = useCallback(
    async (id: string): Promise<void> => {
      setSelectedBank(clients.find((i: any): any => i.id === id));
    },
    [clients],
  );

  useEffect(() => {
    handleGetCleints();

    if (selectedBank) {
      handleOptionChange(selectedBank?.id);
    }
  }, [type, selectedBank]);

  return clients ? (
    <Container
      style={{
        background: loading ? '#fff' : 'transparent',
        padding: loading ? '26px' : 'inheirt',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Configurações dos bancos</h1>
        <Select
          defaultValue="Selecione um banco"
          style={{ width: 200, alignSelf: 'center', margin: 20 }}
          options={banks.map((i: any) => ({ label: i.name, value: i.id }))}
          onChange={handleOptionChange}
        />
      </div>
      {loading ? (
        <>
          <Skeleton active={loading} avatar paragraph={{ rows: 4 }} />
          <Skeleton active={loading} paragraph={{ rows: 4 }} />
        </>
      ) : selectedBank ? (
        <>
          {loading ? (
            <Spin size="large" />
          ) : (
            <RowGutter>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <Content>
                  <Menu
                    onClick={toogleMenu}
                    style={{ width: '100%', background: '#fff' }}
                    defaultSelectedKeys={['1']}
                    mode="inline"
                  >
                    <Menu.Item key="1">Configurações gerais</Menu.Item>
                    <Menu.Item key="2">Custos e receitas mensais</Menu.Item>
                    <Menu.Item key="3">Relatórios</Menu.Item>
                    <Menu.Item key="4">Criar um Usuário Admin</Menu.Item>
                    <Menu.Item key="5">Contas Cadastradas</Menu.Item>
                  </Menu>
                </Content>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
              >
                <Content>
                  {type === 'general' && <General bank={selectedBank} />}
                  {type === 'costs' && <Costs bank={selectedBank} />}
                  {/* {type === 'extract' && (
                    <Extract user={userAccount} account={account} />
                  )} 
                  {type === 'transactions' && (
                    <Transactions account={account} />
                  )} */}
                  {type === 'reports' && <Reports bank={selectedBank} />}
                  {type === 'createAdmin' && (
                    <CreateAdmin bank={selectedBank} />
                  )}
                  {type === 'adminUsers' && <AdminUsers bank={selectedBank} />}
                </Content>
              </Col>
            </RowGutter>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  ) : (
    <Skeleton active />
  );
};

export default BanksConfigurations;

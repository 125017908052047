/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useMemo } from 'react';
import { Modal, notification, Steps } from 'antd';
import { FiX } from 'react-icons/fi';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FaFilePdf } from 'react-icons/fa';

import {
  Container,
  Content,
  Footer,
  Header,
  IUpload,
  Row,
  StepButtons,
  StepContent,
} from './styles';
import { RowButton, SelectInput } from '../../../styles/components';

import api from '../../../services/api';

interface IDocumentsModalProps {
  userId: string;
  visible: boolean;
  hide: () => void;
}

const { Step } = Steps;

const DocumentUpload: React.FC<IDocumentsModalProps> = ({
  visible,
  hide,
  userId,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [documentType, setDocumentType] = useState('SELFIE');
  const [currentFile, setCurrentFile] = useState<null | string>(null);
  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const [enablePreview, setEnablePreview] = useState(true);

  const beforeUpload = useCallback(file => {
    setEnablePreview(true);
    if (file.type === 'application/pdf') {
      setEnablePreview(false);

      if (file.size > 33096064) {
        notification.error({ message: 'No máximo 3MB por arquivo.' });
        return false;
      }
    }

    return true;
  }, []);

  function getBase64(img: Blob, callback: any): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = useCallback((info, _type) => {
    if (info.file.status === 'uploading') {
      setLoadingSelfie(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setCurrentFile(imageUrl);
      });

      setLoadingSelfie(false);
    } else if (info.file.status === 'error') {
      notification.error({
        message: 'Falha na validação do documento enviado.',
      });

      setLoadingSelfie(false);
    }
  }, []);

  const formContent = useMemo(() => {
    switch (documentType) {
      case 'SELFIE':
      default:
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 0 }}
            >
              <h1>Envie a selfie</h1>
            </Row>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 20 }}
            >
              <h2>
                A foto precisa mostrar seu rosto inteiro e de olhos abertos.
              </h2>
            </Row>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IUpload
                action={`${api.defaults.baseURL}/admin/users/documents?category=SELFIE`}
                // action="https://baas.qesh.ai/v1/admin/users/documents?category=SELFIE"
                headers={{
                  authorization: `Bearer ${localStorage.getItem(
                    '@BaasQesh:token',
                  )}`,
                  user: userId,
                }}
                name="file"
                accept="image/jpg,image/jpeg,image/png"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={info => handleChange(info, 'selfie')}
              >
                {currentFile ? (
                  <>
                    {enablePreview ? (
                      <img
                        src={currentFile || ''}
                        alt="Selfie"
                        style={{
                          width: '100%',
                          marginTop: '60px',
                          marginBottom: '60px',
                        }}
                      />
                    ) : (
                      <FaFilePdf size={125} />
                    )}
                  </>
                ) : (
                  <div>
                    <div style={{ marginBottom: '5px' }}>
                      Clique para fazer upload
                    </div>
                    {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                  </div>
                )}
              </IUpload>
            </div>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                      hide();
                      setDocumentType('SELFIE');
                      setCurrentStep(0);
                      setCurrentFile(null);
                    }}
                  >
                    <span>Enviar</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );

      case 'DRIVER_LICENSE_FRONT':
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 20 }}
            >
              <h1>Envie foto da FRENTE da CNH</h1>
            </Row>
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IUpload
                    action={`${api.defaults.baseURL}/admin/users/documents?category=DRIVER_LICENSE_FRONT`}
                    // action="https://baas.qesh.ai/v1/admin/users/documents?category=DRIVER_LICENSE_FRONT"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        '@BaasQesh:token',
                      )}`,
                      user: userId,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={info => handleChange(info, 'selfie')}
                  >
                    {currentFile ? (
                      <>
                        {enablePreview ? (
                          <img
                            src={currentFile || ''}
                            alt="Selfie"
                            style={{
                              width: '100%',
                              marginTop: '60px',
                              marginBottom: '60px',
                            }}
                          />
                        ) : (
                          <FaFilePdf size={125} />
                        )}
                      </>
                    ) : (
                      <div>
                        <div style={{ marginBottom: '5px' }}>
                          Clique para fazer upload
                        </div>
                        {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                  </IUpload>
                </div>
              </div>
            </>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                      hide();
                      setDocumentType('SELFIE');
                      setCurrentStep(0);
                      setCurrentFile(null);
                    }}
                  >
                    <span>Próximo</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );

      case 'DRIVER_LICENSE_VERSE':
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 20 }}
            >
              <h1>Envie foto do VERSO da CNH</h1>
            </Row>
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IUpload
                    action={`${api.defaults.baseURL}/admin/users/documents?category=DRIVER_LICENSE_VERSE`}
                    // action="https://baas.qesh.ai/v1/admin/users/documents?category=DRIVER_LICENSE_VERSE"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        '@BaasQesh:token',
                      )}`,
                      user: userId,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={info => handleChange(info, 'selfie')}
                  >
                    {currentFile ? (
                      <>
                        {enablePreview ? (
                          <img
                            src={currentFile || ''}
                            alt="Selfie"
                            style={{
                              width: '100%',
                              marginTop: '60px',
                              marginBottom: '60px',
                            }}
                          />
                        ) : (
                          <FaFilePdf size={125} />
                        )}
                      </>
                    ) : (
                      <div>
                        <div style={{ marginBottom: '5px' }}>
                          Clique para fazer upload
                        </div>
                        {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                  </IUpload>
                </div>
              </div>
            </>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                      hide();
                      setDocumentType('SELFIE');
                      setCurrentStep(0);
                      setCurrentFile(null);
                    }}
                  >
                    <span>Próximo</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );

      case 'IDENTITY_CARD_FRONT':
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 20 }}
            >
              <h1>Envie foto do FRENTE da identidade</h1>
            </Row>
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IUpload
                    action={`${api.defaults.baseURL}/admin/users/documents?category=IDENTITY_CARD_FRONT`}
                    // action="https://baas.qesh.ai/v1/admin/users/documents?category=IDENTITY_CARD_FRONT"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        '@BaasQesh:token',
                      )}`,
                      user: userId,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={info => handleChange(info, 'selfie')}
                  >
                    {currentFile ? (
                      <>
                        {enablePreview ? (
                          <img
                            src={currentFile || ''}
                            alt="Selfie"
                            style={{
                              width: '100%',
                              marginTop: '60px',
                              marginBottom: '60px',
                            }}
                          />
                        ) : (
                          <FaFilePdf size={125} />
                        )}
                      </>
                    ) : (
                      <div>
                        <div style={{ marginBottom: '5px' }}>
                          Clique para fazer upload
                        </div>
                        {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                  </IUpload>
                </div>
              </div>
            </>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                      hide();
                      setDocumentType('SELFIE');
                      setCurrentStep(0);
                      setCurrentFile(null);
                    }}
                  >
                    <span>Próximo</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );

      case 'IDENTITY_CARD_VERSE':
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 20 }}
            >
              <h1>Envie foto do VERSO da identidade</h1>
            </Row>
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IUpload
                    action={`${api.defaults.baseURL}/admin/users/documents?category=IDENTITY_CARD_VERSE`}
                    // action="https://baas.qesh.ai/v1/admin/users/documents?category=IDENTITY_CARD_VERSE"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        '@BaasQesh:token',
                      )}`,
                      user: userId,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={info => handleChange(info, 'selfie')}
                  >
                    {currentFile ? (
                      <>
                        {enablePreview ? (
                          <img
                            src={currentFile || ''}
                            alt="Selfie"
                            style={{
                              width: '100%',
                              marginTop: '60px',
                              marginBottom: '60px',
                            }}
                          />
                        ) : (
                          <FaFilePdf size={125} />
                        )}
                      </>
                    ) : (
                      <div>
                        <div style={{ marginBottom: '5px' }}>
                          Clique para fazer upload
                        </div>
                        {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                  </IUpload>
                </div>
              </div>
            </>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                      hide();
                      setDocumentType('SELFIE');
                      setCurrentStep(0);
                      setCurrentFile(null);
                    }}
                  >
                    <span>Próximo</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );

      case 'COMPANY':
        return (
          <>
            <Row
              gutter={16}
              style={{ justifyContent: 'center', marginBottom: 0 }}
            >
              <h1 style={{ textAlign: 'center' }}>
                Envie o documento principal de identificação/constituição da
                empresa. <br />
                Ex: Contrato Social.
              </h1>
            </Row>
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IUpload
                    // action={`${api.defaults.baseURL}/onboarding/documents?category=COMPANY`}
                    action={`${api.defaults.baseURL}/admin/users/documents?category=COMPANY`}
                    // action="https://baas.qesh.ai/v1/admin/users/documents?category=COMPANY"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        '@BaasQesh:token',
                      )}`,
                      user: userId,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={info => handleChange(info, 'selfie')}
                  >
                    {currentFile ? (
                      <>
                        {enablePreview ? (
                          <img
                            src={currentFile || ''}
                            alt="Documento"
                            style={{
                              width: '100%',
                              marginTop: '60px',
                              marginBottom: '60px',
                            }}
                          />
                        ) : (
                          <FaFilePdf size={125} />
                        )}
                      </>
                    ) : (
                      <div>
                        <div style={{ marginBottom: '5px' }}>
                          Clique para fazer upload
                        </div>
                        {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                  </IUpload>
                </div>
              </div>
            </>
            {!!currentFile && (
              <>
                <div
                  style={{
                    marginBottom: '40px',
                    marginTop: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RowButton
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </RowButton>

                  <RowButton
                    htmlType="submit"
                    onClick={() => {
                      setCurrentFile(null);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Finalizar</span>
                  </RowButton>
                </div>
              </>
            )}
          </>
        );
    }
  }, [
    documentType,
    userId,
    beforeUpload,
    currentFile,
    enablePreview,
    loadingSelfie,
    handleChange,
    hide,
  ]);

  const steps = [
    {
      title: 'Escolha do arquivo',
      content: (
        <Container>
          <div style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
            <label
              style={{
                width: '100%',
                fontFamily: 'sans-serif',
                fontSize: '16px',
              }}
            >
              Selecione o documento que deseja enviar:
              <SelectInput
                value={documentType}
                onChange={value => setDocumentType(String(value))}
              >
                <SelectInput.Option value="SELFIE">Selfie</SelectInput.Option>
                <SelectInput.Option value="DRIVER_LICENSE_FRONT">
                  Frente da CNH
                </SelectInput.Option>
                <SelectInput.Option value="DRIVER_LICENSE_VERSE">
                  Verso da CNH
                </SelectInput.Option>
                <SelectInput.Option value="IDENTITY_CARD_FRONT">
                  Frente da identidade
                </SelectInput.Option>
                <SelectInput.Option value="IDENTITY_CARD_VERSE">
                  Verso da Identidade
                </SelectInput.Option>
                <SelectInput.Option value="COMPANY">
                  Documento PJ
                </SelectInput.Option>
              </SelectInput>
            </label>
          </div>
        </Container>
      ),
    },
    {
      title: 'Upload',
      content: (
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {formContent}
          </div>
        </Container>
      ),
    },
  ];

  const next = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const prev = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  return (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={() => {
        hide();
        setDocumentType('SELFIE');
        setCurrentStep(0);
        setCurrentFile(null);
      }}
      closable={false}
      onCancel={() => {
        hide();
        setDocumentType('SELFIE');
        setCurrentStep(0);
        setCurrentFile(null);
      }}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0, borderRadius: '8px' }}
      width="80%"
    >
      <Header>
        Upload de arquivos
        <FiX
          onClick={() => {
            hide();
            setDocumentType('SELFIE');
            setCurrentStep(0);
            setCurrentFile(null);
          }}
        />
      </Header>
      <Content>
        <StepContent>{steps[currentStep].content}</StepContent>

        <StepButtons>
          {currentStep < steps.length - 1 && (
            <RowButton type="primary" onClick={() => next()}>
              Próximo
            </RowButton>
          )}

          {currentStep > 0 && (
            <RowButton onClick={() => prev()}>Anterior</RowButton>
          )}

          {/* {currentStep === steps.length - 1 && (
            <RowButton
              type="primary"
              onClick={() =>
                // eslint-disable-next-line prettier/prettier
                notification.success({ message: 'Processing complete!' })}
            >
              Salvar
            </RowButton>
          )} */}
        </StepButtons>
      </Content>

      <Footer>
        <div style={{ width: '40%' }}>
          <Steps current={currentStep}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </Footer>
    </Modal>
  );
};

export default DocumentUpload;

import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Menu, notification, Skeleton, Tag } from 'antd';

import Axios from 'axios';
import api from '../../../../services/api';

import { RowGutter, Col } from '../../../../styles/components';
import { Container, Content, TopSidebar, Avatar } from './styles';

import { useAuth } from '../../../../hooks/auth';

import Personal from '../menus/Personal';

interface PropsParams {
  user_id: string;
}

export interface PropsAccount {
  id: string;
  active: boolean;
  bank_account: string;
  bank_branch: string;
  bank_code: string;
  balance: {
    balance_available: number;
    updated: Date;
  };
  user: {
    id: string;
    name: string;
    social_name: string;
    document: string;
    blocked: boolean;
    email: string;
    phone: string;
    status: number;
    created_at?: Date;
  };
}

export interface PropsDocument {
  id: string;
  name: string;
  original_name: string;
  category: string;
  created_at: Date;
}

export interface PropsUserAccount {
  user: {
    id: string;
    name: string;
    social_name: string;
    document: string;
    blocked: boolean;
    email: string;
    phone: string;
    status: {
      name: string;
    };
    bank: {
      name: string;
      manual_approval: boolean;
    };
    pending_approval?: boolean;
    created_at: Date;
  };
  additional: {
    birth_date: string;
    opening_date: string;
    mother_name: string;
    education: string;
    isPEP?: boolean;
    monthly_income: string;
    nationality: string;
    occupation: string;
    patrimony: string;
    pronoun: string;
    legal_nature: string;
    share_capital: string;
    trading_name: string;
    number_of_employees: string;
    declaration_number: string;
    revenues: string;
    number_of_partners: string;
    relationship: string;
    partners: [
      {
        document: string;
        name: string;
        email: string;
        qualification: string;
      },
    ];
  };
  address: {
    id: string;
    street: string;
    number: string;
    neighborhood: string;
    complement?: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
  };
  documents: Array<PropsDocument>;
}

const UserDetail: React.FC = () => {
  const { user_id } = useParams<PropsParams>();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('personal_data');
  const [user, setUser] = useState({} as PropsUserAccount);

  const { signOut, admin } = useAuth();

  const loadUserInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/users/details`, {
        headers: {
          user: user_id,
        },
      });
      setUser(data);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.message === 'There is another active token') {
        notification.error({
          message:
            'Existe outra sessão ativa para este usuário. É permitido uma sessão por usuário.',
        });
        signOut();
      } else {
        notification.error({
          message: 'Não foi possível recuperar os dados do usuário.',
        });
      }
      setLoading(false);
    }
  }, [signOut, user_id]);

  useEffect(() => {
    loadUserInfo();
  }, [loadUserInfo]);

  const handleBlockUnblockAccount = useCallback(() => {
    api
      .put(
        'admin/users/security/approval',
        {
          pending: !user.user.pending_approval,
        },
        {
          headers: {
            user: user.user.id,
          },
        },
      )
      .then(() => {
        notification.success({ message: 'Sucesso.' });
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch(() => {
        notification.error({ message: 'Erro ao aprovar/bloquear' });
      });
  }, [user]);

  const handleDeleteIncompleteUser = useCallback(() => {
    // eslint-disable-next-line no-alert
    const result = window.confirm(
      'Deseja mesmo deletar esse usuário? Essa ação não pode ser desfeita.',
    );
    if (result) {
      api
        .delete(`admin/users/delete/${user.user.id}`)
        .then(() => {
          notification.success({ message: 'Sucesso.' });
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        })
        .catch(() => {
          notification.error({ message: 'Erro ao deletar usuário' });
        });
    }
  }, [user]);

  const toogleMenu = useCallback(e => {
    switch (e.key) {
      case '1':
        setType('personal_data');
        break;

      default:
        setType('personal_data');
    }
  }, []);

  return (
    <Container
      style={{
        background: loading ? '#fff' : 'transparent',
        padding: loading ? '26px' : 'inheirt',
      }}
    >
      {loading ? (
        <>
          <Skeleton active={loading} avatar paragraph={{ rows: 4 }} />
          <Skeleton active={loading} paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          <h1>Detalhes do usuário - {user.user.name}</h1>
          <RowGutter>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
            >
              <Content>
                <TopSidebar>
                  <Avatar>
                    {String(user.user.name)?.split(' ')?.[0]?.split('')?.[0]}
                    {String(user.user.name)?.split(' ')?.[1]?.split('')?.[0]}
                  </Avatar>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      fontSize: '14px',
                      marginBottom: '6px',
                    }}
                  >
                    <span>Status:</span>
                    {user.user.blocked && (
                      <Tag
                        style={{ fontWeight: 700, marginRight: 0 }}
                        color="#f80850"
                      >
                        Bloqueado
                      </Tag>
                    )}
                    {!user.user.blocked &&
                      user.user.status.name === 'WAITING_DOCUMENTS' && (
                        <Tag
                          style={{ fontWeight: 700, marginRight: 0 }}
                          color="#ffbb00"
                        >
                          Incompleto
                        </Tag>
                      )}
                    {!user.user.blocked &&
                      user.user.status.name === 'WAITING_ANALYSIS' && (
                        <Tag
                          style={{ fontWeight: 700, marginRight: 0 }}
                          color="#0985FA"
                        >
                          Em análise
                        </Tag>
                      )}
                    {!user.user.blocked && user.user.status.name === 'ACTIVE' && (
                      <Tag
                        style={{ fontWeight: 700, marginRight: 0 }}
                        color="#87d068"
                      >
                        Ativo
                      </Tag>
                    )}
                    {!user.user.blocked && user.user.status.name === 'DENIED' && (
                      <Tag
                        style={{ fontWeight: 700, marginRight: 0 }}
                        color="#f80850"
                      >
                        Cadastro negado
                      </Tag>
                    )}
                  </div>
                </TopSidebar>
                <Menu
                  onClick={toogleMenu}
                  style={{ width: '100%', background: '#fff' }}
                  defaultSelectedKeys={['1']}
                  mode="inline"
                >
                  <Menu.Item key="1">Dados pessoais</Menu.Item>
                </Menu>
              </Content>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
            >
              {(admin?.bank.name === 'Qesh Payments' ||
                admin?.bank.name === 'BancoMare' ||
                admin?.bank.name === 'Kexion' ||
                admin?.bank.name === 'Galera' ||
                admin?.bank.name === 'CashPag') && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    marginTop: -40,
                    marginLeft: -20,
                  }}
                >
                  {(admin?.bank.name === 'Qesh Payments' ||
                    user.user.bank.manual_approval) && (
                    <Button onClick={handleBlockUnblockAccount}>
                      {user.user.pending_approval
                        ? 'Aprovar usuário'
                        : 'Bloquear usuário'}
                    </Button>
                  )}
                  {(admin?.bank.name === 'Qesh Payments' ||
                    admin?.bank.name === 'Kexion' ||
                    admin?.bank.name === 'Galera') && (
                    <Button onClick={handleDeleteIncompleteUser}>
                      Deletar usuário
                    </Button>
                  )}
                </div>
              )}
              <Content>
                {type === 'personal_data' && <Personal user={user} />}
              </Content>
            </Col>
          </RowGutter>
        </>
      )}
    </Container>
  );
};

export default UserDetail;

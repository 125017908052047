import styled from 'styled-components';
import { Row as RolAntd, Col as ColAntd, Upload, Button } from 'antd';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignores
import CurrencyInput from 'react-currency-input';

export const Row = styled(RolAntd)`
  @media (max-width: 1020px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Col = styled(ColAntd)`
  @media (max-width: 1020px) {
    margin-top: 30px;
  }

  &.bottom {
    margin-bottom: 35px;

    @media (max-width: 1020px) {
      margin-bottom: 0;
    }
  }

  &.bottomLg {
    margin-bottom: 60px;
    @media (max-width: 1020px) {
      margin-bottom: 0;
    }
  }
`;

export const IUpload = styled(Upload)`
  width: 100%;
  display: flex;
  justify-content: center;

  span > img {
    max-height: 150px;
  }
`;

export const Header = styled.div`
  color: #000;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  padding: 26px;
  text-align: center;
  font-weight: 400 !important;
  font-size: 15px;
  min-height: 110px;

  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 0;
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #000;

    span {
      font-size: 26px;
      font-weight: bold;
      color: #1890ff;
    }

    div {
      height: 36px;
    }
  }
`;

export const StepButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  padding-bottom: 18px;

  button {
    min-width: 60px;
    width: 12%;

    & + button {
      margin-left: 18px;
    }
  }
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  border-top: 1px solid #f0f0f0;

  justify-content: center;
  align-items: center;

  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const CurrecyMaskedInput = styled(CurrencyInput)`
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  border: 0px solid #d9d9d9;
  background: #fff;
  transition: all 0.3s;
  width: 130px;
  color: #707070 !important;
  font-size: 26px !important;
  margin-left: 8px;

  &:disabled {
    cursor: default;
    color: #333;
  }

  :hover {
    border-color: #40a9ff;
  }
`;

export const IButton = styled(Button)`
  border-radius: 18px;
  background: #1890ff;
  color: #fff;
  border: 0px;
  margin-top: 36px;
  font-size: 16px;
  width: 130px;
  height: 34px;

  :hover {
    background: #3899f3;
    color: #fff;
    border: 0px;
  }

  :active {
    background: #3899f3;
    color: #fff;
    border: 0px;
  }

  :focus {
    background: #3899f3;
    color: #fff;
    border: 0px;
  }
`;

import styled from 'styled-components';
import { Alert } from 'antd';
import { Col } from '../../styles/components';

export const Container = styled.div``;

export const Pagination = styled.div`
  margin: 0 auto;
  display: flex;
  width: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  span {
    cursor: pointer;
  }

  span:nth-child(2) {
    padding-left: 15px;

    &::before {
      content: '|';
      padding-right: 15px;
    }
  }
`;

export const ICol = styled(Col)`
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > td
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0;
  }
  .ant-col.bar {
    color: black;
    border: solid;
    border-width: 1px;
    height: 215px;
    width: 1px;
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    align-content: center;
  }
`;
export const ChoseMainPartner = styled.span`
  color: #42c1c7;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const UnlockButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: #ffff;
  padding: 2px 5px;
  background-color: #24a148;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
  width: 100px;
  height: 30px;
`;

export const DontAceptButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: #ffff;
  padding: 2px 5px;
  background-color: #da1e28;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
  width: 100px;
  height: 30px;
`;

export const SuccessAlert = styled(Alert)`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  margin: 15px 0;
`;
export const SuccessModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    font-weight: bold;
  }
`;

import styled from 'styled-components';
import { Col } from '../../styles/components';

export const Container = styled.div``;

export const Pagination = styled.div`
  margin: 0 auto;
  display: flex;
  width: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  span {
    cursor: pointer;
  }

  span:nth-child(2) {
    padding-left: 15px;

    &::before {
      content: '|';
      padding-right: 15px;
    }
  }
`;

export const ICol = styled(Col)`
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > td
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0;
    padding: 0;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: -15px;
`;

export const SuccessButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 25px;
  background-color: transparent;
  color: #24a148;
  border: 1px solid #24a148;
  font-weight: bold;
  cursor: pointer;
`;

export const DangerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 25px;
  background-color: transparent;
  color: #da1e28;
  border: 1px solid #da1e28;
  font-weight: bold;
  cursor: pointer;
`;

export const AlertButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 25px;
  background-color: transparent;
  color: #f5a623;
  border: 1px solid #f5a623;
  font-weight: bold;
  cursor: pointer;
`;

export const LargeAlertButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 45px;
  background-color: transparent;
  color: #f5a623;
  font-size: 12px;
  border: 1px solid #f5a623;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
`;

export const TableInfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
`;

export const SelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 100%;
`;

export const TableButtonArea = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
`;

export const TableButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 33px;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  background-color: #42c1c7;
  cursor: pointer;

  &:hover {
    background-color: #3fb0c7;
  }
`;

export const SearchSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`;
